<template>
  <a-modal v-model="modalShow" :footer="null"
           :width="width"
           @cancel="cancel"
           :title="title && title[language] ? title[language] : ''">
    <div class="modal__container">
      <slot name="body" />
    </div>
    <div
      v-if="!hideButtons"
      class="block block__right block__align-center"
      :style="{marginTop: '20px'}">
      <div>
        <a-button @click="cancel" v-if="showCancel">
          {{ cancelText[language] }}
        </a-button>
      </div>
      <div class="component__margin-left">
        <a-button type="primary" @click="setOk">
          {{ okText[language] }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'Modal',
  data() {
    return {
      modalShow: false,
    };
  },
  props: {
    show: Boolean,
    title: Object,
    name: {
      type: String,
      default: 'modal',
    },
    okText: {
      type: Object,
      default() {
        return {
          ru: 'Добавить',
          en: 'Add',
        };
      },
    },
    width: {
      type: Number,
      default: 500,
    },
    ok: {
      type: Function,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      type: Object,
      default() {
        return {
          ru: 'Отменить',
          en: 'Cancel',
        };
      },
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: function (val) {
      this.modalShow = val;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
      this.modalShow = false;
    },
    setOk() {
      this.$emit('ok');
      this.cancel();
    },
  },
  created() {
    this.modalShow = this.show;
  },
};
</script>
