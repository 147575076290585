<template>
  <div id="app">
    <PageLayout v-if="!meta || meta.layout === 'page'">
      <template slot="page" slot-scope="{scrollTop}">
        <router-view :scroll-top="scrollTop"/>
      </template>
    </PageLayout>
    <InterfaceLayout v-if="meta.layout === 'interface'">
      <template v-slot:page>
        <router-view/>
      </template>
    </InterfaceLayout>
    <EmptyLayout v-if="meta.layout === 'empty'">
      <template v-slot:page>
        <router-view/>
      </template>
    </EmptyLayout>
    <AuthModal :show="isShowAuth" :close="closeAuth" @ok="successAuth" />
  </div>
</template>

<script>
import AuthModal from '@/components/auth/AuthModal.vue';

const PageLayout = () => import('@/layouts/PageLayout.vue');
const InterfaceLayout = () => import('@/layouts/InterfaceLayout.vue');
const EmptyLayout = () => import('@/layouts/EmptyLayout.vue');

export default {
  name: 'App',
  data() {
    return {
      unsubscribe: () => {},
      isShowAuth: false,
      scrollTop: 0,
    };
  },
  watch: {
  },
  metaInfo() {
    return {
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Маркетплейс. Заказать изготовление деталей на заказ. Механообработка, литье,'
          + ' 3Д печать, термообработка на станках с ЧПУ. Разработка технологических процессов.',
        },
      ],
    };
  },
  methods: {
    async updateWidth() {
      if (window.screen.orientation) {
        await new Promise((resolve) => setTimeout(resolve, 300));
        // Пауза, чтобы дать браузеру время на обновление размеров экрана
      }
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      this.$store.commit('index/setWidth', window.innerWidth);
    },
    closeAuth() {
      this.$store.commit('user/setShowAuthorizeModal', false);
    },
    successAuth() {
      this.$store.commit('user/setShowAuthorizeModal', false);
    },
    setScrollTop(val) {
      this.scrollTop = val;
    },
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  created() {
    window.addEventListener('resize', this.updateWidth);
    // const secretKey = localStorage.getItem('token');
    /* if (secretKey) {
      this.$store.dispatch('user/getUser');
    } */
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'user/setShowAuthorizeModal') {
        this.isShowAuth = mutation.payload;
      }
    });
  },
  mounted() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.$store.commit('index/setWidth', window.innerWidth);
  },
  create() {
    /* if (this.$route.query.token) {
      localStorage.setItem('token', this.$route.query.token);
      this.$store.dispatch('user/getUser');
    } else {
      this.$store.dispatch('user/getUser');
    } */
    // this.$store.dispatch('user/getUser');
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  components: {
    AuthModal,
    PageLayout,
    InterfaceLayout,
    EmptyLayout,
  },
};
</script>

<style lang="scss">
</style>
