<template>
  <div class="block block__center">
    <div class="home__container" v-if="companyData">
      <div style="width: 100%">
        <div class="company__header">
          <div class="block__flex">
            <img
              :src="companyData.logo"
              class="company__header_logo"
              v-if="companyData.logo" />
            <div>
              <div class="company__header_name" v-if="companyData.name">
                {{companyData.name}}
              </div>
              <div class="block__flex block__align-center">
                <div
                  style="margin-right: 15px;"
                  class="block__flex"
                  v-if="companyData.location">
                  <MarkerLogo
                    style="min-width: 16px"
                    class="component__margin-right" />
                  <div>{{companyData.location}}</div>
                </div>
                <div v-if="companyData.date_created" style="margin-right: 15px;">
                  <a-tooltip :placement="'bottom'" v-if="item.date_created">
                    <template slot="title">
                      {{orderOffersText.year_foundation[language]}}
                    </template>
                    <div class="block__flex block__align-center component__margin-right">
                      <FoundationLogo />
                      <div class="order-offers__card_info">
                        {{companyData.date_created}}
                      </div>
                    </div>
                  </a-tooltip>
                </div>
                <div v-if="companyData.staff_amount" style="margin-right: 15px;">
                  <a-tooltip
                    :placement="'bottom'"
                    v-if="companyData.staff_amount">
                    <template slot="title">
                      {{orderOffersText.staff_amount[language]}}
                    </template>
                    <div class="block__flex block__align-center component__margin-right">
                      <EmployeesLogo />
                      <div class="order-offers__card_info">
                        {{companyData.staff_amount}}
                      </div>
                    </div>
                  </a-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div class="block__flex">
            <ApproveLogo v-if="companyData.proof_by_i5" />
            <div
              style="margin-left: 10px"
              class="company__header_receive-orders"
              v-if="companyData.receive_orders">
              <div>
                {{text.receive_orders[language]}}
              </div>
              <div style="margin-left: 60px">
                <MtLogo />
              </div>
            </div>
          </div>
        </div>
        <div class="company__block" v-if="companyData.tags && companyData.tags.length > 0">
          <div class="company__title">
            {{text.specialities[language]}}
          </div>
          <div class="block block__between">
            <div class="company__tags">
              <div
                class="company__tag"
                v-for="(tag, index) in companyData.tags"
                v-bind:key="`tag_${index}`">
                {{tag}}
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div
          class="company__block"
          v-if="companyData.certificates && companyData.certificates.length > 0">
          <div class="company__title">
            {{text.certificates[language]}}
          </div>
          <div class="company__tags">
            <div
              v-for="(certificate, index) in companyData.certificates"
              v-bind:key="`certificate_${index}`">
              <a-tooltip :placement="'top'">
                <template slot="title">
                  {{certificate.description}}
                </template>
                <div
                  style="margin-bottom: 10px"
                  class="block__flex block__align-center component__margin-right">
                  <img
                    v-if="certificate && certificate.img"
                    :src="certificate && certificate.img"
                    class="company__certificate_logo" />
                  <div class="company__certificate_text" v-if="certificate.name">
                    {{certificate.name}}
                  </div>
                </div>
              </a-tooltip>
            </div>
          </div>
        </div>
        <div
          class="company__block">
          <div :class="width > t_lg ? 'block block__between' : ''">
            <div>
              <div class="company__title">
                {{text.company_info[language]}}
              </div>
              <div v-if="companyData.inn" style="margin-bottom: 15px">
                <span class="company__company-info_title">
                  {{text.tin[language]}}:
                </span>
                <span class="company__company-info_value">
                  {{companyData.inn}}
                </span>
              </div>
              <div v-if="companyData.company_reg_number" style="margin-bottom: 15px">
                <span class="company__company-info_title">
                  {{text.reg_number[language]}}:
                </span>
                <span class="company__company-info_value">
                  {{companyData.company_reg_number}}
                </span>
              </div>
              <div style="margin-bottom: 15px" v-if="companyData.fact_address">
                <div class="company__company-info_address-title">
                  {{text.fact_address[language]}}
                </div>
                <div class="company__company-info_address-value">
                  {{companyData.fact_address}}
                </div>
              </div>
              <div style="margin-bottom: 15px" v-if="companyData.ur_address">
                <div class="company__company-info_address-title">
                  {{text.address[language]}}
                </div>
                <div class="company__company-info_address-value">
                  {{companyData.ur_address}}
                </div>
              </div>
            </div>
            <div
              :style="width > t_lg ? 'max-width: 50%' : ''"
              v-if="companyData.description">
              <div class="company__title">
                {{text.description[language]}}
              </div>
              <div class="company__company-info_description">
                {{companyData.description}}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="companyData.imgs && companyData.imgs.length > 0"
          class="company__block">
          <div class="company__title" style="text-align: center">
            {{text.photos_company[language]}}
          </div>
          <div class="company__images_main-container">
            <img
              class="company__images_main"
              :src="companyData.imgs[activeImage]" />
          </div>
          <div
            class="block block__align-center">
            <ArrowLeft
              @click="setFileFilter('minus')"
              :class="fileFilterNumber - 3 > 0
                      ? 'company__images_arrow company__images_arrow_active'
                      : 'company__images_arrow'"
              style="margin-right: 10px;" />
            <div v-for="el in companyData.imgs
                    .filter((el, elIndex) => elIndex <= fileFilterNumber
                    && elIndex > fileFilterNumber - 4)"
                 :class="activeImageLink
                         === el
                        ? 'company__images_min-container'
                        + ' company__images_min-container_active'
                        : 'company__images_min-container'"
                 @click="setActiveImage(el)"
                 v-bind:key="`image_${el}`">
              <img
                class="company__images_preview-min"
                :src="el" />
            </div>
            <ArrowRight
              @click="setFileFilter('plus')"
              :class="fileFilterNumber < companyData.imgs.length - 1
                      ? 'company__images_arrow company__images_arrow_active'
                      : 'company__images_arrow'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { query } from '@/utilites/axios';
import MarkerLogo from '@/assets/pictures/part/card/Marker.svg';
import FoundationLogo from '@/assets/pictures/order/foundation.svg';
import EmployeesLogo from '@/assets/pictures/order/employees.svg';
import ApproveLogo from '@/assets/pictures/company/approve.svg';
import MtLogo from '@/assets/pictures/company/mt.svg';
import orderOffersText from '@/assets/text/order-offers.json';
import text from '@/assets/text/company.json';
import ArrowLeft from '@/assets/pictures/editOrder/arrow-left.svg';
import ArrowRight from '@/assets/pictures/editOrder/arrow-right.svg';

export default {
  name: 'Company',
  data() {
    return {
      orderOffersText,
      text,
      companyData: null,
      activeImage: 0,
      activeImageLink: null,
      fileFilterNumber: 3,
    };
  },
  metaInfo() {
    return {
      title: this.companyData && this.companyData.title
        ? this.companyData.title : 'I5.Solutions',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.companyData && this.companyData.description
            ? this.companyData.description : '',
        },
        {
          name: 'keywords',
          content: this.companyData && this.companyData.keywords
            ? this.companyData.keywords : '',
        },
        {
          property: 'og:title',
          content: this.companyData && this.companyData.og_title
            ? this.companyData.og_title : '',
        },
        {
          property: 'og:description',
          content: this.companyData && this.companyData.og_description
            ? this.companyData.og_description : '',
        },
        {
          property: 'og:url',
          content: this.companyData && this.companyData.og_url
            ? this.companyData.og_url : '',
        },
        {
          property: 'og:site_name',
          content: this.companyData && this.companyData.og_site_name
            ? this.companyData.og_site_name : '',
        },
        {
          property: 'og:image',
          content: this.companyData && this.companyData.og_image
            ? this.companyData.og_image : '',
        },
        {
          property: 'og:image:width',
          content: this.companyData && this.companyData.og_image_width
            ? this.companyData.og_image_width : '',
        },
        {
          property: 'og:image:height',
          content: this.companyData && this.companyData.og_image_height
            ? this.companyData.og_image_height : '',
        },
        {
          property: 'og:locale',
          content: this.companyData && this.companyData.og_locale
            ? this.companyData.og_locale : '',
        },
        {
          property: 'og:type',
          content: this.companyData && this.companyData.og_type
            ? this.companyData.og_type : '',
        },
      ],
    };
  },
  computed: {
    uuid() {
      return this.$route.params.id;
    },
    language() {
      return this.$store.state.index.language;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    width() {
      return this.$store.state.index.width;
    },
  },
  watch: {
    uuid: {
      immediate: true,
      handler: function (val) {
        this.getCompany(val);
      },
    },
  },
  methods: {
    getCompany(id) {
      query('post', 'getOpenCompanyInfo', {
        uuid: id,
      }, {}).then((result) => {
        this.companyData = result;
        if (result && result.imgs && result.imgs.length > 0) {
          this.activeImageLink = result.imgs[0];
        }
      });
    },
    setFileFilter(type) {
      if (type === 'minus' && this.fileFilterNumber - 3 > 0) {
        this.fileFilterNumber = --this.fileFilterNumber;
      } else if (type === 'plus'
        && this.fileFilterNumber
        < this.companyData.imgs.length - 1) {
        this.fileFilterNumber = ++this.fileFilterNumber;
      }
    },
    setActiveImage(link) {
      this.activeImageLink = link;
      const index = this.companyData.imgs.findIndex((el) => el === link);
      this.activeImage = index;
    },
  },
  components: {
    MarkerLogo,
    FoundationLogo,
    EmployeesLogo,
    ApproveLogo,
    MtLogo,
    ArrowLeft,
    ArrowRight,
  },
};
</script>
