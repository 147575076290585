const state = () => ({
  order: null,
});

const mutations = {
  setOrder(state, data) {
    state.order = data;
  },
};

const getters = {};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
