import axios from 'axios';
import store from '@/store/index';
import packageJson from '../../../package.json';
// import router from '@/router';

export function query(method, url, data, headers, onUploadProgress = () => {}) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url: `${packageJson.url}${url}`,
      data,
      headers,
      onUploadProgress,
    })
      .then((item) => {
        resolve(item.data);
      })
      .catch((err) => {
        if (err.response) {
          const status = err.response.status;
          if (status === 401) {
            store.commit('user/setShowAuthorizeModal', true);
            reject(err);
          } else if (status === 403) {
            store.commit('user/setShowAuthorizeModal', true);
            reject(err);
          }
        }
        reject(err);
      });
  });
}

export function queryWithout401(method, url, data, headers, onUploadProgress = () => {}) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url: `${packageJson.url}${url}`,
      data,
      headers,
      onUploadProgress,
    })
      .then((item) => {
        resolve(item.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default query;
