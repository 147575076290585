import Vue from 'vue';
import Vuex from 'vuex';
import home from '@/store/modules/home';
import index from '@/store/modules/index';
import part from '@/store/modules/part';
import materials from '@/store/modules/materials';
import tech from '@/store/modules/tech';
import model from '@/store/modules/model';
import modelV2 from '@/store/modules/modelV2';
import user from '@/store/modules/user';
import basket from '@/store/modules/basket';
import techReq from '@/store/modules/techReq';
import orders from '@/store/modules/orders';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    index,
    home,
    part,
    materials,
    tech,
    model,
    user,
    basket,
    modelV2,
    techReq,
    orders,
  },
});
