import { query } from '@/utilites/axios';

const state = () => ({
  materials: null,
  materialId: null,
  materialsThree: {},
  loading: false,
});

const mutations = {
  setMaterials(state, data) {
    state.materials = data;
  },
  setMaterialsThree(state, data) {
    state.materialsThree = data;
  },
  setMaterialId(state, id) {
    state.materialId = id;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

const getters = {
};

const actions = {
  async getMaterials({ commit }, id) {
    await query('get', 'getMaterials', {}, {}, () => {})
      .then((item) => {
        commit('setMaterials', item);
        const result = {};
        item.forEach((material) => {
          if (material.pId === 0) {
            result[material.id] = {
              ...material,
              ...{ expanded: false },
            };
          } else {
            result[material.pId].children = {
              ...result[material.pId].children,
              ...{ [material.id]: material },
            };
            result[material.pId].children[material.id] = {
              ...result[material.pId].children[material.id],
              ...{ parent: result[material.pId] },
            };
          }
          if (id && material.value === id) {
            result[material.pId].expanded = true;
          }
        });
        commit('setMaterialsThree', result);
        commit('setLoading', false);
      });
  },
  async findMaterials({ commit }, data) {
    await query('post', 'getMaterials', data, {}, () => {})
      .then((item) => {
        commit('setMaterials', item);
        commit('setLoading', false);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
