<template>
  <div style="width: 100%">
    <UniversalUpload
      @setUpdate="saveUpdateFunction($event)"
      @leave="handleMouseLeave"
      @enter="handleMouseEnter"
      @dLeave="handleDragLeave"
      @dEnter="handleDragEnter"
      @dDrop="handleDrop"
      :uploadFunction="uploadFile"
      :upload-percent="uploadPercent"
      :formats="`${'.stp,.STP,.STEP,.step,.stl,.STL,.pdf,.txt,.dwg,.dxf,.emf,.dwt'
          + ',.jpeg,.jpg,.png,.cdw,.heif,.raw,.docx,.xlsx,.sldprt,.dxf,.ipt'}`"
      :border-color="activeBorder ? '#B92A21' : '#F1F1F1'"
      style="margin-bottom: 20px;">
      <template slot="body">
        <div
          :style="activeGear ? 'background-color: rgba(185, 42, 33, .3)' : ''"
          class="orders__upload_wrapper">
          <GearLeft
            :fill="activeGear ? '#B92A21' : '#b8b8b8'"
            class="kit__upload_gear-left" />
          <GearRight
            :fill="activeGear ? '#B92A21' : '#b8b8b8'"
            class="kit__upload_gear-right" />
          <div class="orders__upload_container">
            <div
              style="height: 100%;"
              class="block block__column block__center block__align-center">
              <div class="orders__upload_title" style="margin-bottom: 10px;">
                {{text.upload_title[language]}}
              </div>
              <div class="orders__upload_sub-title" style="margin-bottom: 10px;">
                {{text.upload_auto_title[language]}}
              </div>
              <div class="orders__upload_title" style="margin-bottom: 20px;">
                STP | STEP | STL
              </div>
              <div class="orders__upload_sub-title" style="margin-bottom: 10px;">
                {{text.upload_formats_doc_title[language]}}
              </div>
              <div class="orders__upload_format">
                PDF | DWG | JPEG | PNG | CDW | HEIF | RAW | DOCX | XLSX
              </div>
              <div class="orders__upload_format" style="margin-bottom: 20px;">
                SLDPRT | DXF | STL | IPT | 3DXML | PRT | M3D…
              </div>
              <div class="orders__upload_sub-title" style="margin-bottom: 10px;">
                {{text.upload_first[language]}}
              </div>
              <div class="orders__upload_sub-title" style="margin-bottom: 20px;">
                <span>{{text.upload_size[language]}} </span>
                <b>
                  <span>50 </span>
                  <span>{{text.upload_mb[language]}}</span>
                </b>
              </div>
            </div>
            <div class="component__margin class block__flex block__align-center">
              <Protect class="component__margin-right" style="min-width: 22px;" />
              <div class="orders__upload_protect">
                {{text.upload_protect[language]}}
              </div>
            </div>
          </div>
        </div>
      </template>
    </UniversalUpload>
  </div>
</template>

<script>
import UniversalUpload from '@/components/universalUpload/UniversalUploadV2.vue';
import text from '@/assets/text/orders.json';
import GearLeft from '@/assets/pictures/kit/gearLeft.svg';
import GearRight from '@/assets/pictures/kit/gearRight.svg';
import Protect from '@/assets/pictures/kit/protect.svg';
import { query } from '@/utilites/axios';
import func from '@/utilites/functions';

export default {
  name: 'FileUploadI5',
  data() {
    return {
      text,
      func,
      uploadPercent: null,
      updateUploadComponent: () => {},
      activeBorder: false,
      activeGear: false,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    handleMouseEnter() {
      this.activeBorder = true;
    },
    handleMouseLeave() {
      this.activeBorder = false;
    },
    handleDragLeave() {
      this.activeGear = false;
    },
    handleDragEnter() {
      this.activeGear = true;
    },
    handleDrop() {
      this.activeGear = false;
    },
    uploadFile(formData, index, name) {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        if (token) {
          query('put', 'addFile', formData, {
            token,
          }, (e) => {
            const percent = String(Math.round((e.loaded * 100) / e.total));
            this.uploadPercent = percent;
          }).then((item) => {
            query('post', 'addNewPartV2', {
              code: '1',
              name: name,
              files: [],
            }, {
              token: localStorage.getItem('token'),
            }).then(async (result) => {
              let orderUuid = null;
              let orderPartUuid = null;
              await query('post', 'addNewOrderV2', {
                parts: [
                  result.uuid,
                ],
              }, {
                token: localStorage.getItem('token'),
              }).then((order) => {
                orderUuid = order.uuid;
                orderPartUuid = order.order_spec[0];
              });
              await query('post', 'addFilesToOrderPartV2', {
                uuid: orderPartUuid,
                files: [
                  {
                    hash: item.hash,
                    time_stamp: item.timeStamp,
                    file_name: item.fileName,
                  },
                ],
              }).then(() => {
                this.func.routerPush(`/edit-order/${orderUuid}`);
              });
            });
            resolve(item);
          }).catch((err) => {
            if (err.response.status === 401) {
              this.$store.commit('user/setAfterAuthorize', this.updateUploadComponent);
              this.$store.commit('user/setShowAuthorizeModal', true);
            }
            reject(err);
          });
        } else {
          this.$store.commit('user/setAfterAuthorize', this.updateUploadComponent);
          this.$store.commit('user/setShowAuthorizeModal', true);
          reject();
        }
      });
    },
    saveUpdateFunction(func) {
      this.updateUploadComponent = func;
    },
  },
  components: {
    UniversalUpload,
    GearRight,
    GearLeft,
    Protect,
  },
};
</script>
