<template>
  <!-- select type multiple once none -->
  <div id="model-viewer-wrapper" :style="{height: '100%'}" :class="`${componentSize}
  block__fullsize block__absolute_left model-viewer__wrapper`">
    <div
      :class="`block__absolute block__absolute_top block__absolute_left ${
        setBorder(showModelFrame)}`">
    </div>
    <div v-if="logoPosition !== 'none'"
         :class="`block__absolute block__absolute_top block__absolute_${
           logoPosition
         } component__padding`">
      <!--<img v-if="!logo" src="@/assets/images/i5/i5.solutions.s" class="logo logo__medium" />-->
      <!--<img v-if="logo" :src="logo" class="logo logo__medium" />-->
    </div>
    <div class="block block__absolute block__absolute_bottom block__absolute_left"
         v-if="colors && colorsData">
      <div v-for="(color, index) in colors" v-bind:key="`color_${index}`"
           class="model-viewer__colors"
           :style="{backgroundColor: `rgb(${color.r * 255},${color.g * 255},${color.b * 255})`}"
      >
        {{ colorsData[index].value }}
      </div>
    </div>
    <div class="block block__absolute block__absolute_bottom block__absolute_left"
         v-if="decorateViewerColors">
      <div v-for="(item, index) in decorateViewerColors" v-bind:key="`color_${index}`"
           class="model-viewer__colors"
           :style="{backgroundColor: `rgb(${
             item.color.r * 255},${item.color.g * 255},${item.color.b * 255})`}"
      >
        {{ item.value }}
      </div>
    </div>
    <div class="block block__absolute block__absolute_right model-viewer__unit"
         v-if="colors && colorsData && roughnessType">
      {{`${roughnessType},${model.unit[language]}`}}
    </div>
    <div v-if="percent > 0 && percent < 100" class="block block__center block__align-center
    block__absolute"
         :style="{height: '100%', pointerEvents: 'none'}">
      <a-progress stroke-color="#AC1325" type="circle" :percent="percent" :width="80" />
    </div>
    <div v-if="loading && !percent" class="block block__center block__align-center block__absolute"
         :style="{height: '100%', pointerEvents: 'none'}">
      <a-spin size="large" />
    </div>
    <ModelGltf
      v-if="type === 'gltf'"
      :type="type"
      :style="{width: '100%', height: '100%'}"
      @on-mousemove="onMouseMove"
      @on-mousedown="onMouseDown"
      v-on:getResize="setResize($event)"
      v-on:loading="setLoading($event)"
      v-on:surfaces="setSurfaces($event)"
      v-on:set-group="getGroup($event)"
      v-on:progress="setProcess($event)"
      :selectorType="viewerSelectorType"
      :colorize="colorize"
      :selected="selected"
      :width="canvasWidth"
      :height="canvasHeight"
      :activeGroup="activeGroup"
      :defaultGroups="defaultGroups"
      :src="src"
      :background-color="backgroundColor"
    />
    <ModelStl
        v-if="type === 'stl'"
        :type="type"
        :style="{width: '100%', height: '100%'}"
        @on-mousemove="onMouseMove"
        @on-mousedown="onMouseDown"
        v-on:getResize="setResize($event)"
        v-on:loading="setLoading($event)"
        v-on:progress="setProcess($event)"
        :width="canvasWidth"
        :height="canvasHeight"
        :selected="selected"
        :select-type="selectType"
        :activeGroup="activeGroup"
        :src="src"
        :background-color="backgroundColor"
    />
  </div>
</template>
<script>
import model from '@/assets/text/model.json';

const ModelGltf = () => import('@/utilites/3d-viewerV2/model-gltf.vue');
const ModelStl = () => import('@/utilites/3d-viewerV2/model-stl.vue');

export default {
  name: 'ModelViewer',
  props: {
    src: String,
    type: {
      type: String,
      default: 'stl',
    },
    customColors: {
      type: Object,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    logoPosition: {
      type: String,
      default: 'left', // left, right, none
    },
    activeGroup: {
      type: Array,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    colorize: {
      type: String,
      default: '',
    },
    logo: String,
    size: String,
    selectType: {
      type: String,
      default: 'none',
    },
    defaultGroups: {
      type: Array,
    },
    showBoxShadow: {
      type: Boolean,
      default: false,
    },
    backgroundColor: { // format 0xffffff
    },
    // Тип обработки нажатий
    // model старый подход, где обработка нажатий происходит внутри компонента 3д модели
    // component новый подход, где обработка нажатий прописывается внутри компонента
  },
  watch: {
  },
  data() {
    return {
      model,
      loading: false,
      surfacesColor: {},
      detailForm: this.$form.createForm(this, { name: 'detailForm' }),
      surfaceForm: this.$form.createForm(this, { name: 'surfaceForm' }),
      selectedSurfaces: [],
      surfaces: {},
      surfacesControl: {},
      fullsize: false,
      sizeIcon: 'expand-solid',
      componentSize: 'block__relative',
      canvasHeight: undefined,
      canvasWidth: undefined,
      resize: null,
      isShowSurfacesForm: false,
      isShowToleranceForm: false,
      changeRa: 'ra',
      roughnessRa: null,
      roughnessRz: null,
      showForm: '',
      colors: null,
      colorsData: null,
      roughnessType: null,
      shapes: [],
      toleranceForm: [],
      holeFit: [],
      percent: 0,
      decorateViewerColors: null,
      viewerSelectorType: 'model',
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    showModelFrame() {
      return this.$store.state.modelV2.showModelFrame;
    },
  },
  methods: {
    onMouseMove(data) {
      console.log(data);
    },
    onMouseDown(data) {
      this.selectedSurfaces = data;
      this.$emit('select', data);
    },
    setLoading(event) {
      this.loading = event;
    },
    setProcess(e) {
      const percent = Math.round(e.loaded / e.total * 100);
      this.percent = percent;
    },
    setSurfaces(item) {
      this.surfaces = item.surfaces;
    },
    getGroup(data) {
      const groupsArray = [];
      Object.keys(data).forEach((item) => {
        if (Number(item) > 0) {
          groupsArray.push({
            id: item,
            length: data[item].surfaces.length,
          });
        }
      });
      this.$store.commit('model/setGroups', data);
      this.$store.commit('model/setGroupsArray', groupsArray);
    },
    setResize(event) {
      this.resize = event;
    },
    setPercent(event) {
      this.percent = event;
      if (event === 100) {
        setInterval(() => {
          this.percent = 0;
        }, 100);
      }
    },
    setImg(id) {
      return this[`img_${id}`];
    },
    setScale(data) {
      this.colors = data.colors;
      this.colorsData = data.groups;
      if (data.roughnessType && data.roughnessType === 'ra') {
        this.roughnessType = 'Ra';
      } else {
        this.roughnessType = 'Rz';
      }
    },
    clearScale() {
      this.colors = null;
      this.colorsData = null;
    },
    decorateViewer(colors) {
      this.decorateViewerColors = colors;
    },
    setSelectorType(val) {
      this.viewerSelectorType = val;
    },
    setBorder(active) {
      let result = '';
      if (this.showBoxShadow && !active) {
        result = 'model-viewer__hover';
      } else if (this.showBoxShadow && active) {
        result = 'model-viewer__active';
      }
      return result;
    },
  },
  mounted() {
    this.viewerSelectorType = this.selectorType;
    this.$store.commit('model/setSetScaleFunction', this.setScale);
    this.$store.commit('model/setClearScaleFunction', this.clearScale);
    this.$store.commit('modelV2/setDecorateViewer', this.decorateViewer);
    this.$store.commit('modelV2/setChangeSelectorType', this.setSelectorType);
    setTimeout(() => {
      this.canvasHeight = this.$el.scrollHeight;
      this.canvasWidth = this.$el.scrollWidth;
    }, 0);
  },
  components: {
    ModelGltf,
    ModelStl,
  },
};
</script>
