import { query } from '@/utilites/axios';
// import router from '@/router';

const state = () => ({
  language: 'en',
  currency: 'EUR',
  pageLoading: false,
  width: null,
  md: 1280,
  t_lg: 1024,
  t_md: 768,
  m_lg: 480,
  m_md: 380,
  primaryColor: '#B92A21',
  scrollTop: 0,
  showHeader: true,
  languages: [
    'ru',
    'en',
    'de',
  ],
  companyId: null,
});

const mutations = {
  setLanguage(state, lang) {
    state.language = lang;
  },
  setCurrency(state, curr) {
    state.currency = curr;
  },
  setScroll(state, scroll) {
    state.scrollTop = scroll;
  },
  setWidth(state, width) {
    state.width = width;
  },
  setLoading(state, loading) {
    state.pageLoading = loading;
  },
  setShowHeader(state, data) {
    state.showHeader = data;
  },
  setCompanyId(state, data) {
    state.companyId = data;
  },
};

const getters = {
};

const actions = {
  async uploadFile({ commit, store }, data) {
    await query(data.method, data.url, data.data, data.headers, data.onUploadProgress)
      .then((item) => {
        commit('setDetail', item);
        window.location.href = 'https://lk.i5.solutions/#/order?h=' + item.hash + '&t='
          + item.timeStamp + '&n=' + item.fileName + '&e=' + item.ext
          + '&l=' + store.state.index.language;
        /* router.push('/part?h=' + item.hash + '&t='
          + item.timeStamp + '&n=' + item.fileName); */
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
