<template>
  <Modal
    :title="text.offer[language]"
    :show="showModal" @close="closeModal">
    <template slot="body">
      <div class="order-offers__kp_container">
        <div class="order-offers__kp_left">
          <div class="order-offers__kp_body">
          </div>
          <div class="order-offers__kp_button">
            <a
              v-if="companyId"
              :href="`https://api.i5.solutions/getCommercialOfferPDF?t=${
                token
              }&uuid=${uuid}&id=${companyId}`" download>
              <Button
                :text="text.save_pdf[language]"
                class="component__margin-bottom"
                block />
            </a>
            <a
              v-if="companyUuid"
              :href="`https://api.i5.solutions/getCommercialOfferPDFV2?t=${
                token
              }&uuid=${uuid}&uuid_company=${companyUuid}`" download>
              <Button
                :text="text.save_pdf[language]"
                class="component__margin-bottom"
                block />
            </a>
          </div>
        </div>
        <div class="order-offers__kp_right" v-if="showPdf">
          <Pdf
            class="order-offers__kp_pdf"
            v-for="i in pageCount"
            @error="onError"
            :key="i"
            :src="pdfSrc"
            :page="i"
          ></Pdf>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Pdf from 'vue-pdf';
import Modal from '@/components/i5Modal/I5Modal.vue';
import text from '@/assets/text/order-offers.json';
import Button from '@/components/button/Button.vue';
import { query } from '@/utilites/axios';

export default {
  name: 'KpModal',
  data() {
    return {
      text,
      showModal: false,
      pdfSrc: null,
      pageCount: null,
      currentPage: 0,
      estimateType: 1,
      kpData: {},
      timer: null,
      sendData: {},
      showPdf: true,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    uuid: {
      type: String,
    },
    companyId: {
      type: Number,
    },
    companyUuid: {
      type: String,
    },
  },
  watch: {
    show: function (val) {
      this.showModal = val;
      this.getPdf();
      this.getKPInfo();
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.$emit('close');
    },
    sendToCustomer() {
      query('post', 'estimateOrderByManuf', {
        uuid: this.uuid,
      }, {
        token: localStorage.getItem('token'),
      }).then(() => {
        this.closeModal();
      });
    },
    getPdf() {
      if (this.companyId) {
        this.pdfSrc = Pdf.createLoadingTask(
          `https://api.i5.solutions/getCommercialOfferPDF?t=${
            localStorage.getItem('token')
          }&uuid=${this.uuid}&id=${this.companyId}`,
        );
      } else if (this.companyUuid) {
        this.pdfSrc = Pdf.createLoadingTask(
          `https://api.i5.solutions/getCommercialOfferPDFV2?t=${
            localStorage.getItem('token')
          }&uuid=${this.uuid}&uuid_company=${this.companyUuid}`,
        );
      }
      this.pdfSrc.promise.then((pdf) => {
        this.pageCount = pdf.numPages;
      });
    },
    setNumPages(num) {
      this.pageCount = num;
    },
    setCurPage(num) {
      this.currentPage = num;
    },
    setEstimateType(val) {
      this.estimateType = val;
      this.resetTimer(+val, 'id_offer_price_type');
    },
    getKPInfo() {
      query('post', 'getOrderKPinfo', {
        uuid: this.uuid,
      },
      {
        token: localStorage.getItem('token'),
      }).then((result) => {
        this.kpData = result;
      }).catch((err) => {
        console.log(err);
      });
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.changeOrderKPinfo();
      }, 600);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer(value, label) {
      this.sendData[label] = value;
      this.stopTimer();
      this.startTimer();
    },
    changeOrderKPinfo() {
      this.showPdf = false;
      const data = {
        uuid: this.uuid,
      };
      query('post', 'changeOrderKPinfo', { ...this.sendData, ...data }, {
        token: localStorage.getItem('token'),
      }).then(() => {
        this.showPdf = true;
        this.getPdf();
      });
    },
    onError(err) {
      console.log(err);
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    token() {
      return localStorage.getItem('token');
    },
  },
  mounted() {
  },
  components: {
    Modal,
    Button,
    Pdf,
  },
};
</script>
