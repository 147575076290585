import { query } from '@/utilites/axios/index';
// import index from '@/store/modules/index';
import router from '@/router';

const state = () => ({
  activeImage: 0,
  detailData: {},
});

const mutations = {
  setActiveImage(state, index) {
    state.activeImage = index;
  },
  setDetail(state, data) {
    state.detailData = data;
  },
};

const getters = {};

const actions = {
  uploadFile({ commit }, data) {
    const secretKey = localStorage.getItem('token');
    const headers = {};
    if (secretKey) {
      headers.token = secretKey;
    }
    return new Promise((resolve, reject) => {
      query(data.method, data.url, data.data, headers, data.onUploadProgress)
        .then((item) => {
          /* window.location.href = 'https://lk.i5.solutions/#/order?h=' + item.hash + '&t='
            + item.timeStamp + '&n=' + item.fileName + '&e='
            + item.ext + '&l=' + index.state.language; */
          query('post', 'addNewPartV2', {
            code: '1',
            name: data.name,
            files: [],
          }, {
            token: localStorage.getItem('token'),
          }).then(async (result) => {
            let orderUuid = null;
            let orderPartUuid = null;
            await query('post', 'addNewOrderV2', {
              parts: [
                result.uuid,
              ],
            }, {
              token: localStorage.getItem('token'),
            }).then((order) => {
              orderUuid = order.uuid;
              orderPartUuid = order.order_spec[0];
            });
            await query('post', 'addFilesToOrderPartV2', {
              uuid: orderPartUuid,
              files: [
                {
                  hash: item.hash,
                  time_stamp: item.timeStamp,
                  file_name: item.fileName,
                },
              ],
            }).then(() => {
              router.push(`/edit-order/${orderUuid}`);
            });
          });
          commit('setDetail', item);
          resolve(item);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  uploadFileOld({ commit }, data) {
    const secretKey = localStorage.getItem('token');
    const headers = {};
    if (secretKey) {
      headers.token = secretKey;
    }
    return new Promise((resolve, reject) => {
      query(data.method, data.url, data.data, headers, data.onUploadProgress)
        .then((item) => {
          commit('setDetail', item);
          resolve(item);
          /* window.location.href = 'https://lk.i5.solutions/#/order?h=' + item.hash + '&t='
            + item.timeStamp + '&n=' + item.fileName + '&e='
            + item.ext + '&l=' + index.state.language; */
          router.push({
            path: 'part',
            query:
              {
                h: item.hash,
                t: item.timeStamp,
                n: item.fileName,
                e: item.ext,
              },
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
