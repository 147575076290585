<template>
  <div class="block block__center">
    <CheckModal
      :title="text.errors_found"
      :show-footer="false"
      :show="showCheckModal"
      :order="checkOrderData"
      @close="closeCheckModal"
      :check-data="checkData" />
    <Modal
      @ok="deleteOrder"
      :title="text.delete_title"
      :ok-text="text.delete"
      @cancel="() => {
        setShowDelete(false, null);
      }"
      :show="showDeleteModal">
      <template slot="body">
        <div v-if="deletedOrder">
          {{text.delete_text[language]}}: {{deletedOrder.name}}?
        </div>
      </template>
    </Modal>
    <Modal
      @ok="cancelOrder"
      :title="text.cancel"
      :ok-text="text.cancel"
      :cancel-text="text.cancel_back"
      @cancel="() => {
        setShowCancel(false, null);
      }"
      :show="showCancelModal">
      <template slot="body">
        <div v-if="deletedOrder">
          {{text.cancel_text[language]}}: {{deletedOrder.name}}?
        </div>
      </template>
    </Modal>
    <div class="home__container">
      <div style="width: 100%;">
        <div class="orders__title">
          {{text.title[language]}}
        </div>
        <OrderStatuses />
        <div v-if="activeStatus === 11" class="orders__upload_external-container">
          <FileUploadI5 style="margin-bottom: 20px;"/>
        </div>
        <div v-if="orders && orders.length > 0 && loading">
          <div v-for="(order, i) in orders" v-bind:key="order.uuid">
            <Card
              :order="order"
              :index="i"
              @setHeight="setHeight($event)"
              @setTop="setTop($event)"
              @checkOrder="checkOrder($event)"
              @setShowCancel="setShowCancel($event.val, $event.order)"
              @setShowDelete="setShowDelete($event.val, $event.order)" />
          </div>
        </div>
        <div
          class="orders__empty"
          v-else-if="!orders || orders.length === 0 && loading">
          {{text.empty[language]}}
        </div>
        <div v-else class="orders__empty">
          <a-spin size="large"></a-spin>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { query } from '@/utilites/axios';
import text from '@/assets/text/orders.json';
import Modal from '@/components/modal/Modal.vue';
import OrderStatuses from '@/components/orderStatuses/OrderStatuses.vue';
import FileUploadI5 from '@/components/fileUploadI5/FileUploadI5.vue';
import CheckModal from '@/pages/orderOffers/components/checkModal/CheckModal.vue';
import func from '@/utilites/functions';
import Card from './components/card/Card.vue';

export default {
  name: 'Orders',
  data() {
    return {
      text,
      func,
      moment,
      unsubscribe: () => {},
      types: ['draft', 'check', 'application', 'in-work', 'canceled', 'finished'],
      typesWithId: {
        11: 'draft',
        12: 'check',
        9: 'application',
        4: 'in-work',
        5: 'canceled',
        6: 'finished',
        8: 'request',
        3: 'awaiting-payment',
        7: 'delivery',
      },
      activeType: 'draft',
      activeId: 11,
      orders: [],
      activeOrders: null,
      draft_orders: [],
      check_orders: [],
      application_orders: [],
      in_work_orders: [],
      finished_orders: [],
      canceled_orders: [],
      request_orders: [],
      awaiting_payment_orders: [],
      delivery_orders: [],
      showDeleteModal: false,
      deletedOrder: null,
      showCancelModal: false,
      statuses: {
        draft: null,
        check: null,
        application: null,
        request: null,
        awaitingPayment: null,
        inWork: null,
        delivery: null,
        canceled: null,
        finished: null,
      },
      cardsHeight: [],
      cardsTop: 0,
      resultStatuses: [],
      lastLoadedPage: 0,
      totalPages: 0,
      loadedHeight: 0,
      needLoad: false,
      uploadPercent: null,
      checkData: null,
      checkOrderData: null,
      showCheckModal: false,
      loading: false,
    };
  },
  props: {
    scrollTop: {
      type: [Number, String],
    },
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.loading = false;
          this.activeId = this.$store.getters['orders/getStatusIdByName'](val);
          this.$store.dispatch('orders/changeActiveStatus', this.activeId);
          this.lastLoadedPage = 0;
          this.totalPages = 0;
          this.cardsHeight = [];
          this.needLoad = false;
          this.orders = [];
          this.setActiveId(this.activeId);
        }
      },
    },
    scrollTop: function (val) {
      if (this.loadedHeight && val >= this.loadedHeight
        && this.totalPages > this.lastLoadedPage && this.needLoad) {
        this.needLoad = false;
        this.addOrders(
          this.lastLoadedPage + 1,
          this.activeId
          || this.activeId === 0 ? this.activeId : 11,
          5,
        );
      }
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    width() {
      return this.$store.state.index.width;
    },
    activeStatus() {
      return this.$store.state.orders.activeId;
    },
  },
  methods: {
    goTo(path, id = '') {
      this.func.routerPush(`${path}${id}`);
    },
    getOrders(page, status, linePerPage) {
      this.lastLoadedPage = page;
      return new Promise((resolve) => {
        query('post', 'getCurrentCompaniesOrdersV2', {
          page: page,
          line_per_page: linePerPage,
          filters: {
            id_status: [status],
          },
        }, {
          token: localStorage.getItem('token'),
        }).then((orders) => {
          this.loading = true;
          this.orders = orders.table;
          this.totalPages = orders.total_pages;
          if (this.totalPages > 1) {
            this.needLoad = true;
          }
          resolve();
        }).catch(() => {
          this.loading = true;
        });
      });
    },
    addOrders(page, status, linePerPage) {
      this.lastLoadedPage = page;
      return new Promise((resolve) => {
        query('post', 'getCurrentCompaniesOrdersV2', {
          page: page,
          line_per_page: linePerPage,
          filters: {
            id_status: [status],
          },
        }, {
          token: localStorage.getItem('token'),
        }).then((orders) => {
          this.orders = [...this.orders, ...orders.table];
          // this.totalPages = orders.total_pages;
          this.$nextTick(() => {
            this.needLoad = true;
          });
          resolve();
        });
      });
    },
    setActiveStatus(val) {
      this.activeType = val;
      if (val === 'draft') {
        this.activeOrders = this.draft_orders;
      } else if (val === 'check') {
        this.activeOrders = this.check_orders;
      } else if (val === 'application') {
        this.activeOrders = this.application_orders;
      } else if (val === 'in-work') {
        this.activeOrders = this.in_work_orders;
      } else if (val === 'finished') {
        this.activeOrders = this.finished_orders;
      } else if (val === 'canceled') {
        this.activeOrders = this.canceled_orders;
      } else if (val === 'request') {
        this.activeOrders = this.request_orders;
      } else if (val === 'awaiting-payment') {
        this.activeOrders = this.awaiting_payment_orders;
      } else if (val === 'delivery') {
        this.activeOrders = this.delivery_orders;
      }
    },
    setShowDelete(val, order) {
      this.showDeleteModal = val;
      this.deletedOrder = order;
    },
    setShowCancel(val, order) {
      this.showCancelModal = val;
      this.deletedOrder = order;
    },
    deleteOrder() {
      query('delete', 'deleteOrderV2', {
        uuid: this.deletedOrder.uuid,
      }, {
        token: localStorage.getItem('token'),
      }).then(() => {
        this.setShowDelete(false, null);
        this.getOrders(
          1,
          this.activeId,
          5,
        );
      }).catch(() => {
        this.setShowDelete(false, null);
      });
    },
    cancelOrder() {
      query('post', 'cancelOrderV2', {
        uuid: this.deletedOrder.uuid,
      }, {
        token: localStorage.getItem('token'),
      }).then(() => {
        this.setShowCancel(false, null);
        this.getOrders(
          1,
          this.activeId,
          5,
        );
      }).catch(() => {
        this.setShowCancel(false, null);
      });
    },
    setActiveId(val) {
      // this.activeId = val;
      this.getOrders(1, val, 5);
    },
    getOrdersStatuses() {
      query('post', 'getCurrentCompaniesOrdersStatuses', {}, {
        token: localStorage.getItem('token'),
      }).then((statuses) => {
        for (const status of statuses) {
          if (status.id === 8 || status.id === 3 || status.id === 7) {
            if (status.count) {
              if (status.id === 8) {
                this.statuses.request = status;
              } else if (status.id === 3) {
                this.statuses.awaitingPayment = status;
              } else if (status.id === 7) {
                this.statuses.delivery = status;
              }
            }
          } else {
            if (status.id === 11) {
              this.statuses.draft = status;
            } else if (status.id === 12) {
              this.statuses.check = status;
            } else if (status.id === 9) {
              this.statuses.application = status;
            } else if (status.id === 4) {
              this.statuses.inWork = status;
            } else if (status.id === 5) {
              this.statuses.canceled = status;
            } else if (status.id === 6) {
              this.statuses.finished = status;
            }
          }
        }
        for (const item of Object.keys(this.statuses)) {
          if (this.statuses[item]) {
            this.resultStatuses.push(this.statuses[item]);
          }
        }
      });
    },
    setHeight(e) {
      this.cardsHeight[e.index] = e.height;
      this.setLoadedHeight();
    },
    setTop(val) {
      this.cardsTop = val;
      this.setLoadedHeight();
    },
    setLoadedHeight() {
      let sum = 0;
      sum += this.cardsTop;
      this.cardsHeight.forEach((el) => {
        sum += el;
      });
      sum -= window.innerHeight;
      this.loadedHeight = sum;
    },
    uploadFile(formData, index, name) {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        if (token) {
          query('put', 'addFile', formData, {
            token,
          }, (e) => {
            const percent = String(Math.round((e.loaded * 100) / e.total));
            this.uploadPercent = percent;
          }).then((item) => {
            query('post', 'addNewPartV2', {
              code: '1',
              name: name,
              files: [],
            }, {
              token: localStorage.getItem('token'),
            }).then(async (result) => {
              let orderUuid = null;
              let orderPartUuid = null;
              await query('post', 'addNewOrderV2', {
                parts: [
                  result.uuid,
                ],
              }, {
                token: localStorage.getItem('token'),
              }).then((order) => {
                orderUuid = order.uuid;
                orderPartUuid = order.order_spec[0];
              });
              await query('post', 'addFilesToOrderPartV2', {
                uuid: orderPartUuid,
                files: [
                  {
                    hash: item.hash,
                    time_stamp: item.timeStamp,
                    file_name: item.fileName,
                  },
                ],
              }).then(() => {
                this.func.routerPush(`/edit-order/${orderUuid}`);
              });
            });
            resolve(item);
          }).catch((err) => {
            if (err.response.status === 401) {
              this.isShowAuth = true;
            }
            reject(err);
          });
        } else {
          this.isShowAuth = true;
          reject();
        }
      });
    },
    checkOrder(data) {
      const uuid = data.uuid;
      query('post', 'checkOrder', {
        uuid: uuid,
      }, {
        token: localStorage.getItem('token'),
      }).then((checkData) => {
        if (!checkData.check) {
          this.checkData = checkData;
          this.checkOrderData = data;
          this.showCheckModal = true;
        } else {
          this.goTo('/order-offers/', uuid);
        }
      });
    },
    closeCheckModal() {
      this.showCheckModal = false;
      this.checkData = null;
      this.checkOrderData = null;
    },
  },
  mounted() {
    this.loading = false;
    this.getOrdersStatuses();
    if (!this.activeId) {
      this.$store.dispatch('orders/changeActiveStatus', 11);
      this.getOrders(
        1,
        11,
        5,
      );
    } else {
      this.$store.dispatch('orders/changeActiveStatus', this.activeId);
      this.getOrders(
        1,
        this.activeId,
        5,
      );
    }
    this.$store.commit('user/setAfterAuthorize', () => {
      this.getOrdersStatuses();
      if (!this.activeId) {
        this.$store.dispatch('orders/changeActiveStatus', 11);
        this.getOrders(
          1,
          11,
          5,
        );
      } else {
        this.$store.dispatch('orders/changeActiveStatus', this.activeId);
      }
    });
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'user/setUser') {
        if (mutation.payload) {
          this.lastLoadedPage = 0;
          this.totalPages = 0;
          this.cardsHeight = [];
          this.needLoad = false;
          this.orders = [];
          this.setActiveId(this.activeId);
          this.$store.dispatch('orders/getOrderStatuses');
        }
      } else if (mutation.type === 'index/setLanguage') {
        this.lastLoadedPage = 0;
        this.totalPages = 0;
        this.cardsHeight = [];
        this.needLoad = false;
        this.orders = [];
        this.setActiveId(this.activeId);
      }
    });
  },
  beforeDestroy() {
    this.$store.commit('user/setAfterAuthorize', () => {});
    this.unsubscribe();
  },
  components: {
    Modal,
    OrderStatuses,
    Card,
    CheckModal,
    FileUploadI5,
  },
};
</script>
