<template>
  <div class="home__container home__media_container" style="margin-bottom: 20px;">
    <div style="width: 100%">
      <div class="home__companies_title">
        {{text.manufacturing_companies[language]}}
      </div>
      <div class="home__companies_subtitle">
        {{text.manufacturing_companies_subtitile[language]}}
      </div>
      <div
        :class="width > t_lg ? 'block' : ''"
        v-if="companies && companies.length === 5">
        <div
          class="companies__container"
          @mouseenter="onHandleMouseEnter(company.uuid)"
          @mouseleave="onHandleMouseLeave"
          @click="onHandleClick(company.uuid)"
          style="width: 100%"
          v-for="company in companies.filter((el, i) => i <= 1)"
          v-bind:key="`first_${company.uuid}`">
          <img
            class="companies__image"
            :src="company.img" />
          <div class="companies__block">
            <div class="block block__align-center">
              <div>
                <div
                  v-if="company.logo"
                  class="companies__logo_container">
                  <img
                    class="companies__logo_img"
                    :src="company.logo" />
              </div>
              </div>
              <div
                v-if="company.name"
                class="companies__text">
                {{company.name}}
              </div>
            </div>
          </div>
          <div
            :style="activeUuid === company.uuid ? 'opacity: .9' : 'opacity: .8'"
            class="companies__image-wrapper"></div>
        </div>
      </div>
      <div :class="width > t_lg ? 'block' : ''">
        <div
          class="companies__container"
          @mouseenter="onHandleMouseEnter(company.uuid)"
          @mouseleave="onHandleMouseLeave"
          @click="onHandleClick(company.uuid)"
          style="width: 100%"
          v-for="company in companies.filter((el, i) => i > 1 && i <= 5)"
          v-bind:key="`first_${company.uuid}`">
          <img
            class="companies__image"
            :src="company.img" />
          <div class="companies__block">
            <div class="block block__align-center">
              <div
                v-if="company.logo"
                class="companies__logo_container">
                <img
                  class="companies__logo_img"
                  :src="company.logo" />
              </div>
              <div
                v-if="company.name"
                class="companies__text">
                {{company.name}}
              </div>
            </div>
          </div>
          <div
            :style="activeUuid === company.uuid ? 'opacity: .9' : 'opacity: .8'"
            class="companies__image-wrapper"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { query } from '@/utilites/axios';
import text from '@/assets/text/home.json';

export default {
  name: 'Companies',
  data() {
    return {
      text,
      companies: null,
      activeUuid: null,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    width() {
      return this.$store.state.index.width;
    },
  },
  watch: {
    language: function () {
      this.getCompanies();
    },
  },
  methods: {
    getCompanies() {
      query('post', 'getCompaniesMainPage', {
        lang: this.language,
      }, {}).then((result) => {
        this.companies = result;
      });
    },
    onHandleMouseEnter(val) {
      this.activeUuid = val;
    },
    onHandleMouseLeave() {
      this.activeUuid = null;
    },
    onHandleClick(uuid) {
      const routeData = this.$router.resolve({ path: `/company/${uuid}` });
      window.open(routeData.href, '_blank');
    },
  },
  created() {
    this.getCompanies();
  },
};
</script>
