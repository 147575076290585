<template>
  <div
    :class="`input-i5 ${labelCol ? 'input-i5__col' : ''}`"
    :style="`text-align: ${labelPosition}`">
    <label :for="name">{{label}}
      <span v-if="required" >*</span>
      <span v-if="subLabel && !error" class="input-i5__sublabel">
        {{subLabel}}
      </span>
      <span v-if="error" class="input-i5__error">
        {{error}}
      </span>
    </label>
     <div style="position: relative;">
       <input
         :tabindex="ti"
         :value="value"
         :id="name"
         :step="step"
         :type="type"
         :class="`${error ? 'input-i5_error' : ''}`"
         :style="`text-align: ${textPosition}; ${
           search ? 'padding-right: 20px;' : ''} height: ${
           height}px !important; margin-bottom: ${
           marginBottom}px !important; ${maxWidth ? `max-width: ${maxWidth}px` : ''}; ${showError
    ? 'background-color: rgba(185, 42, 33, .3) !important;' : ''}`"
         :name="name"
         autocomplete="off"
         :readonly="readonly === true"
         :disabled="disabled === true"
         @input="handleChange"/>
       <Search v-if="search" class="input-i5__search" />
     </div>
  </div>
</template>

<script>
import Search from '@/assets/pictures/form/search.svg';

export default {
  name: 'Input',
  data() {
    return {
      value: '',
      showError: false,
    };
  },
  props: {
    defaultValue: {
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelPosition: {
      type: String,
      default: 'start', // start center end
    },
    labelCol: {
      type: Boolean,
      default: false,
    },
    subLabel: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    textPosition: {
      type: String,
      default: 'left',
    },
    required: {
      type: Boolean,
      default: false,
    },
    ti: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    step: {
      type: Number,
    },
    search: {
      type: Boolean,
      default: false,
    },
    error: {
      type: [Object, String],
      default: null,
    },
    height: {
      type: Number,
      default: 35,
    },
    marginBottom: {
      type: Number,
      default: 15,
    },
    maxWidth: {
      type: Number,
    },
  },
  watch: {
    defaultValue: function (val) {
      this.value = val;
    },
  },
  methods: {
    handleChange(evt) {
      if (this.type === 'number') {
        let showedValue = '';
        let realValue = '';
        if (+evt.target.value || evt.target.value === '0') {
          if (this.max && +evt.target.value > this.max) {
            // evt.target.value = this.max;
            realValue = this.max;
            showedValue = evt.target.value;
            this.showError = true;
          } else if (this.min && +evt.target.value < this.min) {
            realValue = this.min;
            showedValue = evt.target.value;
            this.showError = true;
            // evt.target.value = this.min;
          } else {
            showedValue = evt.target.value;
            realValue = evt.target.value;
            this.showError = false;
          }
          evt.target.value = showedValue;
          if (!this.showError) {
            this.$emit('change', realValue);
          }
        }
        // evt.target.value = +evt.target.value;
        this.value = evt.target.value;
      } else {
        this.value = evt.target.value;
        this.$emit('change', evt.target.value);
      }
    },
  },
  mounted() {
    this.value = this.defaultValue;
  },
  components: {
    Search,
  },
};
</script>
