const state = () => ({
  // Деталь загрузилась
  modelReady: false,
  // При нажатии на поверхность
  surfaceClick: () => {},
  // Выделяем одну поверхность, остальные без изменения
  // {surface: 'Face1', color: { r: 0.674, g: 0.074, b: 0.145 }}
  decorateOneSurface: () => {},
  // Раскрашиваем поверхности в цвета, остальные становятся серыми
  // [{surfaces: ['Face1'], color: { r: 0.674, g: 0.074, b: 0.145 }}]
  decorateSurfaces: () => {},
  // Очищаем все поверхности
  clearSurfaces: () => {},
  // Рисуем линию с палитрой под моделью
  decorateViewer: () => {},
  // Тип переключателя старый или новый model или component, влияет на клики по детали
  changeSelectorType: () => {},
  // подсвечиваем рамку модели если нужно взаимодействие
  showModelFrame: false,
});

const mutations = {
  setModelReady(state, data) {
    state.modelReady = data;
  },
  setSurfaceClick(state, data) {
    state.surfaceClick = data;
  },
  setDecorateOneSurface(state, data) {
    state.decorateOneSurface = data;
  },
  setDecorateSurfaces(state, data) {
    state.decorateSurfaces = data;
  },
  setClearSurfaces(state, data) {
    state.clearSurfaces = data;
  },
  setDecorateViewer(state, data) {
    state.decorateViewer = data;
  },
  setChangeSelectorType(state, data) {
    state.changeSelectorType = data;
  },
  setShowModelFrame(state, data) {
    state.showModelFrame = data;
  },
};

const getters = {
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
