<template>
  <div class="block block__center">
    <CheckModal
      :show="showCheckModal"
      :order="order"
      @ok="sendOrderToManufacturer($event)"
      @close="closeCheckModal"
      :check-data="checkData" />
    <KpModal
      :show="showKpModal"
      :uuid="$route.params.id"
      :company-id="activeIdManufacturer"
      :show-left="false"
      @close="setShowKpModal(false)" />
    <div class="home__container">
      <div style="width: 100%;">
        <Order
          :order="order"
          :edit="false"
          :custom-title="''"/>
        <div class="block__flex" style="margin: 0 15px;">
          <div class="part__container_filters" v-if="width > t_lg">
            <div class="part__card_wrapper part__card_filter">
              <div class="part__filter_title">
                {{part.filter_title[language]}}
              </div>
              <Filters
                @setFilter="setFilter($event)"
                :filters="filters" />
            </div>
          </div>
          <div class="part__container_prices" v-if="kp && kp.length > 0 && loading">
            <div v-for="(item, index) in kp"
                 v-bind:key="`kp_${index}`">
              <Card
                :item="item"
                @requestCost="checkOrder($event)"
                @openKp="openOrderKpModal(item)" />
            </div>
          </div>
          <div v-if="(!kp || kp.length === 0) && loading" class="part__container_prices">
            <div style="width: 100%;">
              <div class="order-offers__card_wrapper order-offers__card_wrapper_real">
                <div>
                  <img
                    src="@/assets/pictures/order/empty.png"
                    style="width: 100%; height: auto; object-fit: scale-down"/>
                </div>
                <div
                  class="order-offers__card_empty"
                  style="margin: 15px 0; text-align: center">
                  {{text.empty_text[language]}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { query } from '@/utilites/axios';
import Order from '@/pages/editOrder/components/order/Order.vue';
import part from '@/assets/text/part.json';
import text from '@/assets/text/order-offers.json';
import Filters from '@/components/filter/TableFilter.vue';
import Card from './components/card/CardV2.vue';
import KpModal from './components/kpModal/KpModal.vue';
import CheckModal from './components/checkModal/CheckModal.vue';

export default {
  name: 'TestOrderOffers',
  data() {
    return {
      part,
      text,
      unsubscribe: () => {},
      order: null,
      kp: null,
      filters: null,
      showKpModal: false,
      activeIdManufacturer: null,
      externalFilters: null,
      checkData: null,
      showCheckModal: null,
      manufacturerIdToOrder: null,
      loading: false,
      uuids: [
        'be0a15b8-7c1a-4c0c-996a-1ae0d8a3f3c9',
        '148e4a6c-21a1-4573-9760-7f018cb4ab0a',
        '9388d8f0-8bac-4bef-abd5-c4c29deb36de',
        '956f330f-82af-4f51-8177-c6678dde52ac',
      ],
    };
  },
  computed: {
    m_lg() {
      return this.$store.state.index.m_lg;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    t_md() {
      return this.$store.state.index.t_md;
    },
    language() {
      return this.$store.state.index.language;
    },
    width() {
      return this.$store.state.index.width;
    },
  },
  methods: {
    getOrder() {
      return new Promise((resolve, reject) => {
        query('post', 'getOrderInfoV2', {
          uuid: this.$route.params.id,
        }, {
        }).then((result) => {
          this.order = result.order_info;
          resolve();
        }).catch(() => {
          reject();
        });
      });
    },
    setFilter(data) {
      this.externalFilters = data;
      this.$nextTick(() => {
        this.getKP(1);
      });
    },
    getKP(page) {
      this.loading = false;
      return new Promise((resolve) => {
        const queryData = {
          uuid: this.$route.params.id,
          page: page,
          line_per_page: 100,
        };
        if (this.externalFilters) {
          queryData.filters = this.externalFilters;
        }
        query('post', 'getAllKPbyOrderV2', queryData, {}).then((kp) => {
          if (page === 1) {
            this.loading = true;
            this.kp = kp.table;
          } else {
            this.kp = [...this.kp, ...kp.table];
          }
          if (kp.filters) {
            this.filters = kp.filters;
          }
          if (kp.total_pages > page) {
            this.getKP(page + 1).then(() => {
              resolve();
            });
          } else {
            resolve();
          }
        });
      });
    },
    setShowKpModal(val) {
      this.showKpModal = val;
    },
    openOrderKpModal(val) {
      this.activeIdManufacturer = val.id_manufacturer;
      this.setShowKpModal(true);
    },
    checkOrder(data) {
      const uuid = data.id_order;
      this.manufacturerIdToOrder = data.id_manufacturer;
      query('post', 'checkOrder', {
        uuid: uuid,
      }, {
        token: localStorage.getItem('token'),
      }).then((checkData) => {
        this.checkData = checkData;
        this.showCheckModal = true;
        this.getKP(1);
      });
    },
    closeCheckModal() {
      this.showCheckModal = false;
      this.checkData = null;
      this.manufacturerIdToOrder = null;
    },
    sendOrderToManufacturer(orderUuid) {
      query('post', 'sendOrderToManufacturers', {
        uuid: orderUuid,
        manufacturers: [this.manufacturerIdToOrder],
      }, {}).then(() => {
        this.closeCheckModal();
        this.getKP(1);
      });
    },
  },
  mounted() {
    const index = this.uuids.findIndex((el) => el === this.$route.params.id);
    if (index < 0) {
      this.$router.push('/');
    }
    this.getOrder();
    this.getKP(1);
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'user/setUser') {
        if (mutation.payload) {
          this.order = null;
          this.kp = null;
          this.filters = null;
          this.getOrder();
          this.getKP(1);
        }
      } else if (mutation.type === 'index/setLanguage') {
        this.order = null;
        this.kp = null;
        this.filters = null;
        this.getOrder();
        this.getKP(1);
      }
    });
  },
  beforeDestroy() {
    this.$store.commit('user/setAfterAuthorize', () => {});
    this.unsubscribe();
  },
  components: {
    Order,
    Filters,
    Card,
    KpModal,
    CheckModal,
  },
};
</script>
