<template>
  <div
    class="upload-u__wrapper_universal"
    :style="`${borderColor ? `border-color: ${borderColor}; ` : ''}`"
    ondragover="event.preventDefault()"
       @dragenter="handleDragEnter" @dragleave="handleDragLeave" @drop="handleDrop">
    <div v-if="(!isStart || isStart && (!percent || +percent === 0))
    && !errorStatus" style="height: 100%;">
      <slot name='body'></slot>
    </div>
    <div
      class="block block__align-center block__center" style="height: 100%;"
      v-show="isStart && percent && +percent < 100 && !errorStatus">
      <div class="upload-u__loading_wrapper">
        <div>
          <div class="block block__center component__margin">
            <CircleProgress :percent="+percent" />
          </div>
          <div class="upload-u__loading_title">
            {{ text.loading_title[language] }}
          </div>
          <div class="upload-u__loading_subtitle">
            {{ text.loading_subtitle[language] }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="block block__align-center block__center" style="height: 100%;"
      v-show="+percent === 100 && isStart && !errorStatus">
      <div class="upload-u__loading_wrapper">
        <div>
          <div class="block block__center block__relative component__margin">
            <Spin class="upload-u__loading_spin" />
            <div class="upload-u__loading_server-logo">
              <Server />
            </div>
          </div>
          <div class="upload-u__loading_title">
            {{ text.serverside_title[language] }}
          </div>
          <div class="upload-u__loading_subtitle">
            {{ text.serverside_subtitle[language] }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="block block__align-center block__center" style="height: 100%;"
      v-show="errorStatus">
      <div class="upload-u__loading_wrapper">
        <div>
          <div class="block block__center component__margin" v-if="width > t_lg">
            <ErrorLogo />
          </div>
          <div class="upload-u__loading_title">{{ errorTitle }}</div>
          <div class="upload-u__loading_subtitle">{{ errorSubtitle }}</div>
          <div class="block block__center component__margin-top">
            <a-button type="primary" @click="hideErrors">
              {{ text.close_error[language] }}
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <input id="file" type="file"
           @mouseleave="handleMouseLeave()"
           @mouseenter="handleMouseEnter()"
           :accept="formats"
           class="upload-u__input upload-u__input_universal"
           @change="handleChange($event)">
  </div>
</template>

<script>
import text from '@/assets/text/universal-upload.json';

const CircleProgress = () => import('@/components/circleProgress/CircleProgress.vue');
const Spin = () => import('@/assets/pictures/i5/spin.svg');
const Server = () => import('@/assets/pictures/upload/server.svg');
const ErrorLogo = () => import('@/assets/pictures/upload/error.svg');

export default {
  name: 'Upload',
  data() {
    return {
      text,
      unsubscribe: () => {},
      componentHeight: 95,
      componentWidth: 456,
      isStart: false,
      percent: null,
      errorStatus: null,
      errorTitle: null,
      errorSubtitle: null,
      showFileLoad: false,
      showServerLoad: true,
      timeOut: false,
      formData: new FormData(),
      timer: null,
      partCount: null,
      isGetResult: false,
      filename: '',
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    width() {
      return this.$store.state.index.width;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
  },
  props: {
    uploadFunction: {
      type: Function,
      default: () => {},
    },
    uploadPercent: {
      type: [Number, String],
    },
    index: {
      type: [Number, String],
    },
    borderColor: {
      type: String,
    },
    formats: {
      type: String,
      default: '.stp,.STP,.STEP,.step,.stl,.STL',
    },
  },
  watch: {
    uploadPercent: function (val) {
      this.percent = +val;
    },
  },
  methods: {
    handleMouseEnter() {
      this.$emit('enter');
    },
    handleMouseLeave() {
      this.$emit('leave');
    },
    hideErrors() {
      this.errorStatus = null;
      this.errorTitle = null;
      this.errorSubtitle = null;
      clearTimeout(this.timer);
      this.percent = null;
      this.isStart = false;
      document.getElementById('file').value = '';
      this.formData = new FormData();
      this.partCount = null;
    },
    clearUpload() {},
    updateErrors() {
      if (this.errorStatus === 'NE') {
        this.errorTitle = this.text.error_network_title[this.language];
        this.errorSubtitle = this.text.error_network_subtitle[this.language];
      } else if (this.errorStatus === 405) {
        this.errorTitle = this.text.error_405_title[this.language];
        this.errorSubtitle = `${this.text.error_405_subtitle_1[this.language]} ${
          this.partCount
        } ${this.text.error_405_subtitle_2[this.language]}`;
      } else if (this.errorStatus === 415) {
        this.errorTitle = this.text.error_415_title[this.language];
        this.errorSubtitle = this.text.error_415_subtitle[this.language];
      } else if (this.errorStatus === 500) {
        this.errorTitle = this.text.error_500_title[this.language];
        this.errorSubtitle = this.text.error_500_subtitle[this.language];
      } else if (this.errorStatus === 'SIZE') {
        this.errorTitle = this.text.error_size_title[this.language];
        this.errorSubtitle = this.text.error_size_subtitle[this.language];
      }
    },
    startUploadFunction(name) {
      this.uploadFunction(this.formData, this.index, name).then(() => {
        this.isStart = false;
        this.percent = null;
        this.showFileLoad = false;
        this.showServerLoad = false;
        this.isGetResult = true;
        this.$emit('ok', this.index);
      }).catch((err) => {
        this.isStart = false;
        this.showFileLoad = false;
        this.showServerLoad = false;
        if (err.toJSON && err.toJSON().message === 'Network Error') {
          this.errorStatus = 'NE';
          this.errorTitle = this.text.error_network_title[this.language];
          this.errorSubtitle = this.text.error_network_subtitle[this.language];
        }
        if (err.response.status === 405) {
          this.errorStatus = 405;
          this.errorTitle = this.text.error_405_title[this.language];
          this.errorSubtitle = `${this.text.error_405_subtitle_1[this.language]} ${
            err.response.data.parts
          } ${this.text.error_405_subtitle_2[this.language]}`;
          this.partCount = err.response.data.parts;
        } else if (err.response.status === 400) {
          this.errorStatus = 400;
          this.errorTitle = this.text.error_400_title[this.language];
          this.errorSubtitle = this.text.error_400_subtitle[this.language];
        } else if (err.response.status === 415) {
          this.errorStatus = 415;
          this.errorTitle = this.text.error_415_title[this.language];
          this.errorSubtitle = this.text.error_415_subtitle[this.language];
        } else if (err.response.status === 500) {
          this.errorStatus = 500;
          this.errorTitle = this.text.error_500_title[this.language];
          this.errorSubtitle = this.text.error_500_subtitle[this.language];
        } else {
          throw err;
        }
      });
    },
    handleChange(e) {
      this.isGetResult = false;
      const uploadFile = () => {
        this.formData = new FormData();
        this.filename = e.target.files[0].name;
        this.formData.append('file', e.target.files[0]);
        // const name = e.target.files[0].name;
        if ((e.target.files[0].size / 1000000) < 50) {
          this.timer = setTimeout(() => {
            this.timeOut = true;
            if (!this.isGetResult) {
              this.isStart = true;
            } else {
              this.isStart = false;
            }
          }, 1500);
          this.startUploadFunction(this.filename);
        } else {
          this.errorStatus = 'SIZE';
          this.errorTitle = this.text.error_size_title[this.language];
          this.errorSubtitle = this.text.error_size_subtitle[this.language];
        }
      };
      uploadFile();
    },
    handleDrop() {
      this.$emit('dDrop');
    },
    handleDragEnter() {
      this.$emit('dEnter');
    },
    handleDragLeave() {
      this.$emit('dLeave');
    },
    update() {
      clearTimeout(this.timer);
      this.isStart = false;
      this.percent = null;
      this.errorStatus = null;
      this.errorTitle = null;
      this.errorSubtitle = null;
      this.showFileLoad = false;
      this.showServerLoad = true;
      this.timeOut = false;
      // this.formData = new FormData();
      this.timer = null;
      this.partCount = null;
      this.isGetResult = false;
      this.startUploadFunction(this.filename);
    },
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'index/setWidth') {
        this.componentHeight = this.$el.clientHeight;
        this.componentWidth = this.$el.clientWidth;
      } else if (mutation.type === 'index/setLanguage') {
        this.updateErrors();
      }
    });
    this.componentHeight = this.$el.clientHeight;
    this.componentWidth = this.$el.clientWidth;
    this.$emit('setUpdate', this.update);
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  components: {
    CircleProgress,
    Spin,
    Server,
    ErrorLogo,
  },
};
</script>
