<template>
  <div class="tech-req__wrapper">
    <div class="tech-req__left_wrapper">
      <div class="tech-req__left_menu">
        <div class="tech-req__left_menu_items">
          <div v-for="(item, index) in Object.keys(menuValues)" v-bind:key="index">
            <TechMenuItem :value="menuValues[item].value"
                          :index="index + 1"
                          :active="menuValues[item].active"
                          :disabled="menuValues[item].disabled"
                          :id="index" @click="setActiveMenu($event)" />
          </div>
        </div>
        <div class="tech-req__left_menu_static">
          <div class="tech-req__menu-item tech-req__menu-item_exit" @click="closePage">
            {{ tech.menu_exit[language] }}
          </div>
        </div>
      </div>
      <div class="tech-req__left_params">
        <MaterialAndBlank v-if="menuValues[activeMenu].type === 'material'" />
        <Roughness v-if="menuValues[activeMenu].type === 'roughness'"/>
        <Hardness v-if="menuValues[activeMenu].type === 'hardness'" />
      </div>
    </div>
    <div class="tech-req__model_wrapper">
      <ModelViewer :select-type="$store.state.tech.selectType"
                   :selected="returnSelectedSurfaces()"
                   :activeGroup="$store.state.tech.activeIndex"
                   :colorize="$store.state.tech.colorize"
                   :defaultGroups="$store.state.tech.defaultGroups"
                   @select="setSelectedSurfaces($event)"
                   :type="this.ext && this.ext.toLowerCase()" :src="setLink()" />
    </div>
  </div>
</template>

<script>
import packageJson from '@/../package.json';
import tech from '@/assets/text/tech.json';

const TechMenuItem = () => import('./components/MenuItem.vue');
const ModelViewer = () => import('@/components/modelViewer/ModelViewer.vue');
const MaterialAndBlank = () => import('./components/MaterialAndBlank.vue');
const Roughness = () => import('./components/Roughness.vue');
const Hardness = () => import('./components/Hardness.vue');

export default {
  name: 'TechnicalRequirements',
  data() {
    return {
      packageJson,
      tech,
      hash: null,
      file_name: null,
      ext: null,
      time_stamp: null,
      activeMenu: 0,
      menuValues: {
        0: {
          value: tech.menu_material,
          type: 'material',
          active: true,
          disabled: false,
        },
        1: {
          value: tech.menu_roughness,
          type: 'roughness',
          active: false,
          disabled: false,
        },
        /* 2: {
          value: tech.menu_tolerance,
          type: 'size',
          active: false,
          disabled: true,
        },
        3: {
          value: tech.menu_relative_position,
          type: 'form',
          active: false,
          disabled: true,
        },
        4: {
          value: tech.menu_hardness,
          type: 'hardness',
          active: false,
          disabled: false,
        },
        5: {
          value: tech.menu_threads,
          type: 'threads',
          active: false,
          disabled: true,
        },
        6: {
          value: tech.menu_gears,
          type: 'gears',
          active: false,
          disabled: true,
        },
        7: {
          value: tech.menu_slits,
          type: 'slits',
          active: false,
          disabled: true,
        },
        8: {
          value: tech.menu_common,
          type: 'common',
          active: false,
          disabled: true,
        },
        9: {
          value: tech.menu_analysis,
          active: false,
          type: 'analysis',
          disabled: true,
        }, */
      },
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    setActiveMenu(id) {
      if (this.menuValues[id].active === false) {
        this.menuValues[id].active = true;
        this.menuValues[this.activeMenu].active = false;
        this.activeMenu = id;
      }
    },
    setLink() {
      return `${packageJson.url}getFile?time_stamp=${this.time_stamp}&hash=${
        this.hash
      }&file_name=${this.file_name}&ext=${this.ext}`;
    },
    closePage() {
      this.$router.push({
        path: 'part',
        query:
          {
            h: this.hash,
            t: this.time_stamp,
            n: this.file_name,
            e: this.ext,
          },
      });
    },
    setSelectedSurfaces(data) {
      this.$store.commit('tech/setTempSurfaces', data);
    },
    returnSelectedSurfaces() {
      const tech = this.$store.state.tech;
      let result = [];
      if (tech.surfacesRoughness[tech.activeIndex[tech.activeIndex.length - 1]]
        && tech.surfacesRoughness[tech.activeIndex[tech.activeIndex.length - 1]].surfaces) {
        result = tech.surfacesRoughness[tech.activeIndex[tech.activeIndex.length - 1]].surfaces;
      }
      return result;
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!to.query.h || !to.query.t || !to.query.n) {
      next(from.path);
    } else {
      next((vm) => {
        if (to.query.token) {
          localStorage.setItem('token', to.query.token);
        }
        vm.$store.dispatch('user/getUser').then(() => {
          vm.$store.commit('tech/setPartId', {
            hash: to.query.h,
            time_stamp: to.query.t,
            file_name: to.query.n,
            ext: to.query.e,
          });
          vm.$store.dispatch('tech/getDataForUploadedFile', {
            data: {
              hash: to.query.h,
              time_stamp: to.query.t,
              file_name: to.query.n,
            },
          });
          const component = vm;
          component.hash = to.query.h;
          component.file_name = to.query.n;
          component.ext = to.query.e;
          component.time_stamp = to.query.t;
        }).catch(() => {
          next(from.path);
        });
      });
    }
  },
  mounted() {
  },
  components: {
    Hardness,
    TechMenuItem,
    ModelViewer,
    MaterialAndBlank,
    Roughness,
  },
};
</script>
