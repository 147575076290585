var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block block__center"},[(!_vm.error)?_c('Modal',{attrs:{"show":_vm.showDeleteModal,"ok-text":_vm.text.delete_part_ok,"title":_vm.text.delete_part_title},on:{"cancel":() => {
      _vm.showDeleteModal = false;
      _vm.deletePart = null;
    },"ok":function($event){return _vm.setDeletePart(_vm.deletePart.uuid)}}},[_c('template',{slot:"body"},[(_vm.deletePart)?_c('div',{staticStyle:{"padding":"20px"}},[_c('span',[_vm._v(" "+_vm._s(_vm.text.delete_part_text[_vm.language])+": "),_c('b',[_vm._v(_vm._s(_vm.deletePart.code)+"-"+_vm._s(_vm.deletePart.name))]),_vm._v(" ? ")])]):_vm._e()])],2):_vm._e(),(!_vm.error)?_c('FullSize',{attrs:{"type":_vm.fsType,"link":_vm.fsLink,"show":_vm.fsShow,"title":_vm.fsFileName},on:{"close":function($event){return _vm.setFullSize({
      fsFileName: null,
      fsLink: null,
      fsType: null,
      fsShow: false,
    })}}}):_vm._e(),(!_vm.error)?_c('CheckModalV2',{attrs:{"title":_vm.text.errors_found,"show-footer":false,"show":_vm.showCheckModalV2,"order":_vm.order,"check-data":_vm.checkData},on:{"close":_vm.closeCheckModal}}):_vm._e(),_c('div',{staticClass:"home__container"},[_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"width":"100%"}},[_c('OrderStatuses')],1),(!_vm.error)?_c('div',{staticStyle:{"width":"100%"}},[_c('Order',{attrs:{"back":"","order":_vm.order,"uuid":_vm.$route.params.id,"edit":_vm.order && _vm.order.id_status === 11},on:{"update":_vm.getOrder,"finish":() => {
          _vm.func.routerPush('/orders');
        },"save":function($event){return _vm.setShowCheckModal(true)},"view-cost":_vm.checkOrder}}),_vm._l((_vm.parts),function(part,index){return _c('div',{key:`part_${index}`},[_c('PartCard',{attrs:{"part":part,"index":index,"edit":part.edit,"fileFilter":_vm.fileFilters[part.uuid],"isDel":_vm.parts.length > 1,"order":_vm.order},on:{"setFullSize":function($event){return _vm.setFullSize($event)},"setEdit":function($event){return _vm.setEdit($event)},"setDelete":function($event){return _vm.setShowDeleteModal($event)},"save":function($event){return _vm.save($event.name, $event.value, $event.uuid)},"setHeight":function($event){return _vm.setHeight($event)},"setTop":function($event){return _vm.setTop($event)}}})],1)}),(_vm.order && _vm.order.id_status === 11)?_c('div',{style:(`margin-bottom: 20px; padding: 0 ${_vm.width > _vm.t_lg ? '20px' : '15px'};`)},[_c('UniversalUpload',{staticClass:"component__margin-bottom",attrs:{"border-color":"#B92A21","uploadFunction":_vm.addNewPart,"upload-percent":_vm.percent,"formats":`${'.stp,.STP,.STEP,.step,.stl,.STL,.pdf,.txt,.dwg,.dxf,.emf,.dwt'
        + ',.jpeg,.jpg,.png,.cdw,.heif,.raw,.docx,.xlsx,.sldprt,.dxf,.ipt'}`}},[_c('template',{slot:"body"},[_c('div',{staticClass:"edit-order__upload_container"},[_c('div',{staticClass:"edit-order__upload_small"},[_vm._v(" "+_vm._s(_vm.text.upload_first[_vm.language])+" ")]),_c('div',{staticClass:"edit-order__upload_title"},[_vm._v(" "+_vm._s(_vm.text.create_title[_vm.language])+" ")]),_c('div',{staticClass:"edit-order__upload_formats"},[_vm._v(" STP | STEP | STL | PDF | TXT | DWG | DXF | EMF | DWT ")]),_c('div',{staticClass:"edit-order__upload_first"},[_vm._v(" "+_vm._s(_vm.text.upload_title[_vm.language])+" ")]),_c('div',{staticClass:"edit-order__upload_small"},[_c('span',[_vm._v(_vm._s(_vm.text.upload_size[_vm.language])+" ")]),_c('b',[_c('span',[_vm._v("50")]),_c('span',[_vm._v(_vm._s(_vm.text.upload_mb[_vm.language]))])])])])])],2)],1):_vm._e(),_c('div',{style:(_vm.width <= _vm.t_lg ? 'padding: 0 15px; width: 100%' : 'width: 100%')},[(_vm.order.id_status !== 5)?_c('Button',{attrs:{"type":"primary","text":_vm.text.view_cost[_vm.language],"block":""},on:{"click":_vm.checkOrder}}):_vm._e()],1)],2):_vm._e(),(_vm.error)?_c('Page404'):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }