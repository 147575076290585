<template>
  <div class="orders__order_card">
    <div class="block block__between block__align-center" v-if="order">
      <div class="orders__order_title">
        {{text.order[language]}} № {{order.name}}
      </div>
      <div class="orders__order_title" v-if="width > t_lg">
        {{order.status}}
      </div>
    </div>
    <div class="block block__between" style="margin-bottom: 20px;" v-if="width > t_lg">
      <div style="width: 100%">
        <div class="orders__order_block-title" style="margin-bottom: 10px;">
          {{text.order_info[language]}}:
        </div>
        <div class="block__flex">
          <div>
            <div class="block block__align-center component__margin-bottom">
              <Price />
              <div
                class="component__margin-left edit-order__order_label">
                {{text.max_price[language]}}:
              </div>
              <div class="component__margin-left edit-order__order_value">
                {{order.max_price ? func.getMoney(order.max_price, currency || 'RUB', language)
                : text.not_set[language]}}
              </div>
            </div>
            <div class="block block__align-center component__margin-bottom">
              <Parts />
              <div
                class="component__margin-left edit-order__order_label">
                {{text.parts[language]}}
              </div>
              <div
                class="component__margin-left edit-order__order_value">
                {{order.parts_in_order ? order.parts_in_order : text.not_set2[language]}}
                {{order.parts_in_order ? text.pcs[language] : ''}}
              </div>
            </div>
            <div class="block block__align-center component__margin-bottom">
              <Files />
              <div
                class="component__margin-left edit-order__order_label">
                {{text.files[language]}} {{text.pcs[language]}}
              </div>
              <div
                class="component__margin-left edit-order__order_value">
                {{order.total_files ? order.total_files : text.not_set2[language]}}
                {{order.total_files ? text.pcs[language] : ''}}
              </div>
            </div>
            <div class="block block__align-center component__margin-bottom">
              <Weight />
              <div
                class="component__margin-left edit-order__order_label">
                {{text.total_weight[language]}}
              </div>
              <div
                class="component__margin-left edit-order__order_value">
                {{order.total_weight ? order.total_weight : text.not_set2[language]}}
                {{order.total_weight ? text.g[language] : ''}}
              </div>
            </div>
          </div>
          <div style="margin-left: 20px;">
            <div class="block block__align-center component__margin-bottom">
              <div
                class="component__margin-left edit-order__order_label">
                {{text.create_date[language]}}:
              </div>
              <div class="component__margin-left edit-order__order_value">
                {{moment(order.date_creation).format('DD.MM.YYYY')}}
              </div>
            </div>
            <div class="block block__align-center component__margin-bottom">
              <div
                class="component__margin-left edit-order__order_label">
                {{text.execution[language]}}:
              </div>
              <div class="component__margin-left edit-order__order_value">
                {{order.date_execution
                ? moment(order.date_execution).format('DD.MM.YYYY') : text.not_set3[language]}}
              </div>
            </div>
            <div class="block block__align-center component__margin-bottom">
              <div
                class="component__margin-left edit-order__order_label">
                {{text.deadline[language]}}:
              </div>
              <div class="component__margin-left edit-order__order_value">
                {{order.date_applications
                ? moment(order.date_applications).format('DD.MM.YYYY') : text.not_set3[language]}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%">
        <div
          class="orders__order_block-title"
          style="margin-bottom: 10px; text-align: right;">
          {{text.order_files[language]}}:
        </div>
        <div
          v-if="order.preview_imgs"
          class="block block__align-center block__right"
          style="margin-top: 20px; margin-bottom: 20px;">
          <div
            class="component__margin-left block__flex block__right"
            :style="`width: calc(100% / ${
                        order.preview_imgs.length});`"
            v-for="(preview, index) in order.preview_imgs"
            v-bind:key="`preview_${index}`">
            <img
              @error="setError"
              v-if="preview && preview !== 'default'"
              :src="preview"
              :style="`max-width: 100%; height: auto; max-height: 150px;`" />
          </div>
        </div>
      </div>
    </div>
    <div class="block" v-if="width > t_lg">
      <div
        class="component__margin-right"
        style="width: calc(100% / 3)">
        <Button
          block :text="text.edit_order[language]"
          v-if="order.id_status === 11"
          @click="goTo('/edit-order/', order.uuid)"
          type="attention" />
        <Button
          block :text="text.view_order[language]"
          v-else
          @click="goTo('/edit-order/', order.uuid)"
          type="attention" />
      </div>
      <div
        class="component__margin-right"
        style="width: calc(100% / 3)">
        <Button
          v-if="order.id_status === 11"
          block
          @click="checkOrder"
          :text="text.offers_on_order_automatic[language]" type="attention" />
        <Button
          v-else-if="order.id_status !== 5"
          @click="goTo('/order-offers/', order.uuid)"
          block
          :text="`${text.offers_on_order[language]} ${order.offers
                  ? '(' + order.offers + ')' : ''}`"
          type="attention" />
      </div>
      <div style="width: calc(100% / 3)">
        <Button
          v-if="order.id_status === 11"
          block
          :text="text.delete_order[language]"
          @click="setShowDelete(true, order)"
          type="default" />
        <Button
          v-if="order.id_status === 9 || order.id_status === 12"
          block
          @click="setShowCancel(true, order)"
          :text="text.cancel_order[language]"
          type="default" />
      </div>
    </div>
    <div v-if="width <= t_lg">
      <div style="width: 100%" v-if="order.preview_imgs && order.preview_imgs.length > 0">
        <div class="block">
          <div
            class="orders__order_m-preview-container"
            :style="`${order.preview_imgs.length > 1 ? 'width: 60%;'
                    : 'width: 100%;'}`">
            <img
              @error="setError"
              class="orders__order_m-preview"
              :src="order.preview_imgs[0]"  />
          </div>
          <div
            v-if="order.preview_imgs.length > 1"
            style="width: 40%;">
            <div
              :style="`padding: ${index === 0 ? 0 : '5px'} 0 0 5px;`"
              v-bind:key="`preview_${index}`"
              v-for="(preview, index) in order.preview_imgs.filter((el, i) => i > 0)">
              <div
                style="width: 100%;"
                class="orders__order_m-miniatures"
                v-if="order.preview_imgs.length > 1">
                <img
                  @error="setError"
                  class="orders__order_m-mini-preview"
                  :src="preview"  />
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%">
          <div class="orders__order_block-title" style="margin-bottom: 10px;">
            {{text.order_info[language]}}:
          </div>
          <div>
            <div class="block block__align-center component__margin-bottom">
              <Price />
              <div
                class="component__margin-left edit-order__order_label">
                {{text.max_price[language]}}:
              </div>
              <div class="component__margin-left edit-order__order_value">
                {{order.max_price ? order.max_price : text.not_set[language]}}
              </div>
            </div>
            <div class="block block__align-center component__margin-bottom">
              <Parts />
              <div
                class="component__margin-left edit-order__order_label">
                {{text.parts[language]}}
              </div>
              <div
                class="component__margin-left edit-order__order_value">
                {{order.parts_in_order ? order.parts_in_order : text.not_set2[language]}}
                {{order.parts_in_order ? text.pcs[language] : ''}}
              </div>
            </div>
            <div class="block block__align-center component__margin-bottom">
              <Files />
              <div
                class="component__margin-left edit-order__order_label">
                {{text.files[language]}} {{text.pcs[language]}}
              </div>
              <div
                class="component__margin-left edit-order__order_value">
                {{order.total_files ? order.total_files : text.not_set2[language]}}
                {{order.total_files ? text.pcs[language] : ''}}
              </div>
            </div>
            <div class="block block__align-center component__margin-bottom">
              <Weight />
              <div
                class="component__margin-left edit-order__order_label">
                {{text.total_weight[language]}}
              </div>
              <div
                class="component__margin-left edit-order__order_value">
                {{order.total_weight ? order.total_weight : text.not_set2[language]}}
                {{order.total_weight ? text.g[language] : ''}}
              </div>
            </div>
          </div>
          <div>
            <div class="orders__order_block-title" style="margin-bottom: 10px;">
              {{text.order_info[language]}}:
            </div>
            <div class="block block__align-center component__margin-bottom">
              <div
                class="component__margin-left edit-order__order_label">
                {{text.create_date[language]}}:
              </div>
              <div class="component__margin-left edit-order__order_value">
                {{moment(order.date_creation).format('DD.MM.YYYY')}}
              </div>
            </div>
            <div class="block block__align-center component__margin-bottom">
              <div
                class="component__margin-left edit-order__order_label">
                {{text.execution[language]}}:
              </div>
              <div class="component__margin-left edit-order__order_value">
                {{text.not_set3[language]}}
              </div>
            </div>
            <div class="block block__align-center component__margin-bottom">
              <div
                class="component__margin-left edit-order__order_label">
                {{text.deadline[language]}}:
              </div>
              <div class="component__margin-left edit-order__order_value">
                {{text.not_set3[language]}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="width <= t_lg">
      <div style="width: 100%; margin-bottom: 15px; margin-top: 15px">
        <Button
          block :text="text.edit_order[language]"
          v-if="order.id_status === 11"
          @click="goTo('/edit-order/', order.uuid)"
          type="attention" />
        <Button
          block :text="text.view_order[language]"
          v-else
          @click="goTo('/edit-order/', order.uuid)"
          type="attention" />
      </div>
      <div
        style="width: 100%; margin-bottom: 15px;">
        <Button
          v-if="order.id_status === 11"
          block
          @click="checkOrder"
          :text="text.offers_on_order_automatic[language]" type="attention" />
        <Button
          v-else-if="order.id_status !== 5"
          @click="goTo('/order-offers/', order.uuid)"
          block
          :text="`${text.offers_on_order[language]} ${order.offers
                  ? '(' + order.offers + ')' : ''}`"
          type="attention" />
      </div>
      <div style="width: 100%">
        <Button
          v-if="order.id_status === 11"
          block
          :text="text.delete_order[language]"
          @click="setShowDelete(true, order)"
          type="default" />
        <Button
          v-if="order.id_status === 9 || order.id_status === 12"
          block
          @click="setShowCancel(true, order)"
          :text="text.cancel_order[language]"
          type="default" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import func from '@/utilites/functions';
import text from '@/assets/text/orders.json';
import Price from '@/assets/pictures/editOrder/price.svg';
import Parts from '@/assets/pictures/editOrder/parts.svg';
import Files from '@/assets/pictures/editOrder/files.svg';
import Weight from '@/assets/pictures/editOrder/weight.svg';
import Button from '@/components/button/Button.vue';
import Default from '@/assets/pictures/order/edit-deafult.png';

export default {
  name: 'Card',
  data() {
    return {
      moment,
      text,
      func,
    };
  },
  props: {
    order: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    width() {
      return this.$store.state.index.width;
    },
    currency() {
      return this.$store.state.index.currency;
    },
  },
  methods: {
    setError(e) {
      e.target.src = Default;
    },
    goTo(path, id = '') {
      this.func.routerPush(`${path}${id}`);
    },
    setShowDelete(val, order) {
      this.$emit('setShowDelete', {
        val,
        order,
      });
    },
    setShowCancel(val, order) {
      this.$emit('setShowCancel', {
        val,
        order,
      });
    },
    setHeight() {
      this.$emit('setHeight', {
        index: this.index,
        height: this.$el.clientHeight,
      });
    },
    checkOrder() {
      this.$emit('checkOrder', this.order);
    },
  },
  mounted() {
    this.setHeight();
    if (this.index === 0) {
      this.$emit('setTop', this.$el.getBoundingClientRect().y);
    }
  },
  components: {
    Parts,
    Price,
    Files,
    Weight,
    Button,
  },
};
</script>
