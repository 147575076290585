<template>
  <div class="carousel__wrapper">
    <img :src="images[index]" class="carousel__image" />
    <div class="carousel__container">
      <div class="carousel__item"
           v-for="index in items" v-bind:key="index"
           @mouseenter="handleMouseEnter(index)">
      </div>
    </div>
    <div class="carousel__dot_container">
      <div
        :class="`carousel__dot_item ${index === i ? 'carousel__dot_item_active' : ''}`"
        @click="handleMouseEnter(i)"
        v-for="i in items" v-bind:key="i">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Carousel',
  data() {
    return {
      index: 0,
      items: [],
    };
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'fullscreen',
      // fullscreen or mobile
    },
  },
  methods: {
    handleMouseEnter(index) {
      this.index = index;
    },
  },
  mounted() {
    this.images.forEach((item, index) => {
      this.items.push(index);
    });
  },
};
</script>
