<template>
  <div>
    <OrderComponent />
    <Companies />
    <HowWeWork />
    <Services />
    <InNumbers />
    <Media />
    <LoadTest v-if="width <= t_lg" />
  </div>
</template>

<script>
import HowWeWork from '@/pages/home/components/HowWeWork.vue';
import InNumbers from '@/pages/home/components/InNumbers.vue';
import Services from './components/Services.vue';
import Media from './components/Media.vue';
import Companies from './components/Companies.vue';

const OrderComponent = () => import('@/pages/home/components/OrderComponent.vue');
const LoadTest = () => import('@/pages/home/components/LoadTest.vue');

export default {
  name: 'Home',
  mounted() {
    if (this.$route.hash.length > 0) {
      this.$router.push('/');
    }
  },
  computed: {
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    width() {
      return this.$store.state.index.width;
    },
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    OrderComponent,
    LoadTest,
    Media,
    HowWeWork,
    InNumbers,
    Services,
    Companies,
  },
};
</script>
