<template>
  <div class="block block__center" style="margin-bottom: 90px;">
    <div class="home__container block__relative home__media_container">
      <div style="width: 100%;">
        <div class="home__title">
          {{text.services_title[language]}}
        </div>
        <div :class="width > t_lg ? 'block block__between' : ''"
             style="align-items: stretch">
          <div class="services__container">
            <div class="services__card">
              <div>
                <img
                  class="services__image component__margin-bottom"
                  src="@/assets/pictures/services/Frame 1269.png" />
                <div class="component__margin-bottom">
                  <div class="services__title">
                    {{text.services_title_1[language]}}
                  </div>
                </div>
                <div class="news__card_text component__margin-bottom">
                  {{text.services_text_1[language]}}
                </div>
              </div>
              <a href="mailto:info@i5.solutions" >
                <Button
                  :text="text.services_button[language]"
                  type="primary"
                  block />
              </a>
            </div>
          </div>
          <div class="services__container">
            <div class="services__card">
              <div>
                <img
                  class="services__image component__margin-bottom"
                  src="@/assets/pictures/services/Frame 1269 (1).png" />
                <div class="component__margin-bottom">
                  <div class="services__title">
                    {{text.services_title_2[language]}}
                  </div>
                </div>
                <div class="news__card_text component__margin-bottom">
                  {{text.services_text_2[language]}}
                </div>
              </div>
              <a href="mailto:info@i5.solutions" >
                <Button
                  :text="text.services_button[language]"
                  type="primary"
                  block />
              </a>
            </div>
          </div>
          <div class="services__container">
            <div class="services__card">
              <div>
                <img
                  class="services__image component__margin-bottom"
                  src="@/assets/pictures/services/Frame 1269 (3).png" />
                <div class="component__margin-bottom">
                  <div class="services__title">
                    {{text.services_title_3[language]}}
                  </div>
                </div>
                <div class="news__card_text component__margin-bottom">
                  {{text.services_text_3[language]}}
                </div>
              </div>
              <a href="mailto:info@i5.solutions" >
                <Button
                  :text="text.services_button[language]"
                  type="primary"
                  block />
              </a>
            </div>
          </div>
          <div class="services__container">
            <div class="services__card">
              <div>
                <img
                  class="services__image component__margin-bottom"
                  src="@/assets/pictures/services/Frame 1269 (4).png" />
                <div class="component__margin-bottom">
                  <div class="services__title">
                    {{text.services_title_4[language]}}
                  </div>
                </div>
                <div class="news__card_text component__margin-bottom">
                  {{text.services_text_4[language]}}
                </div>
              </div>
              <a href="mailto:info@i5.solutions" >
                <Button
                  :text="text.services_button[language]"
                  type="primary"
                  block />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from '@/assets/text/home.json';
import Button from '@/components/button/Button.vue';

export default {
  name: 'Services',
  data() {
    return {
      text,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    width() {
      return this.$store.state.index.width;
    },
  },
  components: {
    Button,
  },
};
</script>
