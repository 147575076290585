import { query } from '@/utilites/axios';

const state = () => ({
  statusAmountList: [],
  activeId: localStorage.getItem('activeStatusId')
    ? +localStorage.getItem('activeStatusId') : 11,
  statuses: {
    draft: null,
    check: null,
    application: null,
    request: null,
    awaitingPayment: null,
    inWork: null,
    delivery: null,
    canceled: null,
    finished: null,
  },
  statusNames: {
    11: 'draft',
    12: 'check',
    9: 'application',
    4: 'in-work',
    5: 'canceled',
    6: 'finished',
    8: 'request',
    3: 'awaiting-payment',
    7: 'delivery',
  },
});

const mutations = {
  setStatusAmountList(state, data) {
    state.statusAmountList = data;
  },
  setStatus(state, data) {
    state.statuses[data.name] = data.value;
  },
  setActiveId(state, id) {
    state.activeId = id;
  },
};

const getters = {
  getStatusIdByName: (state) => (name) => {
    const statusName = state.statusNames;
    return parseInt(Object.keys(statusName)
      .find((key) => statusName[key] === name), 10);
  },
};

const actions = {
  changeActiveStatus({ commit }, id) {
    localStorage.setItem('activeStatusId', id);
    commit('setActiveId', id);
  },
  getOrderStatuses({ commit, state }) {
    return new Promise((resolve, reject) => {
      query('post', 'getCurrentCompaniesOrdersStatuses', {}, {
        token: localStorage.getItem('token'),
      }).then((res) => {
        for (const status of res) {
          if (status.id === 8 || status.id === 3 || status.id === 7) {
            if (status.count) {
              if (status.id === 8) {
                commit('setStatus', {
                  name: 'request',
                  value: status,
                });
              } else if (status.id === 3) {
                commit('setStatus', {
                  name: 'awaitingPayment',
                  value: status,
                });
              } else if (status.id === 7) {
                commit('setStatus', {
                  name: 'delivery',
                  value: status,
                });
              }
            }
          } else {
            if (status.id === 11) {
              commit('setStatus', {
                name: 'draft',
                value: status,
              });
            } else if (status.id === 9) {
              commit('setStatus', {
                name: 'application',
                value: status,
              });
            } else if (status.id === 4) {
              commit('setStatus', {
                name: 'inWork',
                value: status,
              });
            } else if (status.id === 5) {
              commit('setStatus', {
                name: 'canceled',
                value: status,
              });
            } else if (status.id === 6) {
              commit('setStatus', {
                name: 'finished',
                value: status,
              });
            }
          }
        }
        const result = [];
        for (const item of Object.keys(state.statuses)) {
          if (state.statuses[item]) {
            result.push({
              ...state.statuses[item],
              ...{
                path: state.statusNames[state.statuses[item].id],
              },
            });
          }
        }
        commit('setStatusAmountList', result);
        resolve();
      }).catch(() => {
        reject();
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
