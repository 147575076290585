<template>
  <div class="filters__container">
    <div
      v-for="(filter, index) in filters"
      v-bind:key="`filter_${index}`">
      <div v-if="filter && filter.type && filter.type === 'find'">
        <div style="margin-bottom: 15px;">
          <div class="filters__title">
            {{text.search[language]}}
          </div>
          <Input
            search
            @change="resetTimer(find, $event)" />
        </div>
      </div>
      <div v-if="filter && filter.type && filter.column && filter.type === 'checkbox_list'">
        <div style="margin-bottom: 15px;">
          <div
            class="filters__title block block__between"
            :style="`margin-bottom: 15px; ${filter.values.length > 5 ? 'cursor: pointer;' : ''}`"
            @click="setCbOpen(filter.column, filter.values.length)"
            v-if="filter.name">
            <div>{{filter.name}}</div>
            <div
              v-if="filter.values.length > 5"
              :class="`component__margin-left ${cb_opened && filter.column
              && cb_opened[filter.column]
              ? 'filters__arrow_open' : 'filters__arrow'}`">
              <ArrowLogo/>
            </div>
          </div>
          <div v-if="filter.values && filter.column">
            <div
              v-for="(item, i) in setCBValues(filter.values, cb_opened[filter.column])"
              v-bind:key="`cbl_${i}`">
              <div
                @click="setCheckedCb(item.value, filter.column)"
                class="block block__between block__align-center"
                style="margin-bottom: 10px; cursor: pointer;">
                <div>
                  <span>
                    {{item.name}} <b>({{func.getNumberWithSpaces(item.count)}})</b>
                  </span>
                </div>
                <div class="component__margin-left">
                  <CheckBoxI5
                    disable-handle-click
                    :value="cb_checked && cb_checked[filter.column]
                    && cb_checked[filter.column][item.value]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="margin-bottom: 15px;"
        v-if="filter && filter.type && filter.column && filter.type === 'segment'">
        <div class="filters__title" style="margin-bottom: 10px;">
          {{filter.name}}
        </div>
        <div class="block block__align-center block__between" v-if="filter.values">
          <div>
            {{segments_min_max[filter.column][0]}}
          </div>
          <div>
            {{segments_min_max[filter.column][1]}}
          </div>
        </div>
        <a-slider
          v-if="segments_values
          && segments_values[filter.column] && filter.values"
          range
          :min="segments_min_max[filter.column][0]"
          :max="segments_min_max[filter.column][1]"
          :step="filter.tolerance"
          :value="segments_values[filter.column]"
          style="margin-bottom: 15px;"
          @change="changeSegment($event, filter.column)"
          @afterChange="setSegment($event, filter.column)"
        />
        <div class="block block__align-center block__between" v-if="filter.values">
          <Input
            type="number"
            :min="filter.values[0]"
            :max="filter.values[1]"
            :default-value="segments_values[filter.column][0]"
            :max-width="70"
            @change="resetTimer(setSegmentByInput, $event, filter.column, 'left')"
            :margin-bottom="0"/>
          <Input
            type="number"
            :min="filter.values[0]"
            :max="filter.values[1]"
            :default-value="segments_values[filter.column][1]"
            :max-width="70"
            @change="resetTimer(setSegmentByInput, $event, filter.column, 'right')"
            :margin-bottom="0"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/inputV2/Input.vue';
import text from '@/assets/text/filters.json';
import CheckBoxI5 from '@/components/checkbox/CheckBoxI5.vue';
import ArrowLogo from '@/assets/pictures/part/ArrowDown.svg';
import func from '@/utilites/functions';

export default {
  name: 'TableFilter',
  data() {
    return {
      text,
      func,
      timer: null,
      cb_opened: {},
      cb_checked: {},
      segments_values: {},
      segments_min_max: {},
      resultFilter: {},
      filterNames: [],
    };
  },
  props: {
    filters: {
      type: [Object, Array],
    },
  },
  watch: {
    filters: {
      handler: function (values) {
        if (values && Array.isArray(values)) {
          const filterNames = [];
          for (const i of values) {
            if (i && i.type && i.type === 'checkbox_list') {
              if (this.cb_opened && !this.cb_opened[i.column]) {
                if (!this.cb_opened[i.column]) {
                  this.cb_opened[i.column] = false;
                }
                if (!this.cb_checked[i.column]) {
                  this.cb_checked[i.column] = {};
                }
              }
              filterNames.push(i.column);
            } else if (i && i.type && i.type === 'segment') {
              if (!this.segments_values[i.column]) {
                this.segments_values[i.column] = [];
                this.segments_values[i.column][0] = i.values[0];
                this.segments_values[i.column][1] = i.values[1];
              }
              if (!this.segments_min_max[i.column]) {
                this.segments_min_max[i.column] = [];
                this.segments_min_max[i.column][0] = i.values[0];
                this.segments_min_max[i.column][1] = i.values[1];
              }
              filterNames.push(i.column);
            } else if (i && i.type && i.type === 'find') {
              filterNames.push(i.type);
            }
          }
          let filterIsDeleted = false;
          for (const filter of this.filterNames) {
            const index = filterNames.findIndex((el) => el === filter);
            if (index < 0 && this.resultFilter[filter]) {
              delete this.resultFilter[filter];
              filterIsDeleted = true;
            }
          }
          if (filterIsDeleted) {
            this.$emit('setFilter', this.resultFilter);
          }
          this.filterNames = filterNames;
        }
      },
      immediate: true,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    startTimer(callback, ...args) {
      this.timer = setTimeout(() => {
        if (typeof callback === 'function') {
          callback(...args);
        }
      }, 600);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer(callback, ...args) {
      this.stopTimer();
      this.startTimer(callback, ...args);
    },
    find(value) {
      this.resultFilter.find = value;
      this.$emit('setFilter', this.resultFilter);
    },
    setCBValues(values, isOpen) {
      let result = [];
      if (!isOpen) {
        result = values.filter((el, i) => i < 5);
      } else {
        result = values;
      }
      return result;
    },
    changeSegment(value, column) {
      this.segments_values[column] = value;
    },
    setSegment(value, column) {
      this.resultFilter[column] = value;
      const segmentsValues = { ...this.segments_values };
      segmentsValues[column][0] = value[0];
      segmentsValues[column][1] = value[1];
      this.segments_values = segmentsValues;
      this.$emit('setFilter', this.resultFilter);
    },
    setSegmentByInput(value, column, position) {
      const segmentsValues = { ...this.segments_values };
      if (position === 'left') {
        this.segments_values[column][0] = +value;
        this.resultFilter[column] = [+value, segmentsValues[column][0]];
      } else if (position === 'right') {
        this.segments_values[column][1] = +value;
        this.resultFilter[column] = [segmentsValues[column][0], +value];
      }
      this.segments_values = segmentsValues;
      this.$emit('setFilter', this.resultFilter);
    },
    setCbOpen(name, length) {
      if (length > 5) {
        const cbOpened = { ...this.cb_opened };
        cbOpened[name] = !cbOpened[name];
        this.cb_opened = cbOpened;
      }
    },
    setCheckedCb(value, column) {
      const cbChecked = { ...this.cb_checked };
      cbChecked[column][value] = !cbChecked[column][value];
      if (!this.resultFilter[column]) {
        this.resultFilter[column] = [];
      }
      const index = this.resultFilter[column].findIndex((el) => el === value);
      if (cbChecked[column][value] && index < 0) {
        this.resultFilter[column].push(value);
      } else if (!cbChecked[column][value] && index >= 0) {
        this.resultFilter[column].splice(index, 1);
      }
      this.$emit('setFilter', this.resultFilter);
      this.cb_checked = cbChecked;
    },
  },
  components: {
    Input,
    CheckBoxI5,
    ArrowLogo,
  },
};
</script>
