<template>
  <div class="block block__center">
    <ModalForm :show="showSuccessModal" :title="{
      ru: 'Номер заказа',
      en: 'Order number',
    }" :okText="{
      ru: 'Вернуться к выбору предприятий',
      en: 'Back to the choice of enterprises',
    }" @cancel="closeSuccessModal" :showCancel="false">
      <template v-slot:body>
        <div>{{orderNumber}}</div>
      </template>
    </ModalForm>
    <div class="block block__start block__align-center block__column basket__container">
      <a-form :form="form"  @submit="endOrder">
        <div v-if="order">
          <div class="block block__center basket__card_container">
            <div>
              <Card :item="order" :hide-basket="true" />
            </div>
          </div>
        </div>
        <div class="block block__center block__align-center" style="max-width: 900px">
          <div class="basket__card_block">
            <div class="basket__card_header component__margin">
              {{ basket.order_detail[language] }}
            </div>
            <div class="block__flex block__between component__margin">
              <div>{{ basket.order_status[language] }}</div>
              <div>{{ basket.draft[language] }}</div>
            </div>
            <div class="block__flex block__between component__margin">
              <div>{{ basket.material_part[language] }}</div>
              <div>{{ `${part.part.material_name} (${part.part.material_full_name})` }}</div>
            </div>
            <div class="block__flex block__between component__margin">
              <div>{{ basket.weight_part[language] }}</div>
              <div>{{`${order.weight}${basket.weight_unit[language]}`}}</div>
            </div>
            <div class="block__flex block__between component__margin">
              <div>{{ basket.size_part[language] }}</div>
              <div>{{`${order.size}`}}</div>
            </div>
            <div class="block__flex block__between component__margin">
              <div>{{ basket.scale_part[language] }}</div>
              <div>{{ `${part.part.scale}%` }}</div>
            </div>
          </div>
          <div class="basket__card_img_container">
            <img class="basket__card_img"
                 :src="`https://lk.i5.solutions/files/img/${timeStamp}/${hash}/${fileName}.png`"/>
          </div>
        </div>
        <div class="block block__center block__align-start" style="max-width: 900px">
          <div class="basket__card_block">
            <div class="basket__card_header component__margin">
              {{ basket.manuf_params[language] }}
            </div>
            <div class="block__flex block__between component__margin">
              <div>{{ basket.technology[language] }}</div>
              <div>{{ order.technology }}</div>
            </div>
            <div class="block__flex block__between component__margin">
              <div>{{ basket.method[language] }}</div>
              <div>{{ order.method }}</div>
            </div>
            <div class="block__flex block__between component__margin"
                 v-if="order.id_technology === 1 && part.printing">
              <div>{{ basket.density[language] }}</div>
              <div>{{ `${part.printing.density.value}%` }}</div>
            </div>
            <div class="block__flex block__between component__margin"
                 v-if="order.id_technology === 1 && part.printing">
              <div>{{ basket.wall[language] }}</div>
              <div>{{ `${part.printing.wall.value}мм.` }}</div>
            </div>
            <div class="block__flex block__between component__margin"
                 v-if="order.id_technology === 1 && part.printing">
              <div>{{ basket.layer[language] }}</div>
              <div>{{ `${part.printing.layer.valueMin}${basket.size_unit[language]} - ${
                part.printing.layer.valueMax
              }${basket.size_unit[language]}` }}</div>
            </div>
            <div class="basket__card_header component__margin">
              {{ basket.customer_data[language] }}
            </div>
            <div class="block__flex block__between component__margin">
              <div>{{ basket.email[language] }}</div>
              <div>{{ user.email }}</div>
            </div>
            <div class="block__flex block__between component__margin">
              <div class="basket__card_phone">
                <div class="basket__card_star">*</div>
                <div class="component__margin-left">{{ basket.phone[language] }}</div>
              </div>
              <a-form-item disabled>
                <a-input
                  v-decorator="['phone', {
                    rules: [{ required: true, message: basket.filed_require[language] }]
                  }]"
                />
              </a-form-item>
            </div>
          </div>
          <div class="basket__card_block">
            <div class="basket__card_header component__margin">
              {{ basket.terms_cost[language] }}
            </div>
            <div class="block__flex block__between component__margin">
              <div>{{ basket.order_date[language] }}</div>
              <div>{{ order.date }}</div>
            </div>
            <div class="block__flex block__between component__margin">
              <div>{{ basket.price_for_one[language] }}</div>
              <div>{{func.getMoney(String(order.priceForOne), currency, language)}}</div>
            </div>
            <div class="block__flex block__between component__margin">
              <div>{{ basket.amount[language] }}</div>
              <div>{{ `${order.amount}${basket.amount_unit[language]}` }}</div>
            </div>
            <div class="basket__card_line"></div>
            <div class="block__flex block__between component__margin">
              <div>{{ basket.total[language] }}</div>
              <div>{{func.getMoney(String(order.price), currency, language)}}</div>
            </div>
            <div class="block__flex block__between component__margin">
              <div>{{ basket.comments[language] }}</div>
            </div>
            <div class="block__flex block__between component__margin">
              <a-form-item style="width: 100%">
                <a-textarea
                  v-decorator="['comment']"
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                />
              </a-form-item>
            </div>
            <div class="block__flex block__right component__margin">
              <a-button @click="closeOrder">
                {{ basket.cancel[language] }}
              </a-button>
              <a-button type="primary" class="component__margin-left" html-type="submit">
                {{ basket.set_order[language] }}
              </a-button>
            </div>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
// import packageJson from '../../../package.json';
import func from '@/utilites/functions/index';
import basket from '@/assets/text/basket.json';
import { query } from '@/utilites/axios';

const Card = () => import('@/pages/part/components/PriceCard.vue');
const ModalForm = () => import('@/components/modalForm/ModalForm.vue');

export default {
  name: 'Basket',
  data() {
    return {
      // packageJson,
      basket,
      func,
      form: this.$form.createForm(this, { name: 'basket' }),
      orderNumber: null,
      showSuccessModal: false,
    };
  },
  methods: {
    closeOrder() {
      this.$router.push({
        path: 'part',
        query:
          {
            h: this.hash,
            t: this.timeStamp,
            n: this.fileName,
            e: this.ext,
          },
      });
    },
    closeSuccessModal() {
      this.showSuccessModal = false;
      this.closeOrder();
    },
    getOrderData() {
      query('post', 'getPricesV2', {
        order: true,
        lang: this.language,
        currency: this.currency,
        time_stamp: this.timeStamp,
        file_name: this.fileName,
        hash: this.hash,
        amount: this.order.amount,
        id_method: this.order.id_method,
        id_manuf: this.order.id_manuf,
        printing: {
          density: 50,
          wall: 0.6,
          layerMin: 0.3,
          layerMax: 0.5,
        },
      }, {
        token: localStorage.getItem('token'),
        lang: this.language,
      }).then((item) => {
        this.$store.commit('basket/setOrder', item.manuf);
      });
    },
    endOrder(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          query('post', 'getPricesV2',
            {
              sendNewOrder: true,
              time_stamp: this.timeStamp,
              file_name: this.fileName,
              hash: this.hash,
              amount: this.order.amount,
              id_method: this.order.id_method,
              id_manuf: this.order.id_manuf,
              printing: {
                density: 50,
                wall: 0.6,
                layerMin: 0.3,
                layerMax: 0.5,
              },
              comment: values.comment || '',
              phone: values.phone,
            },
            {
              token: localStorage.getItem('token'),
              lang: this.language,
            }).then((item) => {
            this.orderNumber = item;
            this.showSuccessModal = true;
          });
        }
      });
    },
  },
  computed: {
    hash() {
      return this.$store.state.part.hash;
    },
    ext() {
      return this.$store.state.part.ext;
    },
    timeStamp() {
      return this.$store.state.part.timeStamp;
    },
    fileName() {
      return this.$store.state.part.fileName;
    },
    order() {
      return this.$store.state.basket.order;
    },
    user() {
      return this.$store.state.user.user;
    },
    part() {
      return this.$store.state.part.partData;
    },
    language() {
      return this.$store.state.index.language;
    },
    currency() {
      const userParameters = this.$store.state.part.userParameters;
      if (userParameters && userParameters.currency) {
        return userParameters.currency;
      } else {
        return 'RUB';
      }
    },
  },
  mounted() {
    this.$store.watch((state) => state.index.language, () => {
      this.getOrderData();
    });
  },
  components: {
    Card,
    ModalForm,
  },
};
</script>
