import Vue from 'vue';
import VueRouter from 'vue-router';
import pages from '@/assets/pages.json';
import Home from '@/pages/home/Home.vue';
import Part from '@/pages/part/Part.vue';
import Order from '@/pages/order/Order.vue';
import TechnicalRequirements from '@/pages/technical_requirements/TechnicalRequirements.vue';
import TechnicalRequirementsV2 from '@/pages/technicalRequirementsV2/TechnicalRequirements.vue';
import Basket from '@/pages/basket/Basket.vue';
import BasketNew from '@/pages/basket/BasketNew.vue';
import PdfViewer from '@/pages/pdfViewer/PdfViewer.vue';
import EditOrder from '@/pages/editOrder/EditOrder.vue';
import Kit from '@/pages/kit/Kit.vue';
import News from '@/pages/news/NewsPage.vue';
import Orders from '@/pages/orders/Orders.vue';
import OrderOffers from '@/pages/orderOffers/OrderOffers.vue';
import TestOrderOffers from '@/pages/testOrderOffers/TestOrderOffers.vue';
import Company from '@/pages/company/Company.vue';
import Page404 from '@/pages/404/Page404.vue';
import RegistrationSuccess from '@/pages/registrationSuccess/RegistrationSuccess.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: pages.home,
  },
  {
    path: '/registrationSuccess',
    name: 'RegistrationSuccess',
    component: RegistrationSuccess,
    meta: pages.registration_success,
  },
  {
    path: '/company/:id',
    name: 'Company',
    component: Company,
    meta: pages.company,
  },
  {
    path: '/document/:id',
    name: 'Document',
    component: PdfViewer,
    meta: pages.document,
  },
  {
    path: '/news/:id',
    name: 'News',
    component: News,
    meta: pages.news,
  },
  {
    path: '/order',
    name: 'Order',
    component: Order,
    meta: pages.order,
  },
  {
    path: '/part',
    name: 'Part',
    component: Part,
    props: (route) => ({
      hash: route.query.h,
      timeStamp: route.query.t,
      fileName: route.query.n,
      ext: route.query.e,
    }),
    meta: pages.part,
  },
  {
    path: '/edit-order/:id',
    name: 'EditOrder',
    component: EditOrder,
    meta: pages['edit-order'],
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: pages.orders,
  },
  {
    path: '/orders/:id',
    name: 'OrdersWithId',
    component: Orders,
    meta: pages.orders,
  },
  {
    path: '/order-offers/:id',
    name: 'OrderOffers',
    component: OrderOffers,
    meta: pages['order-offers'],
  },
  {
    path: '/test-order-offers/:id',
    name: 'TestOrderOffers',
    component: TestOrderOffers,
    meta: pages['test-order-offers'],
  },
  {
    path: '/basket',
    name: 'Basket',
    component: Basket,
    props: (route) => ({
      hash: route.query.h,
      timeStamp: route.query.t,
      fileName: route.query.n,
      ext: route.query.e,
    }),
    meta: pages.basket,
  },
  {
    path: '/basket-new',
    name: 'BasketNew',
    component: BasketNew,
    meta: pages['basket-new'],
  },
  {
    path: '/technical-requirements',
    name: 'TechnicalRequirements',
    component: TechnicalRequirements,
    props: (route) => ({
      hash: route.query.h,
      timeStamp: route.query.t,
      fileName: route.query.n,
    }),
    meta: pages['technical-requirements'],
  },
  {
    path: '/tech-requirements/:uuid',
    name: 'TechnicalRequirementsV2',
    component: TechnicalRequirementsV2,
    meta: pages['technical-requirements'],
  },
  {
    path: '/kit',
    name: 'Kit',
    component: Kit,
    meta: pages.kit,
  },
  {
    path: '/kit/:company',
    name: 'Kit-company',
    component: Kit,
    meta: pages.kit,
  },
  {
    path: '*',
    name: '404',
    component: Page404,
    meta: { layout: 'interface', access: 'all' },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
