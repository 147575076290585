import jwtDecode from 'jwt-decode';
import store from '@/store/index';
import { query, queryWithout401 } from '@/utilites/axios';

const state = () => ({
  user: null,
  showAuthorizeModal: false,
  afterAuthorizeFunction: () => {},
  access: null,
  closableModal: false,
});

const mutations = {
  setUser(state, data) {
    state.user = data;
  },
  setShowAuthorizeModal(state, data) {
    state.showAuthorizeModal = data;
  },
  setAfterAuthorize(state, data) {
    state.afterAuthorizeFunction = data;
  },
  removeUser(state) {
    state.user = null;
  },
  setAccess(state, data) {
    state.access = data;
  },
  removeAccess(state) {
    state.access = null;
  },
  setClosableModal(state, data) {
    state.closableModal = data;
  },
};

const getters = {};

const actions = {
  async getUser({ state, commit }) {
    return new Promise((resolve, reject) => {
      query('get', 'getUserInfo', {}, {
        token: localStorage.getItem('token'),
      })
        .then((item) => {
          state.afterAuthorizeFunction();
          commit('setUser', item);
          if (item.lang) {
            store.commit('index/setLanguage', item.lang);
            localStorage.setItem('language', item.lang);
          }
          if (item.currency) {
            store.commit('index/setCurrency', item.currency);
          }
          commit('setAfterAuthorize', () => {});
          const getPayload = () => {
            const token = localStorage.getItem('token');
            if (token) {
              const decoded = jwtDecode(token);
              commit('setAccess', decoded);
            } else {
              commit('removeAccess');
            }
          };
          getPayload();
          resolve(item);
        }).catch(() => {
          commit('removeUser', null);
          commit('removeAccess');
          const secretKey = localStorage.getItem('token');
          if (secretKey) {
            localStorage.removeItem('token');
          }
          reject();
        });
    });
  },
  async getUserWithout401({ state, commit }) {
    return new Promise((resolve, reject) => {
      queryWithout401('get', 'getUserInfo', {}, {
        token: localStorage.getItem('token'),
      })
        .then((item) => {
          state.afterAuthorizeFunction();
          commit('setUser', item);
          const getPayload = () => {
            const token = localStorage.getItem('token');
            if (token) {
              const decoded = jwtDecode(token);
              commit('setAccess', decoded);
            } else {
              commit('removeAccess');
            }
          };
          getPayload();
          resolve(item);
        }).catch(() => {
          commit('removeUser', null);
          commit('removeAccess');
          const secretKey = localStorage.getItem('token');
          if (secretKey) {
            localStorage.removeItem('token');
          }
          reject();
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
