<template>
  <div>
    <ModalForm :show="authModal" :title="setModalTitle()" @cancel="closeAuthModal"
               :ok="setModalOk()" @setForm="setModalForm($event)"
               :hideButton="true">
      <template v-slot:body>
        <div v-if="modalType === 'sign-in'">
          <div v-if="error424" class="header__modal_error424">
            {{header.error_424[language]}}
          </div>
          <a-form-item :label="header.email_label[language]">
            <a-input
              @change="clearL"
              v-decorator="['l',
              { rules: [
                { required: true, message: header.empty_error[language]},
                { validator: check404 },
              ]
              }
              ]"
              placeholder="example@domain.com"
            />
          </a-form-item>
          <a-form-item :label="header.pass_label[language]">
            <a-input
              @change="clearP"
              v-decorator="['p',
            { rules: [
              { required: true, message: header.empty_error[language] },
              { validator: check404 },
              ] }]"
              type="password"
              :placeholder="header.pass_label[language]"
            />
          </a-form-item>
          <div class="header__modal_margin">
            <I5Button
              type="primary"
              :text="header.sign_in[language]"
              fontSize="15px"
              html-type="submit"
              minHeight="32px"/>
          </div>
          <div class="header__modal_margin block block__center">
            <span class="header__modal_text">{{ header.have_account[language] }}</span>
            <span class="header__modal_sign-up
          header__modal_pointer
          component__margin-left" @click="setModalType('sign-up')">
            {{ header.sign_up[language] }}
          </span>
          </div>
          <div class="block block__center
        header__modal_pointer
        header__modal_sign-up" @click="setModalType('recovery')">
            {{ header.forgot_password[language] }}
          </div>
        </div>
        <div v-if="modalType === 'sign-up'">
          <a-form-item :label="header.email_label[language]" has-feedback>
            <a-input
              @change="clearEmail"
              v-decorator="['email',
              { rules: [
                { required: true, message: header.empty_error[language]},
                { type: 'email', message: header.error_email_format[language] },
                { validator: check409 },
              ]
              }
              ]"
              placeholder="example@domain.com"
            />
          </a-form-item>
          <a-form-item :label="header.pass_label[language]" has-feedback>
            <a-input
              @blur="handleConfirmBlur"
              v-decorator="['password',
            { rules: [
              { required: true, message: header.empty_error[language] },
              { validator: validateToNextPassword, },
              { min: 6, message: header.error_pass_min[language] },
              { max: 200, message: header.error_pass_max[language] },
              {
                pattern: RegExp(/^(?=.*?[a-zA-Z])(?=.*?[0-9])/),
                message: header.error_pass_contain[language],
              }
              ] }]"
              type="password"
              :placeholder="header.pass_label[language]"
            />
          </a-form-item>
          <a-form-item :label="header.pass_confirm[language]" has-feedback>
            <a-input
              v-decorator="['confirm',
            { rules: [
              { required: true, message: header.empty_error[language] },
              { validator: compareToFirstPassword, },
              ] }]"
              type="password"
              :placeholder="header.pass_confirm[language]"
            />
          </a-form-item>
          <div class="header__modal_margin">
            <I5Button
              type="primary"
              :text="header.sign_up_button[language]"
              fontSize="15px"
              html-type="submit"
              minHeight="32px"/>
          </div>
          <div class="header__modal_margin block block__center">
            <span class="header__modal_text">{{ header.already_have_account[language] }}</span>
            <span class="header__modal_sign-up
          header__modal_pointer
          component__margin-left" @click="setModalType('sign-in')">
            {{ header.sign_in[language] }}
          </span>
          </div>
        </div>
        <div v-if="modalType === 'success'">
          <div class="header__modal_success block block__center header__modal_margin">
            {{ header.modal_success_text[language] }}
          </div>
          <div class="block block__center header__modal_margin">
            {{ header.success_recovery[language] }}
          </div>
          <div class="header__modal_margin">
            <I5Button
              type="primary"
              :text="header.sign_in[language]"
              fontSize="15px"
              @click="setModalType('sign-in')"
              minHeight="32px"/>
          </div>
        </div>
        <div v-if="modalType === 'recovery'">
          <a-form-item :label="header.email_label[language]">
            <a-input
              @change="clearRecovery"
              v-decorator="['email_recovery',
              { rules: [
                { required: true, message: header.empty_error[language]},
                { validator: check404Recovery },
              ]
              }
              ]"
              placeholder="example@domain.com"
            />
          </a-form-item>
          <div class="header__modal_margin">
            <I5Button
              type="primary"
              :text="header.modal_get_recovery[language]"
              fontSize="15px"
              html-type="submit"
              minHeight="32px"/>
          </div>
          <div class="header__modal_margin block block__center">
            <span class="header__modal_sign-up
          header__modal_pointer
          component__margin-left" @click="setModalType('sign-in')">
            {{ header.sign_in[language] }}
          </span>
          </div>
        </div>
        <div v-if="modalType === 'recovery-success'">
          <div class="header__modal_success block block__center header__modal_margin">
            {{ header.success_recovery[language] }}
          </div>
          <div class="header__modal_margin">
            <I5Button
              type="primary"
              :text="header.sign_in[language]"
              fontSize="15px"
              @click="setModalType('sign-in')"
              minHeight="32px"/>
          </div>
        </div>
      </template>
    </ModalForm>
  </div>
</template>

<script>
import { sha256 } from 'js-sha256';
import { query } from '@/utilites/axios';
import header from '@/assets/text/header.json';

const I5Button = () => import('@/components/i5Button/I5Button.vue');
const ModalForm = () => import('@/components/modalForm/ModalForm.vue');

export default {
  name: 'AuthModal',
  data() {
    return {
      header,
      unsubscribe: () => {},
      authModal: false,
      error404: false,
      error404Recovery: false,
      error401: false,
      error409: false,
      error424: false,
      form: null,
      confirmDirty: false,
      modalType: 'sign-in',
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: function (val) {
      this.authModal = val;
    },
  },
  methods: {
    setModalOk() {
      if (this.modalType === 'sign-in') {
        return this.auth;
      } else if (this.modalType === 'sign-up') {
        return this.reg;
      } else if (this.modalType === 'recovery') {
        return this.recovery;
      } else {
        return () => {};
      }
    },
    setModalForm(form) {
      this.form = form;
    },
    recovery(values, form) {
      return new Promise((resolve, reject) => {
        query('post', 'passwordRecoveryByMail', {
          mail: values.email_recovery.toLowerCase(),
        }, {})
          .then(() => {
            this.modalType = 'recovery-success';
            resolve({
              notClose: true,
            });
          }).catch((err) => {
            if (err.response) {
              const status = err.response.status;
              if (status === 404) {
                this.error404Recovery = true;
                form.validateFields(['email_recovery'], { force: true });
              }
            }
            reject();
          });
      });
    },
    reg(values, form) {
      return new Promise((resolve, reject) => {
        query('put', 'addUser', {
          email: values.email.toLowerCase(),
          pass: sha256(values.password),
        }, {})
          .then(() => {
            this.modalType = 'success';
            resolve({
              notClose: true,
            });
          }).catch((err) => {
            if (err.response) {
              const status = err.response.status;
              if (status === 409) {
                this.error409 = true;
                form.validateFields(['email'], { force: true });
              }
            }
            reject();
          });
      });
    },
    auth(values, form) {
      return new Promise((resolve, reject) => {
        query('post', 'auth', {
          l: values.l.toLowerCase(),
          p: sha256(values.p),
        }, {})
          .then((item) => {
            localStorage.setItem('token', item.token);
            this.$emit('ok', item.token);
            this.$store.dispatch('user/getUser').then(() => {
              if (this.$store.state.user.afterAuthorize) {
                this.$store.state.user.afterAuthorize();
              }
            });
            resolve({
              notClose: false,
            });
          }).catch((err) => {
            if (err.response) {
              const status = err.response.status;
              if (status === 401) {
                this.error401 = true;
                form.validateFields(['p', 'l'], { force: true }).then(() => {
                  this.error401 = false;
                });
              } else if (status === 404) {
                this.error404 = true;
                form.validateFields(['p', 'l'], { force: true }).then(() => {
                  this.error404 = false;
                });
              } else if (status === 424) {
                this.error424 = true;
                setTimeout(() => {
                  this.error424 = false;
                }, 30000);
              }
            }
            reject();
          });
      });
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    showAuthModal() {
      this.authModal = true;
    },
    check409(rule, value, callback) {
      if (!this.error409) {
        callback();
        return;
      }
      callback(this.header['409_error'][this.language]);
    },
    checkPass(rule, value, callback) {
      if (!this.error401) {
        callback();
        return;
      }
      callback(this.header.pass_error[this.language]);
    },
    check404(rule, value, callback) {
      if (!this.error404) {
        callback();
        return;
      }
      callback(this.header['404_error'][this.language]);
    },
    check404Recovery(rule, value, callback) {
      if (!this.error404Recovery) {
        callback();
        return;
      }
      callback(this.header['404_error'][this.language]);
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true });
      }
      callback();
    },
    setModalTitle() {
      if (this.modalType === 'sign-in') {
        return this.header.sign_in;
      } else if (this.modalType === 'sign-up') {
        return this.header.sign_up_title;
      } else if (this.modalType === 'success') {
        return this.header.modal_success;
      } else if (this.modalType === 'recovery') {
        return this.header.modal_recovery_title;
      } else if (this.modalType === 'recovery-success') {
        return this.header.modal_recovery_title;
      } else {
        return {
          ru: '',
          en: '',
        };
      }
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue('password')) {
        callback(this.header.error_pass_match[this.language]);
      } else {
        callback();
      }
    },
    setModalType(type) {
      this.modalType = type;
    },
    clearL() {
      if (this.error404) {
        this.error404 = false;
        this.form.validateFields(['p', 'l'], { force: true });
      }
      if (this.error424) {
        this.error424 = false;
      }
    },
    clearP() {
      if (this.error401) {
        this.error401 = false;
        this.form.validateFields(['p', 'l'], { force: true });
      }
    },
    clearEmail() {
      this.error409 = false;
      this.form.validateFields(['email'], { force: true });
    },
    clearRecovery() {
      this.error404Recovery = false;
      this.form.validateFields(['email_recovery'], { force: true });
    },
    closeAuthModal() {
      this.authModal = false;
      this.modalType = 'sign-in';
      this.$emit('close');
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'user/setShowAuthorizeModal') {
        const item = mutation.payload;
        if (item) {
          this.showAuthModal();
          this.$store.commit('user/setShowAuthorizeModal', false);
        }
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  components: {
    I5Button,
    ModalForm,
  },
};
</script>
