<template>
  <div class="kit__wrapper">
    <AuthModal :show="isShowAuth" @close="() => {
      this.isShowAuth = false;
    }"/>
    <div class="block block__column" style="height: 100%;">
      <div style="margin-bottom: 20px" class="block block__between block__align-center">
        <div class="block__flex block__align-center">
          <Logo v-if="!company" />
          <SkLogo v-if="company === 'skrnd'" />
          <div
            v-if="company === 'skrnd'"
            class="kit__name component__margin-left">RnD Market</div>
          <div v-if="company === 'innokam'">
            <img class="kit__logo" src="@/assets/pictures/kit/innocam.png" />
          </div>
        </div>
        <div class="block__flex block__align-center">
          <Dropdown
            v-if="width > t_lg"
            class="component__margin-right"
            active="activeLanguage">
            <template slot="body">
              <div
                style="cursor: pointer;"
                class="block__flex block__align-center">
                <LanguageLogo class="component__margin-right" />
                <div class="kit__menu" v-if="language === 'ru'">
                  Русский
                </div>
                <div class="kit__menu" v-if="language === 'en'">
                  English
                </div>
                <Arrow class="component__margin-left" />
              </div>
            </template>
            <template slot="dropdown">
              <div @click="e => e.preventDefault()">
                <div
                  style="cursor: pointer;"
                  @click="setLanguage('ru')"
                  class="kit__menu" v-if="language !== 'ru'">
                  Русский
                </div>
                <div
                  style="cursor: pointer;"
                  @click="setLanguage('en')"
                  class="kit__menu" v-if="language !== 'en'">
                  English
                </div>
              </div>
            </template>
          </Dropdown>
          <Dropdown
            v-if="width > t_lg"
            class="component__margin-left"
            :active="activeUser">
            <template slot="body">
              <div
                style="cursor: pointer;"
                class="block__flex block__align-center">
                <UserLogo class="component__margin-right" />
                <div class="kit__menu" v-if="!user">
                  {{text.user_profile[language]}}
                </div>
                <div class="kit__menu" v-else>
                  {{user.email}}
                </div>
                <Arrow class="component__margin-left" />
              </div>
            </template>
            <template slot="dropdown">
              <div @click="e => e.preventDefault()">
                <div
                  style="cursor: pointer;"
                  @click="signIn"
                  class="kit__menu" v-if="!user">
                  {{text.sign_in[language]}}
                </div>
                <div
                  style="cursor: pointer;"
                  @click="logOut"
                  class="kit__menu" v-if="user">
                  {{text.log_out[language]}}
                </div>
              </div>
            </template>
          </Dropdown>
          <Dropdown
            v-if="width <= t_lg"
            class="component__margin-left"
            :active="activeBurger">
            <template slot="body">
              <Burger style="cursor: pointer;" />
            </template>
            <template slot="dropdown">
              <div @click="e => e.preventDefault()">
                <Dropdown
                  class="component__margin-right"
                  active="activeLanguage">
                  <template slot="body">
                    <div
                      style="cursor: pointer;"
                      class="block__flex block__align-center">
                      <LanguageLogo class="component__margin-right" />
                      <div class="kit__menu" v-if="language === 'ru'">
                        Русский
                      </div>
                      <div class="kit__menu" v-if="language === 'en'">
                        English
                      </div>
                      <Arrow class="component__margin-left" />
                    </div>
                  </template>
                  <template slot="dropdown">
                    <div @click="e => e.preventDefault()">
                      <div
                        style="cursor: pointer;"
                        @click="setLanguage('ru')"
                        class="kit__menu" v-if="language !== 'ru'">
                        Русский
                      </div>
                      <div
                        style="cursor: pointer;"
                        @click="setLanguage('en')"
                        class="kit__menu" v-if="language !== 'en'">
                        English
                      </div>
                    </div>
                  </template>
                </Dropdown>
                <Dropdown
                  class="component__margin-left"
                  :active="activeUser">
                  <template slot="body">
                    <div
                      style="cursor: pointer;"
                      class="block__flex block__align-center">
                      <UserLogo class="component__margin-right" />
                      <div class="kit__menu" v-if="!user">
                        {{text.user_profile[language]}}
                      </div>
                      <div class="kit__menu" v-else>
                        {{user.email}}
                      </div>
                      <Arrow class="component__margin-left" />
                    </div>
                  </template>
                  <template slot="dropdown">
                    <div @click="e => e.preventDefault()">
                      <div
                        style="cursor: pointer;"
                        @click="signIn"
                        class="kit__menu" v-if="!user">
                        {{text.sign_in[language]}}
                      </div>
                      <div
                        style="cursor: pointer;"
                        @click="logOut"
                        class="kit__menu" v-if="user">
                        {{text.log_out[language]}}
                      </div>
                    </div>
                  </template>
                </Dropdown>
                <div class="kit__menu" @click="setActiveBurger">
                  {{text.close[language]}}
                </div>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
      <div style="height: 100%;">
        <FileUploadI5 style="height: 100%;" />
      </div>
    </div>
  </div>
</template>

<script>
import FileUploadI5 from '@/components/fileUploadI5/FileUploadI5.vue';
import Dropdown from '@/components/dropdown/Dropdown.vue';
import AuthModal from '@/components/header/AuthModal.vue';
import SkLogo from '@/assets/pictures/kit/sk-rnd-logo.svg';
import LanguageLogo from '@/assets/pictures/kit/language.svg';
import Burger from '@/assets/pictures/kit/burger.svg';
import Arrow from '@/assets/pictures/kit/arrow.svg';
import UserLogo from '@/assets/pictures/kit/user.svg';
import Logo from '@/assets/pictures/i5/wide_logo.svg';
import text from '@/assets/text/kit.json';
import { query } from '@/utilites/axios';
import func from '@/utilites/functions';

export default {
  name: 'Kit',
  data() {
    return {
      text,
      func,
      activeLanguage: null,
      activeUser: null,
      isShowAuth: false,
      uploadPercent: null,
      activeBurger: false,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    user() {
      return this.$store.state.user.user;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    width() {
      return this.$store.state.index.width;
    },
    company() {
      return this.$route.params.company;
    },
  },
  methods: {
    setLanguage(val) {
      this.$store.commit('index/setLanguage', val);
      localStorage.setItem('language', val);
      this.activeLanguage = val;
    },
    signIn() {
      this.activeUser = 'sign_in';
      this.isShowAuth = true;
    },
    logOut() {
      this.activeUser = 'log_out';
      // this.$store.commit('user/removeUser');
      localStorage.removeItem('token');
    },
    uploadFile(formData, index, name) {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        if (token) {
          query('put', 'addFile', formData, {
            token,
          }, (e) => {
            const percent = String(Math.round((e.loaded * 100) / e.total));
            this.uploadPercent = percent;
          }).then((item) => {
            query('post', 'addNewPartV2', {
              code: '1',
              name: name,
              files: [],
            }, {
              token: localStorage.getItem('token'),
            }).then(async (result) => {
              let orderUuid = null;
              let orderPartUuid = null;
              await query('post', 'addNewOrderV2', {
                parts: [
                  result.uuid,
                ],
              }, {
                token: localStorage.getItem('token'),
              }).then((order) => {
                orderUuid = order.uuid;
                orderPartUuid = order.order_spec[0];
              });
              await query('post', 'addFilesToOrderPartV2', {
                uuid: orderPartUuid,
                files: [
                  {
                    hash: item.hash,
                    time_stamp: item.timeStamp,
                    file_name: item.fileName,
                  },
                ],
              }).then(() => {
                this.func.routerPush(`/edit-order/${orderUuid}`);
              });
            });
            resolve(item);
          }).catch((err) => {
            if (err.response.status === 401) {
              this.isShowAuth = true;
            }
            reject(err);
          });
        } else {
          this.isShowAuth = true;
          reject();
        }
      });
    },
    setActiveBurger() {
      this.activeBurger = !this.activeBurger;
    },
  },
  mounted() {
    this.activeLanguage = this.language;
  },
  components: {
    SkLogo,
    LanguageLogo,
    UserLogo,
    Dropdown,
    Arrow,
    AuthModal,
    Burger,
    Logo,
    FileUploadI5,
  },
};
</script>
