<template>
  <div class="input-i5">
    <label :for="name">{{label}}
      <span v-if="required" >*</span>
      <span v-if="subLabel" class="input-i5__sublabel">
        {{subLabel}}
      </span>
    </label>
    <textarea
      :tabindex="ti"
      :value="value"
      :id="name"
      :rows="rows"
      :name="name"
      autocomplete="off"
      :disabled="disabled === true"
      @input="handleChange"/>
  </div>
</template>

<script>
export default {
  name: 'Textarea',
  data() {
    return {
      value: '',
    };
  },
  props: {
    defaultValue: {
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    subLabel: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
    required: {
      type: Boolean,
      default: false,
    },
    ti: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    defaultValue: function (val) {
      this.value = val;
    },
  },
  methods: {
    handleChange(evt) {
      this.$emit('change', evt.target.value);
    },
  },
  mounted() {
    this.value = this.defaultValue;
  },
};
</script>
