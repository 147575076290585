<template>
  <div class="block block__center" style="margin-bottom: 90px; margin-top: 40px;">
    <div class="home__container block__relative home__media_container">
      <div :class="`${width > t_lg ? 'block block__between' : ''}`">
        <div>
          <div class="in-numbers_title">
            i5.Solutions
          </div>
          <div class="in-numbers_subtitle">
            {{text.in_numbers_subtitle[language]}}
          </div>
          <div style="margin-bottom: 30px;">
            <div class="in-numbers__number_label">{{text.in_numbers_over[language]}}</div>
            <div class="in-numbers__number_value">{{getNumberWithSpaces(10000)}}</div>
            <div class="in-numbers__number_description">
              {{text.in_numbers_models[language]}}
            </div>
            <div class="block">
              <ModelLogo />
              <ModelLogo class="component__margin-left" />
              <ModelLogo class="component__margin-left" />
              <ModelLogo class="component__margin-left" />
              <ModelLogo class="component__margin-left" />
            </div>
          </div>
          <div style="margin-bottom: 30px;">
            <div class="in-numbers__number_label">{{text.in_numbers_over[language]}}</div>
            <div class="in-numbers__number_value">{{getNumberWithSpaces(1000)}}</div>
            <div class="in-numbers__number_description">
              {{text.in_numbers_machines[language]}}
            </div>
            <div class="block">
              <MachineLogo />
              <MachineLogo class="component__margin-left" />
              <MachineLogo class="component__margin-left" />
              <MachineLogo class="component__margin-left" />
              <MachineLogo class="component__margin-left" />
            </div>
          </div>
        </div>
        <div :style="`${width > t_lg ? 'margin-left: 25px' : ''}`">
          <img
            v-if="width > t_lg"
            src="@/assets/pictures/inNumbers/in_numbers.png"
            style="height: auto; width: 100%; margin-bottom: 25px;" />
          <img
            v-if="width <= t_lg"
            src="@/assets/pictures/inNumbers/in_numbers_mobile.png"
            style="max-height: 500px; width: 100%; object-fit: scale-down; margin-bottom: 25px;" />
          <div>
            <span class="in-numbers_text">
              <span class="in-numbers_first">FERMAT WRF 160 CNC</span>
              {{text.in_numbers_fermat[language]}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from '@/assets/text/home.json';
import ModelLogo from '@/assets/pictures/inNumbers/model_icon.svg';
import MachineLogo from '@/assets/pictures/inNumbers/machine_icon.svg';
import func from '@/utilites/functions/index';

export default {
  name: 'InNumbers',
  data() {
    return {
      text,
      getNumberWithSpaces: func.getNumberWithSpaces,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    m_lg() {
      return this.$store.state.index.m_lg;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    width() {
      return this.$store.state.index.width;
    },
  },
  components: {
    ModelLogo,
    MachineLogo,
  },
};
</script>
