<template>
  <div>
    <div v-if="item" style="width: 100%;">
      <div class="order-offers__card_wrapper order-offers__card_wrapper_real">
        <div class="order-offers__card_header" v-if="width > t_lg">
          <div class="block__flex">
            <div>
              <img
                class="order-offers__card_logo"
                v-if="item.logo" :src="item.logo" />
            </div>
            <div style="margin-bottom: 15px;">
              <div
                @click="companyOpen(item.uuid_manufacturer)"
                class="order-offers__card_company-name">
                {{item && item.company_name ? item.company_name : ''}}
              </div>
              <div class="block block__align-center">
                <MarkerLogo class="component__margin-right" />
                <div class="order-offers__card_company-place">
                  <b>{{item && item.location ? item.location : ''}}</b>
                </div>
              </div>
              <div class="block__flex block__align-center" style="margin-top: 3px">
                <a-tooltip :placement="'bottom'" v-if="item.date_created">
                  <template slot="title">
                    {{text.year_foundation[language]}}
                  </template>
                  <div class="block__flex block__align-center component__margin-right">
                    <FoundationLogo />
                    <div class="order-offers__card_info">
                      {{item.date_created}}
                    </div>
                  </div>
                </a-tooltip>
                <a-tooltip
                  :placement="'bottom'"
                  v-if="item.staff_amount">
                  <template slot="title">
                    {{text.staff_amount[language]}}
                  </template>
                  <div class="block__flex block__align-center component__margin-right">
                    <EmployeesLogo />
                    <div class="order-offers__card_info">
                      {{item.staff_amount}}
                    </div>
                  </div>
                </a-tooltip>
              </div>
            </div>
          </div>
          <div class="block__flex block__align-center">
            <div v-if="item.proof_by_i5">
              <ApproveLogo style="margin: 0 20px;" />
            </div>
            <div>
              <div v-if="item.id_type === 1" class="order-offers__card_type-auto">
                <div>
                  {{item.type}}
                </div>
                <BrainLogo style="min-width: 31px; margin-left: 10px;" />
              </div>
              <div v-if="item.id_type === 2" class="order-offers__card_type-auto">
                <div >
                  {{item.type}}
                </div>
                <BrainLogo style="min-width: 31px; margin-left: 10px;" />
              </div>
              <div v-if="item.id_type === 3" class="order-offers__card_type-auto">
                <div>
                  {{item.type}}
                </div>
                <ManufLogo style="min-width: 31px; margin-left: 10px;" :fill="primaryColor" />
              </div>
              <div v-if="item.id_type === 4" class="order-offers__card_type-manual">
                <div>
                  {{item.type}}
                </div>
                <ManufLogo style="min-width: 31px; margin-left: 10px;" />
              </div>
            </div>
          </div>
        </div>
        <div class="order-offers__card_header" v-if="width <= t_lg">
          <div class="block block__between" style="margin-bottom: 10px;">
            <div>
              <img
                class="order-offers__card_logo"
                v-if="item.logo" :src="item.logo" />
            </div>
            <div class="block__flex block__align-center">
              <div v-if="item.proof_by_i5">
                <ApproveLogo style="margin-right: 5px"/>
              </div>
              <div>
                <div v-if="item.id_type === 1" class="order-offers__card_type-auto">
                  <div>
                    {{item.type}}
                  </div>
                  <BrainLogo style="min-width: 31px; margin-left: 10px;" />
                </div>
                <div v-if="item.id_type === 2" class="order-offers__card_type-auto">
                  <div>
                    {{item.type}}
                  </div>
                  <BrainLogo style="min-width: 31px; margin-left: 10px;" />
                </div>
                <div v-if="item.id_type === 3" class="order-offers__card_type-auto">
                  <div>
                    {{item.type}}
                  </div>
                  <ManufLogo style="min-width: 31px; margin-left: 10px;" :fill="primaryColor" />
                </div>
                <div v-if="item.id_type === 4" class="order-offers__card_type-manual">
                  <div>
                    {{item.type}}
                  </div>
                  <ManufLogo style="min-width: 31px; margin-left: 10px;" />
                </div>
              </div>
            </div>
          </div>
          <div style="margin-bottom: 15px;">
            <div class="order-offers__card_company-name">
              {{item && item.company_name ? item.company_name : ''}}
            </div>
            <div class="block block__align-center">
              <MarkerLogo class="component__margin-right" />
              <div class="order-offers__card_company-place">
                <b>{{item && item.location ? item.location : ''}}</b>
              </div>
            </div>
            <div class="block__flex block__align-center" style="margin-top: 3px">
              <a-tooltip :placement="'bottom'" v-if="item.date_created">
                <template slot="title">
                  {{text.year_foundation[language]}}
                </template>
                <div class="block__flex block__align-center component__margin-right">
                  <FoundationLogo />
                  <div class="order-offers__card_info">
                    {{item.date_created}}
                  </div>
                </div>
              </a-tooltip>
              <a-tooltip
                :placement="'bottom'"
                v-if="item.staff_amount">
                <template slot="title">
                  {{text.staff_amount[language]}}
                </template>
                <div class="block__flex block__align-center component__margin-right">
                  <EmployeesLogo />
                  <div class="order-offers__card_info">
                    {{item.staff_amount}}
                  </div>
                </div>
              </a-tooltip>
            </div>
          </div>
        </div>
        <div
          class="order-offers__card_body"
          v-if="item.description || (item.imgs && item.imgs.length > 0)">
          <div class="block block__between" v-if="width > t_lg">
            <div class="order-offers__card_description">
              <div v-if="item.description" v-html="item.description"></div>
            </div>
            <div
              style="margin: 0 50px"
              v-if="item.certificates && item.certificates.length > 0">
              <div class="order-offers__card_percent-lebel" style="margin-bottom: 15px">
                {{text.cert_title[language]}}
              </div>
              <div v-for="(cert, index) in item.certificates" v-bind:key="`cert_${index}`">
                <div class="block__flex block__align-center" style="margin-bottom: 10px;">
                  <div v-if="cert && cert.logo">
                    <img :src="cert.logo" class="order-offers__card_cert-logo" />
                  </div>
                  <div
                    class="order-offers__card_cert-text"
                    v-if="cert && cert.name">
                    {{cert.name}}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Carousel
                style="min-width: 232px; max-width: 232px; margin-left: 15px;"
                v-if="item.imgs"
                :images="item.imgs" />
            </div>
          </div>
          <div v-if="width <= t_lg">
            <Carousel
              style="min-width: 232px; max-width: 100%; margin-bottom: 15px"
              v-if="item.imgs"
              :images="item.imgs" />
            <div style="margin-bottom: 15px" v-if="item.certificates">
              <div class="order-offers__card_percent-lebel" style="margin-bottom: 15px">
                {{text.cert_title[language]}}
              </div>
              <div v-for="(cert, index) in item.certificates" v-bind:key="`cert_${index}`">
                <div class="block__flex block__align-center" style="margin-bottom: 10px">
                  <div v-if="cert && cert.logo">
                    <img :src="cert.logo" class="order-offers__card_cert-logo" />
                  </div>
                  <div
                    class="order-offers__card_cert-text"
                    v-if="cert && cert.name">
                    {{cert.name}}
                  </div>
                </div>
              </div>
            </div>
            <div class="order-offers__card_description">
              <div v-if="item.description" v-html="item.description"></div>
            </div>
          </div>
          <div style="margin-top: 15px;" class="order-offers__card_tags">
            <div
              class="order-offers__card_tag"
              v-for="(tag, index ) in item.tags"
              v-bind:key="`tag_${index}`">
              {{tag}}
            </div>
          </div>
        </div>
        <div class="order-offers__card_footer">
          <div :class="width > t_lg ? 'block block__between' : ''">
            <div style="margin-bottom: 10px;">
              <div class="order-offers__card_percent-lebel">
                {{text.percent[language]}}:
              </div>
              <div class="order-offers__card_percent-value">
                {{item.order_percent}}
              </div>
            </div>
            <div style="margin-bottom: 10px;" v-if="item.total_price">
              <div
                class="order-offers__card_percent-lebel"
                :style="width > t_lg ? 'text-align: right;' : ''">
                {{text.price[language]}}:
              </div>
              <div class="order-offers__card_percent-value">
                {{func.getMoney(item.total_price, 'RUB', 'ru')}}
              </div>
            </div>
          </div>
          <div style="width: 100%">
            <div
              class="block"
              v-if="item.id_type === 1">
              <Button
                @click="requestCost"
                :text="text.request_cost[language]"
                type="attention"
                block />
            </div>
            <div
              class="block"
              v-if="item.id_type === 2">
              <Button
                :text="text.confirm_cost[language]"
                type="attention"
                block />
            </div>
            <div
              :class="width > t_lg ? 'block' : ''"
              v-if="item.id_type === 4">
              <Button block
                      :style="width <= t_lg ? 'margin-bottom: 15px' : ''"
                      :text="text.view_kp[language]" @click="() => {
            $emit('openKp');
          }" />
              <!--<Button
                :text="text.sign_contract[language]"
                type="attention"
                :class="width > t_lg ? 'component__margin-left' : ''"
                block />-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from '@/assets/text/order-offers.json';
import MarkerLogo from '@/assets/pictures/part/card/Marker.svg';
import BrainLogo from '@/assets/pictures/order/brain2.svg';
import ManufLogo from '@/assets/pictures/order/manufacture.svg';
import ApproveLogo from '@/assets/pictures/order/approve.svg';
import FoundationLogo from '@/assets/pictures/order/foundation.svg';
import EmployeesLogo from '@/assets/pictures/order/employees.svg';
import Carousel from '@/components/carousel/Carousel.vue';
import Button from '@/components/button/Button.vue';
import func from '@/utilites/functions';

export default {
  name: 'Card',
  data() {
    return {
      text,
      func,
    };
  },
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    primaryColor() {
      return this.$store.state.index.primaryColor;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    width() {
      return this.$store.state.index.width;
    },
  },
  methods: {
    requestCost() {
      this.$emit('requestCost', {
        id_manufacturer: this.item.id_manufacturer,
        id_order: this.$route.params.id,
      });
    },
    companyOpen(uuid) {
      if (uuid) {
        const routeData = this.$router.resolve({ path: `/company/${uuid}` });
        window.open(routeData.href, '_blank');
      }
    },
  },
  components: {
    MarkerLogo,
    BrainLogo,
    ManufLogo,
    ApproveLogo,
    FoundationLogo,
    EmployeesLogo,
    Carousel,
    Button,
  },
};
</script>
