<template>
  <div class="i5-modal__wrapper" v-if="modalShow">
    <div :class="`i5-modal__container i5-modal__container_${size}`">
      <div class="i5-modal__header">
        <div class="i5-modal__title">{{title}}</div>
        <Close class="i5-modal__close" @click="close" />
      </div>
      <div class="i5-modal__body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Close from '@/assets/pictures/modal/modal-close.svg';

export default {
  name: 'I5Modal',
  data() {
    return {
      modalShow: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'big', // big, full-size
    },
  },
  watch: {
    show: {
      handler: function (val) {
        this.modalShow = val;
        this.$emit('show');
      },
    },
  },
  methods: {
    close() {
      this.modalShow = false;
      this.$emit('close');
    },
  },
  mounted() {
    this.modalShow = this.show;
  },
  components: {
    Close,
  },
};
</script>
