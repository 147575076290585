import { query } from '@/utilites/axios';
// import model from '@/store/modules/model';

const state = () => ({
  tempSurfacesStorage: null,
  partData: null,
  partId: {},
  material_id: null,
  blank_type: null,
  method_id: null,
  methods: [],
  hardnessTypes: [
    {
      id: 0,
      label: 'HB',
    },
    {
      id: 1,
      label: 'HRC',
    },
  ],
  method_types: [],
  hardnessAll: {},
  roughnessRaValues: [],
  roughnessRzValues: [],
  selectType: 'none',
  surfacesRoughness: {},
  activeIndex: [0],
  roughnessScale: null,
  colorize: '',
  defaultPartData: null,
  defaultGroups: [],
});

const mutations = {
  setMaterialId(state, id) {
    state.material_id = id;
  },
  setBlankType(state, type) {
    state.blank_type = type;
  },
  setMethodId(state, id) {
    state.method_id = id;
  },
  setMethods(state, methods) {
    state.methods = methods;
  },
  setMethodTypes(state, types) {
    state.method_types = types;
  },
  setPart(state, data) {
    state.partData = data;
  },
  setDefaultPart(state, data) {
    state.defaultPartData = data;
  },
  setRoughnessRaValues(state, data) {
    state.roughnessRaValues = data;
  },
  setRoughnessRzValues(state, data) {
    state.roughnessRzValues = data;
  },
  setTempSurfaces(state, data) {
    state.tempSurfacesStorage = data;
  },
  setActiveIndex(state, index) {
    state.activeIndex = index;
  },
  setRoughnessForSurface(state, data) {
    state.surfacesRoughness = data;
  },
  addRoughnessForSurface(state, data) {
    state.surfacesRoughness[data.id] = data;
  },
  changeRoughnessForSurface(state, data) {
    if (state.surfacesRoughness[data.id]) {
      state.surfacesRoughness[data.id] = { ...state.surfacesRoughness[data.id], ...data.data };
    }
  },
  delRoughnessForSurface(state, id) {
    if (state.surfacesRoughness[id]) {
      delete state.surfacesRoughness[id];
    }
  },
  setRoughnessScale(state, data) {
    state.roughnessScale = data;
  },
  setColorize(state, data) {
    state.colorize = data;
  },
  setPartId(state, data) {
    state.partId = data;
  },
  setDefaultGroups(state, data) {
    state.defaultGroups = data;
  },
  setHardnessAll(state, data) {
    state.hardnessAll = { ...state.hardnessAll, ...data };
  },
};

const getters = {
};

const actions = {
  async getBlanksByPart({ commit, state }, data) {
    if (state.methods.length === 0) {
      await query('post', 'getBlanksByPartArray', data, {}, () => {})
        .then((item) => {
          commit('setMethods', item.methods);
          commit('setMethodTypes', item.types);
        });
    }
  },
  async getDataForUploadedFile({ commit }, data) {
    await query('post', 'getDataForUploadedFile', data.data, {})
      .then((item) => {
        commit('setPart', item);
      });
  },
  async getRoughnessRz({ commit }) {
    await query('get', 'getRoughnessRzShort', {}, {})
      .then((item) => {
        commit('setRoughnessRzValues', item);
        return item;
      });
  },
  async getRoughnessRa({ commit }) {
    await query('get', 'getRoughnessRaShort', {}, {})
      .then((item) => {
        commit('setRoughnessRaValues', item);
        return item;
      });
  },
  async getRoughness({ commit }, data) {
    await query('post', 'getRoughnessByPart', data, {}).then((item) => {
      commit('setDefaultPart', item);
      return item;
    });
  },
  async getHardness({ commit, state }, data) {
    new Promise((resolve) => {
      setTimeout(() => {
        console.log(data);
        resolve({
          hardness: {
            all: { values: [200, 300], type: 0 },
            groups: [
              {
                surfaces: ['Face40', 'Face39', 'Face3', 'Face37'],
                values: [222, 330],
                type: 0,
              },
              {
                surfaces: ['Face41', 'Face2', 'Face42'],
                values: [260, 347],
                type: 1,
              },
            ],
          },
        });
      }, 1000);
    }).then((item) => {
      if (item.hardness.all) {
        commit('setHardnessAll', {
          type: item.hardness.all.type,
          values: item.hardness.all.values,
          typeName: state.hardnessTypes
            .find((type) => type.id === item.hardness.all.type).label,
        });
      }
      commit('setDefaultPart', item);
      return item;
    });
  },
  getColorize({ state }) {
    return state.roughnessScale;
  },
};

/* const setColor = (percent) => {
  const zero = 0.5;
  const one = 0.89;
  const change = one - zero;
  const summ = one + zero;
  const color = {
    r: 0,
    g: 0,
    b: 0,
  };
  if (percent >= 0 && percent <= 0.25) {
    color.r = zero;
    color.g = +(zero + ((percent / 0.25) * change)).toFixed(3);
    color.b = one;
  } else if (percent > 0.25 && percent <= 0.5) {
    color.r = zero;
    color.g = one;
    color.b = +(summ - (zero + ((percent / 0.5) * change))).toFixed(3);
  } else if (percent > 0.5 && percent <= 0.75) {
    color.r = +(zero + ((percent / 0.75) * change)).toFixed(3);
    color.g = one;
    color.b = zero;
  } else {
    color.r = one;
    color.g = +(summ - (zero + ((percent) * change))).toFixed(3);
    color.b = zero;
  }
  return color;
}; */

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
