<template>
  <I5Modal :show="showModal" @close="handleClose" size="full-size" :title="title">
    <template slot="body">
      <div class="full-size__wrapper" v-if="type === 'image'">
        <img :src="link" class="full-size__img" />
      </div>
      <ModelViewer
        v-if="type === '3d'"
        :src="link"  />
      <div v-if="type === 'none'">not image</div>
    </template>
  </I5Modal>
</template>

<script>
import I5Modal from '@/components/i5Modal/I5Modal.vue';
import ModelViewer from '@/components/modelViewer/ModelViewerV2.vue';

export default {
  name: 'FullSize',
  data() {
    return {
      showModal: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'image',
    },
    link: {
      type: String,
    },
    title: {
      type: String,
      default: '',
    },
  },
  watch: {
    show: function (val) {
      this.showModal = val;
    },
  },
  methods: {
    handleClose() {
      this.showModal = false;
      this.$emit('close');
    },
  },
  components: {
    I5Modal,
    ModelViewer,
  },
};
</script>
