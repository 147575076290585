<template>
  <Modal
    :title="title ? title : text.request_cost"
    :hide-buttons="!showFooter"
    :show="show"
    :ok-text="text.send_ok"
    :width="800"
    @ok="ok"
    @cancel="close">
    <template slot="body">
      <div class="order-offers__success-check" v-if="show">
        <div v-if="!checkData
          || (checkData['important_errors'].length === 0
          && checkData['recommendations'].length === 0)"
             class="order__success-check">
          <div>
            <SuccessLogo />
          </div>
          <div class="component__margin-left" v-if="order && order.name">
            {{text.success_check[language]}} {{order.name}}
          </div>
        </div>
        <div v-if="checkData">
          <div v-if="checkData['important_errors'] && checkData['important_errors'].length > 0">
            <div class="block__flex">
              <WarningLogo class="order-offers__check_danger" />
              <div class="order-offers__check_title component__margin-left">
                {{text.important_errors[language]}}
              </div>
            </div>
            <div
              v-for="(item, index) in checkData['important_errors']" v-bind:key="`ie_${index}`"
            >
              <div class="order-offers__check_sub-title">
                {{item.title}}
              </div>
              <ul v-for="(el, index) in item.messages" v-bind:key="`iem_${index}`">
                <li>{{el}}</li>
              </ul>
            </div>
          </div>
          <div v-if="checkData['recommendations'] && checkData['recommendations'].length > 0">
            <div class="block__flex">
              <WarningLogo class="order-offers__check_rec" />
              <div class="order-offers__check_title component__margin-left">
                {{text.recommendations[language]}}
              </div>
            </div>
            <div
              v-for="(item, index) in checkData['recommendations']" v-bind:key="`rec_${index}`"
            >
              <div class="order-offers__check_sub-title">
                {{item.title}}
              </div>
              <ul v-for="(el, index) in item.messages" v-bind:key="`rm_${index}`">
                <li>{{el}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import text from '@/assets/text/order-offers.json';
import Modal from '@/components/modal/Modal.vue';
import SuccessLogo from '@/assets/pictures/order/success.svg';
import WarningLogo from '@/assets/pictures/order/warning_medium.svg';

export default {
  name: 'CheckModal',
  data() {
    return {
      text,
    };
  },
  props: {
    checkData: {
      type: Object,
    },
    show: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
    },
    title: {
      type: Object,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    ok() {
      this.$emit('ok', this.checkData.uuid_order);
    },
  },
  components: {
    Modal,
    SuccessLogo,
    WarningLogo,
  },
};
</script>
