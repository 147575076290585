<template>
  <div
    class="block block__center block__align-center block__column pdf-viewer__container">
    <Pdf
      class="pdf-viewer__page"
      v-for="i in pageCount"
      :key="i"
      :src="pdfSrc"
      :page="i"
    ></Pdf>
  </div>
</template>

<script>
import Pdf from 'vue-pdf';

export default {
  data() {
    return {
      pdfSrc: null,
      pageCount: null,
      currentPage: 0,
      links: {
        confidentiality: 'https://market.i5.solutions/docs/confidentiality.pdf',
        cookies: 'https://market.i5.solutions/docs/cookies.pdf',
        offer: 'https://market.i5.solutions/docs/offer.pdf',
        personal_data: 'https://market.i5.solutions/docs/personal_data.pdf',
        term_of_use: 'https://market.i5.solutions/docs/term_of_use.pdf',
      },
    };
  },
  watch: {
    '$route.params.id': function (val) {
      this.scrollToTop();
      this.getPdf(this.links[val]);
    },
  },
  methods: {
    getPdf(link) {
      this.pdfSrc = Pdf.createLoadingTask(
        link,
      );
      this.pdfSrc.promise.then((pdf) => {
        this.pageCount = pdf.numPages;
      }).catch((err) => {
        throw err;
      });
    },
    setNumPages(num) {
      this.pageCount = num;
    },
    setCurPage(num) {
      this.currentPage = num;
    },
    scrollToTop() {
      // window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.scrollToTop();
    this.getPdf(this.links[this.$route.params.id]);
  },
  components: {
    Pdf,
  },
};
</script>
