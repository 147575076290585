<template>
  <div class="edit-order__order_wrapper" v-if="order">
    <Modal
      @ok="saveMaxPrice"
      :ok-text="text.save_modal"
      @cancel="setShowMaxPriceModal(false)"
      :show="showMaxPriceModal"
      :title="text.set_max_cost">
      <template slot="body">
        <div style="margin: 20px">
          <Input
            @change="changeMaxPrice($event)"
            :default-value="maxPrice ? maxPrice : ''"
            type="number"
            :label="text.max_price[language]" />
        </div>
      </template>
    </Modal>
    <div
      class="block__flex block__align-center"
      :style="`margin-bottom: 15px; ${width <= t_lg ? 'margin-left: 15px' : ''}`">
      <Back v-if="back" @click="goBack" style="
      min-width: 42px;
      margin-right: 15px;
      cursor: pointer" />
      <div class="block__flex block__align-center">
        <Logo style="min-width: 23px;" v-if="width > t_lg" />
        <div
          class="edit-order__order_title component__margin-left">
          {{text.title[language]}} № {{order.name}}
        </div>
      </div>
    </div>
    <div class="edit-order__order_container" >
      <div
        :class="width > t_lg ? 'block block__between' : ''"
        style="margin-bottom: 20px;">
        <div style="width: 100%;">
          <div :class="width > t_lg ? 'block block__align-top' : ''">
            <div>
              <div
                v-if="!customTitle"
                class="edit-order__order_block-title"
                style="margin-bottom: 10px;">
                {{text.order_info[language]}}
              </div>
              <div
                v-if="customTitle"
                class="edit-order__order_block-title"
                style="margin-bottom: 10px;">
                {{customTitle}}
              </div>
              <div v-if="order.status">
                <div class="block block__align-center component__margin-bottom">
                  <div
                    class="edit-order__order_status-label">
                    {{text.order_status[language]}}:
                  </div>
                  <div class="component__margin-left edit-order__order_value">
                    {{order.status}}
                  </div>
                </div>
              </div>
              <div
                @click="openMaxPriceModal"
                class="block block__align-center component__margin-bottom">
                <Price />
                <div
                  class="component__margin-left edit-order__order_label">
                  {{text.max_price[language]}}:
                </div>
                <div
                  :class="`component__margin-left ${edit
                  ? 'edit-order__order_active-value' : 'edit-order__order_value'}`">
                  {{order.max_price ? func.getMoney(order.max_price, currency || 'RUB', language)
                  : text.not_set[language]}}
                </div>
              </div>
              <div class="block block__align-center component__margin-bottom">
                <Parts />
                <div
                  class="component__margin-left edit-order__order_label">
                  {{text.parts[language]}}
                </div>
                <div class="component__margin-left edit-order__order_value">
                  {{order.parts ? order.parts : text.not_set2[language]}} {{text.pcs[language]}}
                </div>
              </div>
              <div class="block block__align-center component__margin-bottom">
                <Files />
                <div
                  class="component__margin-left edit-order__order_label">
                  {{text.files[language]}} {{text.pcs[language]}}
                </div>
                <div class="component__margin-left edit-order__order_value">
                  {{order.total_files
                  ? order.total_files : text.not_set2[language]}} {{text.pcs[language]}}
                </div>
              </div>
              <div class="block block__align-center component__margin-bottom">
                <Weight />
                <div
                  class="component__margin-left edit-order__order_label">
                  {{text.total_weight[language]}}
                </div>
                <div class="component__margin-left edit-order__order_value">
                  {{order.total_weight
                  ? order.total_weight + ' ' + text.g[language] : text.not_set2[language]}}
                </div>
              </div>
            </div>
            <div :style="width > t_lg ? 'margin-left: 40px;' : ''">
              <div class="edit-order__order_block-title component__margin-bottom">
                {{text.timing[language]}}
              </div>
              <div class="block component__margin-bottom">
                <div class="edit-order__order_label">
                  {{text.create_date[language]}}
                </div>
                <div class="component__margin-left edit-order__order_value">
                  {{order.date_creation}}
                </div>
              </div>
              <div class="component__margin-bottom">
                <div class="component__margin-bottom edit-order__order_value">
                  {{text.execution[language]}}
                </div>
                <DropdownV3
                  name="calendar_1"
                  :disabled="!(order && order.edit)"
                  @setClose="setDateExecClose($event)">
                  <template slot="body">
                    <div class="block block__align-center component__margin-top">
                      <CalendarImage/>
                      <div :class="`component__margin-left ${edit
                  ? 'edit-order__order_active-value' : 'edit-order__order_value'}`">
                        {{order.date_execution
                        ? text.before[language] + ' ' + order.date_execution
                        : text.not_set[language]}}
                      </div>
                    </div>
                  </template>
                  <template slot="dropdown">
                    <Calendar
                      @setDate="saveDateExec($event)"
                      :default-date="order.date_execution" />
                  </template>
                </DropdownV3>
              </div>
              <div class="component__margin-bottom">
                <div class="component__margin-bottom edit-order__order_label">
                  {{text.deadline[language]}}
                </div>
                <DropdownV3
                  name="calendar_2"
                  :disabled="!(order && order.edit)"
                  @setClose="setDateAppClose($event)">
                  <template slot="body">
                    <div class="block block__align-center component__margin-top">
                      <CalendarImage/>
                      <div :class="`component__margin-left ${edit
                  ? 'edit-order__order_active-value' : 'edit-order__order_value'}`">
                        {{order.date_applications
                        ? text.before[language] + ' ' + order.date_applications
                        : text.not_set[language]}}
                      </div>
                    </div>
                  </template>
                  <template slot="dropdown">
                    <Calendar
                      @setDate="saveDateApp($event)"
                      :default-date="order.date_applications" />
                  </template>
                </DropdownV3>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%;">
          <div class="edit-order__order_block-title component__margin-bottom">
            {{text.comment[language]}}
          </div>
          <Textarea v-if="edit" :default-value="order.comment" :rows="8" />
          <div v-if="!edit">{{order.comment}}</div>
        </div>
      </div>
      <div :class="width > t_lg ? 'block block__between' : ''"
           v-if="order.id_status !== 5 && edit">
        <Button
          block
          :text="text.view_cost[language]"
          type="primary"
          @click="() => {
          $emit('view-cost');
        }"
          :class="width > t_lg ? 'component__margin-left' : 'component__margin-bottom'" />
      </div>
    </div>
  </div>
</template>

<script>
import text from '@/assets/text/edit-order.json';
import Logo from '@/assets/pictures/editOrder/logo.svg';
import Price from '@/assets/pictures/editOrder/price.svg';
import Parts from '@/assets/pictures/editOrder/parts.svg';
import Files from '@/assets/pictures/editOrder/files.svg';
import Back from '@/assets/pictures/editOrder/back.svg';
import Weight from '@/assets/pictures/editOrder/weight.svg';
import CalendarImage from '@/assets/pictures/editOrder/calendar.svg';
import Textarea from '@/components/textarea/Textarea.vue';
import Button from '@/components/button/Button.vue';
import Calendar from '@/components/calendarV2/Calendar.vue';
import DropdownV3 from '@/components/dropdownV3/DropdownV3.vue';
import Modal from '@/components/modal/Modal.vue';
import Input from '@/components/inputV2/Input.vue';
import moment from 'moment';
import { query } from '@/utilites/axios';
import func from '@/utilites/functions';

export default {
  name: 'Order',
  data() {
    return {
      text,
      func,
      closeDateApp: () => {},
      closeDateExec: () => {},
      showMaxPriceModal: false,
      maxPrice: null,
    };
  },
  props: {
    order: {
      type: Object,
    },
    edit: {
      type: Boolean,
    },
    uuid: {
      type: String,
    },
    back: {
      type: [Object, Boolean],
    },
    customTitle: {
      type: String,
    },
  },
  watch: {
    order: {
      immediate: true,
      handler(val) {
        if (val && val.max_price) {
          this.maxPrice = val.max_price;
        }
      },
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    width() {
      return this.$store.state.index.width;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    currency() {
      return this.$store.state.index.currency;
    },
  },
  methods: {
    setDateAppClose(func) {
      this.closeDateApp = func;
    },
    setDateExecClose(func) {
      this.closeDateExec = func;
    },
    goBack() {
      if (this.back && this.back.path) {
        this.$router.push(this.back.path);
      } else {
        this.$router.back();
      }
    },
    saveDateExec(date) {
      const splitDate = date.split('.');
      const normalDate = new Date(+splitDate[2], +splitDate[1] - 1, splitDate[0]);
      this.saveData('date_execution', moment(normalDate).format('YYYY.MM.DD'));
      this.closeDateExec();
    },
    saveDateApp(date) {
      const splitDate = date.split('.');
      const normalDate = new Date(+splitDate[2], +splitDate[1] - 1, splitDate[0]);
      this.saveData('date_applications', moment(normalDate).format('YYYY.MM.DD'));
      this.closeDateApp();
    },
    saveData(name, value) {
      const data = {
        uuid: this.uuid,
      };
      data[name] = value;
      query('post', 'changeOrderInfoV2', data, {
        token: localStorage.getItem('token'),
      }).then(() => {
        this.$emit('update');
      });
    },
    setShowMaxPriceModal(val) {
      this.showMaxPriceModal = val;
    },
    openMaxPriceModal() {
      if (this.edit) {
        this.setShowMaxPriceModal(true);
      }
    },
    changeMaxPrice(val) {
      this.maxPrice = val;
    },
    saveMaxPrice() {
      if (this.maxPrice) {
        this.saveData('max_price', +this.maxPrice);
        this.setShowMaxPriceModal(false);
      }
    },
  },
  components: {
    Logo,
    Price,
    Parts,
    Files,
    Weight,
    CalendarImage,
    Textarea,
    Button,
    Calendar,
    DropdownV3,
    Back,
    Modal,
    Input,
  },
};
</script>
