<template>
  <div class="component__100">
    <div class="page__container component__100">
      <div class="component__100">
        <div class="basket-new__orders_title">Корзина</div>
        <div class="block block__between basket-new__container">
          <div class="basket-new__orders">
            <div v-for="(order, index) in orders" v-bind:key="index">
              <OrderCard :order="{
                data: order
              }" />
            </div>
          </div>
          <div class="basket-new__total">
            <div class="basket-new__total_card" :style="setTotalCardStyle()">
              <div class="basket-new__total_order-button">
                <Button type="primary" :text="'ПЕРЕЙТИ К ОФОРМЛЕНИЮ'" :fontSize="'12px'"/>
              </div>
              <div class="basket-new__total_body">
                <div class="basket-new__total_info">
                  <div class="block block__between">
                    <div class="basket-new__total_lable_big">
                      Ваша корзина
                    </div>
                    <div>
                      <span class="basket-new__total_value_info">1 деталь</span>
                      <span class="basket-new__total_value_info"> 6 кг</span>
                    </div>
                  </div>
                  <div class="block block__between">
                    <div>
                      <span class="basket-new__total_lable_small">Заказы</span>
                      <span class="basket-new__total_lable_add"> (2)</span>
                    </div>
                    <div class="basket-new__total_value_price">15 235₽</div>
                  </div>
                  <div class="block block__between">
                    <div>
                      <span class="basket-new__total_lable_small">Скидка </span>
                      <span class="basket-new__total_lable_add-underline">12%</span>
                    </div>
                    <div class="basket-new__total_value_discount">-1 213₽</div>
                  </div>
                </div>
                <div class="basket-new__total_result">
                  <div class="block block__between">
                    <div class="basket-new__total_lable_big">Общая стоймость</div>
                    <div class="basket-new__total_value_total">10 000₽</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const Button = () => import('@/components/i5Button/I5Button.vue');
const OrderCard = () => import('./components/OrderCard.vue');

export default {
  name: 'BasketNew',
  data() {
    return {
      orders: [1, 2],
    };
  },
  computed: {
    isHeaderShow() {
      return this.$store.state.index.showHeader;
    },
  },
  methods: {
    setTotalCardStyle() {
      const result = {};
      if (this.isHeaderShow) {
        result.top = '115px';
      } else {
        result.top = '20px';
      }
      return result;
    },
  },
  components: {
    Button,
    OrderCard,
  },
};
</script>
