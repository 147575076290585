const state = () => ({
  groups: {},
  groupsArray: [],
  surfaces: [],
  deletedGroup: null,
  deleteFunction: null,
  colorizeFunction: null,
  setScaleFunction: null,
  clearFunction: null,
  clearScaleFunction: null,
  colorGroupFunction: null,
  defaultGroupLoaded: false,
  decorateSurfaces: () => {},
});

const mutations = {
  setGroups(state, data) {
    state.groups = data;
  },
  setGroupsArray(state, data) {
    state.groupsArray = data;
  },
  setSurfaces(state, data) {
    state.surfaces = data;
  },
  setDeletedGroup(state, id) {
    state.deletedGroup = id;
  },
  setDeleteFunction(state, func) {
    state.deleteFunction = func;
  },
  setColorizeFunction(state, func) {
    state.colorizeFunction = func;
  },
  setSetScaleFunction(state, func) {
    state.setScaleFunction = func;
  },
  setClearScaleFunction(state, func) {
    state.clearScaleFunction = func;
  },
  setClearFunction(state, func) {
    state.clearFunction = func;
  },
  setColorGroupFunction(state, func) {
    state.colorGroupFunction = func;
  },
  setDefaultGroupState(state, data) {
    state.defaultGroupLoaded = data;
  },
  setDecorateSurfaces(state, data) {
    state.decorateSurfaces = data;
  },
};

const getters = {
};

const actions = {
  deleteGroup({ commit }, id) {
    commit('setDeletedGroup', id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
