<template>
  <div class="block block__start block__align-center block__column" id="part">
    <div class="part__container part__container_top">
      <div class="part__left" id="left_part_block">
        <div class="block__flex block__align-center" style="margin-bottom: 22px">
          <img class="part__title_logo" src="@/assets/pictures/i5/Logo.png" />
          <div class="part__title" style="margin-left: 14px">
            {{part.title[language]}}
          </div>
        </div>
        <div class="part__text part__text_page">
          {{part.page_text[language]}}
        </div>
        <div class="part__model_wrapper" v-if="width <= t_lg">
          <ModelViewer v-if="hash && time_stamp && file_name && ext"
                       logoPosition="right"
                       :src="setLink()" />
        </div>
        <div class="block block__center" v-if="width <= t_lg">
          <div class="part__mobile_parameters">
            <div>
              <div class="block__flex block__align-bottom">
                <div class="part__left_params_title">{{part.param1[language]}}</div>
                <div class="part__left_params_value component__margin-left"
                     v-if="partData && partData.size.xSize
                && partData.size.ySize && partData.size.zSize">
                  {{`${func.getNumberWithSpaces(String(partData.size.xSize))}${
                  part.size_unit[language]} x ${
                  func.getNumberWithSpaces(String(partData.size.ySize))}${
                  part.size_unit[language]} x ${
                  func.getNumberWithSpaces(String(partData.size.zSize))}${
                  part.size_unit[language]}`}}
                </div>
              </div>
              <div class="block__flex block__align-bottom">
                <div class="part__left_params_title">{{part.param2[language]}}</div>
                <div class="block__flex block__align-bottom component__margin-left">
                  <div class="part__left_params_value" v-if="weight">
                    {{func.getNumberWithSpaces(String(weight))}}
                  </div>
                  <div style="padding-left: 7px"
                       class="part__left_params_value">{{part.weight_unit[language]}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="part__material">
            <div class="part__material_container">
              <div class="part__label">
                {{part.material_label[language]}}
              </div>
              <Select type="search" :query="findMaterials"
                      name="material"
                      design="shadow"
                      @change="setMaterial($event)"
                      :defaultValue="materialName"
                      :title="'name'"
                      :fontWeight="600"
                      class="tech-req__card_select_line"
                      />
            </div>
          </div>
        </div>
        <div>
          <div class="part__material">
            <div class="part__amount">
              <div>
                <div class="part__label">
                  {{part.amount_materials[language]}}
                </div>
                <div>
                  <InputNumber @change="setDetailsAmount($event)" :width="leftBlockWidth" />
                </div>
              </div>
              <Border
                v-if="width > t_lg"
                class="part__amount_border"
                viewBox="0 0 251 147"
                stroke="#A9A9A9"
                width="251"
                height="147"
              />
            </div>
            <div class="part__left_params" v-if="width > t_lg">
              <div class="part__left_params_container part__left_params_top">
                <div class="part__left_params_title">{{part.param1[language]}}</div>
                <div class="part__left_params_value" v-if="partData && partData.size.xSize
                && partData.size.ySize && partData.size.zSize">
                  {{`${func.getNumberWithSpaces(String(partData.size.xSize))}${
                  part.size_unit[language]} x ${
                  func.getNumberWithSpaces(String(partData.size.ySize))}${
                  part.size_unit[language]} x ${
                  func.getNumberWithSpaces(String(partData.size.zSize))}${
                  part.size_unit[language]}`}}
                </div>
              </div>
              <div class="part__left_params_container">
                <div class="part__left_params_title">{{part.param2[language]}}</div>
                <div class="block__flex block__align-bottom">
                  <div class="part__left_params_value" v-if="weight">
                    {{func.getNumberWithSpaces(String(weight))}}
                  </div>
                  <div style="padding-left: 7px"
                       class="part__left_params_value">{{part.weight_unit[language]}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="part__model_wrapper" v-if="width > t_lg">
        <ModelViewer v-if="hash && time_stamp && file_name && ext"
                     logoPosition="right"
                     :src="setLink()" />
      </div>
    </div>
    <div class="part__tech-req">
      <div class="block block__center block__align-center part__tech-req_block">
        <TechReqIcon :style="{minWidth: '55px'}" />
        <div class="part__tech-req_title part__tech-req_title_top">
          {{part.tech_title[language]}}
        </div>
      </div>
      <div class="block block__center part__tech-req_text part__tech-req_block">
        {{part.tech_text[language]}}
      </div>
      <div>
        <div class="part__tech-req_card">
          <div class="block block__center">
            <div class="part__tech-req_title part__tech-req_block">
              {{part.tech_title_2[language]}}
            </div>
          </div>
          <div class="part__tech-req_block block block__around">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="part__tech-req_button" @click="goToTech">
            {{part.tech_button[language]}}
          </div>
          <div class="block block__between">
            <div class="part__params_wrapper">
              <div
                v-for="(item, index) in params[0]" v-bind:key="index"
                class="component__margin-bottom"
              >
                <div :class="width > m_lg ? 'block block__between' : ''" v-if="item">
                  <div class="part__params_title">
                    {{ item.label }}
                  </div>
                  <div class="part__params_value">
                    {{ item.value }}
                  </div>
                </div>
                <div v-if="item && item.child.length > 0">
                  <div v-for="(child, index) in item.child" v-bind:key="index">
                    <div>
                  <span class="part__params_child-label">
                    {{ child.label }}
                  </span>
                      <span class="part__params_child-value">
                    {{ child.value.join(', ') }}
                  </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="part__params_wrapper" v-if="width > 800">
              <div
                v-for="(item, index) in params[1]" v-bind:key="index"
                class="component__margin-bottom"
              >
                <div class="block block__between" v-if="item">
                  <div class="part__params_title">
                    {{ item.label }}
                  </div>
                  <div class="component__margin-left part__params_value">
                    {{ item.value }}
                  </div>
                </div>
                <div v-if="item && item.child.length > 0">
                  <div v-for="(child, index) in item.child" v-bind:key="index">
                    <div>
                  <span class="part__params_child-label">
                    {{ child.label }}
                  </span>
                      <span class="part__params_child-value">
                    {{ child.value.join(', ') }}
                  </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="part__params_wrapper" v-if="width > 1024">
              <div
                v-for="(item, index) in params[2]" v-bind:key="index"
                class="component__margin-bottom"
              >
                <div class="block block__between" v-if="item">
                  <div class="part__params_title">
                    {{ item.label }}
                  </div>
                  <div class="component__margin-left part__params_value">
                    {{ item.value }}
                  </div>
                </div>
                <div v-if="item && item.child.length > 0">
                  <div v-for="(child, index) in item.child" v-bind:key="index">
                    <div>
                  <span class="part__params_child-label">
                    {{ child.label }}
                  </span>
                      <span class="part__params_child-value">
                    {{ child.value.join(', ') }}
                  </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="part__title_wrapper block block__align-center">
      <img class="part__title_logo" src="@/assets/pictures/i5/Logo.png" />
      <div class="part__title" style="margin-left: 14px">
        {{part.title_manufacture[language]}}
      </div>
    </div>
    <div class="part__title_wrapper">
      <div class="part__text">
        {{part.manufacture_text[language]}}
      </div>
    </div>
    <div class="part__container part__container_bottom">
      <div class="part__container_filters" v-if="width > t_lg">
        <div class="part__card_wrapper part__card_filter">
          <div class="part__filter_title">
            {{part.filter_title[language]}}
          </div>
          <Filters @filter="setFilter($event)" />
        </div>
      </div>
      <div class="part__container_prices">
        <!--<div class="component__100">
          <div class="part__container_commercial">
            <CommercialCard />
            <CommercialCard />
            <CommercialCard />
          </div>
          <div class="block block__right part__commercial">
            {{part.commercial[language]}}
          </div>
        </div>-->
        <div class="block block__between part__sort" v-if="width > t_lg">
          <div class="block__flex">
            <div class="part__sort_title">{{part.sort[language]}}</div>
            <div>
              <Select
                v-if="language === 'ru'"
                type="select"
                design="shadow"
                :fontWeight="600"
                defaultValue="По умолчанию"
                :title="'title'"
                @change="sortPrices($event)"
                name="sort"
                :values="sort_ru" />
              <Select
                v-if="language === 'en'"
                @change="sortPrices($event)"
                type="select"
                :fontWeight="600"
                design="shadow"
                defaultValue="By default"
                :title="'title'"
                name="sort"
                :values="sort_en" />
            </div>
          </div>
          <div class="block__flex">
            <div class="part__sort_title">{{part.currenсy[language]}}</div>
            <div style="max-width: 50px">
              <Select
                type="select"
                @change="setCurrency($event)"
                design="shadow"
                :fontWeight="600"
                defaultValue="₽"
                :title="'title'"
                name="currency"
                :values="currency" />
            </div>
          </div>
        </div>
        <div class="component__100" v-for="item in filteredPrices"
             v-bind:key="item.id">
          <PriceMobileCard :item="item" v-if="width <= t_md" />
          <PriceCard :item="item" v-if="width > t_md"
                     @openAboutModal="openCompanyInfoModal($event)" />
        </div>
        <div
          class="block block__center"
          v-if="filteredPrices.length === 0 || prices.length === 0">
          <div class="part__empty_container">
            <img src="@/assets/pictures/select/box.png" class="part__empty_image" />
            <div class="part__empty_text">{{part.empty[language]}}</div>
          </div>
        </div>
      </div>
    </div>
    <Modal :title="{
      ru: companyInfoModalTitle,
      en: companyInfoModalTitle,
    }" :show="showCompanyInfoModal"  @ok="closeCompanyInfoModal"
           :okText="{
      ru: 'Ок',
      en: 'OK',
    }" :showCancel="false" @cancel="closeCompanyInfoModal" >
      <template slot="body">
        <div v-html="companyInfoModalText"></div>
      </template>
    </Modal>
  </div>
</template>

<script>
import moment from 'moment';
import part from '@/assets/text/part.json';
import packageJson from '@/../package.json';
import func from '@/utilites/functions/index';
import Border from '@/assets/pictures/borders/Rectangle 112.svg';
// eslint-disable-next-line
import { query } from '@/utilites/axios';

// const CommercialCard = () => import('@/components/commercial/CommercialCard.vue');
const PriceCard = () => import('./components/PriceCard.vue');
const PriceMobileCard = () => import('./components/PriceMobileCard.vue');
const InputNumber = () => import('./components/InputNumber.vue');
const ModelViewer = () => import('@/components/modelViewer/ModelViewer.vue');
const Filters = () => import('./components/Filter.vue');
const TechReqIcon = () => import('@/assets/pictures/part/TechReqIconSmall.svg');
const Select = () => import('@/components/select/I5Select.vue');
const Modal = () => import('@/components/modal/Modal.vue');

export default {
  name: 'Part',
  data() {
    return {
      part,
      packageJson,
      func,
      unsubscribe: () => {},
      showCompanyInfoModal: false,
      companyInfoModalTitle: '',
      companyInfoModalText: '',
      params: [],
      sort: 1,
      amount: 1,
      weight: '',
      timer: null,
      materialId: null,
      partData: null,
      hash: null,
      time_stamp: null,
      file_name: null,
      ext: null,
      materialName: null,
      materials: [],
      prices: [],
      filteredPrices: [],
      activeFilters: null,
      sort_ru: [
        {
          id: 1,
          title: part.sort_default.ru,
        },
        {
          id: 2,
          title: part.sort_cheap.ru,
        },
        {
          id: 3,
          title: part.sort_expensive.ru,
        },
      ],
      sort_en: [
        {
          id: 1,
          title: part.sort_default.en,
        },
        {
          id: 2,
          title: part.sort_cheap.en,
        },
        {
          id: 3,
          title: part.sort_expensive.en,
        },
      ],
      activeCurrency: 'RUB',
      currency: [
        {
          id: 1,
          title: '₽',
          value: 'RUB',
        },
        {
          id: 2,
          title: '$',
          value: 'USD',
        },
        {
          id: 3,
          title: '€',
          value: 'EUR',
        },
      ],
      leftBlockWidth: null,
    };
  },
  watch: {
    prices: function (val) {
      this.filterPrices(val);
    },
  },
  methods: {
    filterPrices(data) {
      if (this.activeFilters) {
        const tempData = [];
        data.forEach((item) => {
          let isSet = true;
          if (this.activeFilters.technology
            && !this.activeFilters.technology.includes(item.id_technology)
            && this.activeFilters.technology.length > 0) {
            isSet = false;
          }
          if (this.activeFilters.rating && item.mark < this.activeFilters.rating) {
            isSet = false;
          }
          if (this.activeFilters.country
            && !this.activeFilters.country.includes(item.id_address_country)
            && this.activeFilters.country.length > 0) {
            isSet = false;
          }
          if (this.activeFilters.region
            && !this.activeFilters.region.includes(item.id_address_region)
            && this.activeFilters.region.length > 0) {
            isSet = false;
          }
          if (this.activeFilters.calendar) {
            const deadline = moment(item.date, 'DD.MM.YYYY').toDate();
            const calendar = moment(this.activeFilters.calendar, 'DD.MM.YYYY').toDate();
            if (deadline > calendar) {
              isSet = false;
            }
          }
          if (isSet) {
            tempData.push(item);
          }
        });
        this.filteredPrices = tempData;
      } else {
        this.filteredPrices = data;
      }
    },
    closeCompanyInfoModal() {
      this.showCompanyInfoModal = false;
    },
    openCompanyInfoModal(item) {
      this.companyInfoModalTitle = item.name;
      this.companyInfoModalText = item.company_info;
      this.showCompanyInfoModal = true;
    },
    onHandleClick() {
      const material = document.getElementById('material-select');
      material.blur();
    },
    setDetailsAmount(value) {
      this.amount = +value;
      this.debounce(this.getPrices);
    },
    setCurrency(data) {
      this.activeCurrency = data.value;
      this.getPrices();
    },
    startDebounce(func) {
      this.timer = setTimeout(() => {
        func();
      }, 500);
    },
    stopDebounce() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    pricesSort(item) {
      return item;
    },
    debounce(func) {
      if (this.timer) {
        this.stopDebounce();
        this.startDebounce(func);
      } else {
        this.startDebounce(func);
      }
    },
    findMaterials(val) {
      return new Promise((resolve) => {
        query('post',
          'getMaterialsAsArray', {
            find: val,
            lang: this.language,
          }, {},
          () => {}).then((items) => {
          resolve(items);
        });
      });
    },
    sortPrices(data) {
      this.sort = data.id;
      if (data.id === 1) {
        this.prices = this.$store.state.part.prices.slice();
      } else if (data.id === 2) {
        this.prices = this.prices
          .sort((a, b) => a.price - b.price);
      } else if (data.id === 3) {
        this.prices = this.prices
          .sort((a, b) => b.price - a.price);
      }
    },
    getPrices() {
      this.$store.dispatch('part/getPrices', {
        hash: this.hash,
        time_stamp: this.time_stamp,
        file_name: this.file_name,
        amount: this.amount,
        lang: this.language,
        currency: this.activeCurrency,
        printing: {
          density: this.partData.printing.density.value,
          wall: this.partData.printing.wall.value,
          layerMin: this.partData.printing.layer.valueMin,
          layerMax: this.partData.printing.layer.valueMax,
        },
      });
    },
    setFilter(filter) {
      this.activeFilters = filter;
      this.filterPrices(this.prices);
    },
    setLink() {
      return `${packageJson.url}getFile?time_stamp=${this.time_stamp}&hash=${
        this.hash
      }&file_name=${this.file_name}&ext=stl`;
    },
    setMaterial(value) {
      query('put', 'addMaterialToPart', {
        id_material: value.id,
        time_stamp: this.time_stamp,
        hash: this.hash,
        file_name: this.file_name,
      }, {}).then((item) => {
        this.weight = item.weight;
        this.getPrices();
      });
    },
    goToTech() {
      const secretKey = localStorage.getItem('token');
      if (secretKey) {
        this.$router.push({
          path: 'technical-requirements',
          query:
            {
              h: this.hash,
              t: this.time_stamp,
              n: this.file_name,
              e: this.ext,
            },
        });
      } else {
        this.$store.commit('user/setShowAuthorizeModal', true);
      }
    },
  },
  computed: {
    m_lg() {
      return this.$store.state.index.m_lg;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    t_md() {
      return this.$store.state.index.t_md;
    },
    language() {
      return this.$store.state.index.language;
    },
    width() {
      return this.$store.state.index.width;
    },
  },
  beforeRouteEnter(to, from, next) {
    if (to.query.token) {
      localStorage.setItem('token', to.query.token);
    }
    if (!to.query.h || !to.query.t || !to.query.n) {
      next(from.path);
    } else {
      next();
    }
  },
  created() {
    const query = this.$route.query;
    this.hash = query.h;
    this.file_name = query.n;
    this.ext = query.e;
    this.time_stamp = query.t;
    // this.$store.commit('index/setLoading', true);
    this.$store.commit('part/setPartData', {
      hash: query.h,
      timeStamp: query.t,
      fileName: query.n,
      ext: query.e,
    });
    this.$store.dispatch('part/getDataForUploadedFile', {
      data: {
        hash: query.h,
        time_stamp: query.t,
        file_name: query.n,
        lang: this.language,
      },
    }).then(() => {
      this.getPrices();
    });
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'part/setPart') {
        const item = mutation.payload;
        if (item && item.part) {
          this.partData = item;
          this.materialId = item.part.material;
          this.materialName = item.part.material_name;
          this.weight = item.weight;
          this.$store.commit('materials/setMaterialId', item.part.material);
          this.$store.commit('index/setLoading', false);
        }
      } else if (mutation.type === 'part/setPartRequirements') {
        const item = mutation.payload;
        if (item) {
          const result = [[], [], []];
          if (this.width > 1024) {
            item.forEach((item, index) => {
              if ((index + 1) % 3 === 1) {
                result[0].push(item);
              } else if ((index + 1) % 3 === 2) {
                result[1].push(item);
              } else {
                result[2].push(item);
              }
            });
          } else if (this.width <= 1024 && this.width > 800) {
            item.forEach((item, index) => {
              if ((index + 1) % 2 === 1) {
                result[0].push(item);
              } else {
                result[1].push(item);
              }
            });
          } else {
            result[0] = item;
          }
          this.params = result;
        }
      } else if (mutation.type === 'index/setLanguage') {
        const query = this.$route.query;
        this.$store.dispatch('part/getDataForUploadedFile', {
          data: {
            hash: query.h,
            time_stamp: query.t,
            file_name: query.n,
            lang: this.language,
          },
        }).then(() => {
          this.getPrices();
        });
      } else if (mutation.type === 'part/setPrices') {
        const tempPrices = mutation.payload.slice();
        if (this.sort === 2) {
          this.prices = tempPrices.sort((a, b) => a.price - b.price);
        } else if (this.sort === 3) {
          this.prices = tempPrices.sort((a, b) => b.price - a.price);
        } else {
          this.prices = tempPrices;
        }
      } else if (mutation.type === 'index/setWidth') {
        const item = this.$store.state.part.partRequirements;
        const width = mutation.payload;
        if (item) {
          const result = [[], [], []];
          if (width > 1024) {
            item.forEach((item, index) => {
              if ((index + 1) % 3 === 1) {
                result[0].push(item);
              } else if ((index + 1) % 3 === 2) {
                result[1].push(item);
              } else {
                result[2].push(item);
              }
            });
          } else if (width <= 1024 && width > 800) {
            item.forEach((item, index) => {
              if ((index + 1) % 2 === 1) {
                result[0].push(item);
              } else {
                result[1].push(item);
              }
            });
          } else {
            result[0] = item;
          }
          this.params = result;
        }
      }
    });
    this.hash = this.$attrs.hash;
    this.file_name = this.$attrs.fileName;
    this.ext = this.$attrs.ext;
    this.time_stamp = this.$attrs.timeStamp;
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  components: {
    // CommercialCard,
    PriceCard,
    InputNumber,
    ModelViewer,
    Filters,
    TechReqIcon,
    Select,
    Border,
    PriceMobileCard,
    Modal,
    // SelectI5,
  },
};
</script>
