import { query } from '@/utilites/axios/index';
// import store from '@/store/index';

const state = () => ({
  hash: null,
  fileName: null,
  timeStamp: null,
  ext: null,
  partData: null,
  prices: null,
  filters: [],
  openFilters: {},
  objectFilters: {},
  userParameters: null,
  partRequirements: null,
});

const mutations = {
  setPart(state, data) {
    state.partData = data;
  },
  setPrices(state, data) {
    state.prices = data;
  },
  setFilters(state, data) {
    state.filters = data;
    const result = {};
    const objectResult = {};
    data.forEach((item, index) => {
      result[index] = false;
      objectResult[index] = item;
    });
    state.objectFilters = objectResult;
    state.openFilters = result;
  },
  setFilterState(state, data) {
    const result = {};
    result[data.id] = data.value;
    state.openFilters = { ...state.openFilters, ...result };
  },
  setPartData(state, data) {
    if (data.hash) {
      state.hash = data.hash;
    }
    if (data.timeStamp) {
      state.timeStamp = data.timeStamp;
    }
    if (data.fileName) {
      state.fileName = data.fileName;
    }
    if (data.ext) {
      state.ext = data.ext;
    }
  },
  setUserParameters(state, data) {
    state.userParameters = data;
  },
  setPartRequirements(state, data) {
    state.partRequirements = data;
  },
};

const getters = {
};

const actions = {
  getDataForUploadedFile({ commit }, data) {
    return new Promise((resolve) => {
      query('post', 'getDataForUploadedFile', data.data, {})
        .then((item) => {
          commit('setPart', item);
          resolve(item);
        });
    });
  },
  async getPrices({ commit }, data) {
    const secretKey = localStorage.getItem('token');
    const headers = {};
    if (secretKey) {
      headers.token = secretKey;
    }
    await query('post', 'getPricesV2', data, headers)
      .then((item) => {
        commit('setPrices', item.manufArray);
        commit('setFilters', item.filters);
        commit('setUserParameters', item.user_parameters);
        commit('setPartRequirements', item.part_requirements);
        item.filters.forEach((el, index) => {
          commit('setFilterState', {
            id: index,
            value: true,
          });
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
