<template>
  <div class="block block__center" style="margin-bottom: 90px; margin-top: 40px;">
    <div class="home__container block__relative home__media_container">
      <div class="block news-page__container" v-if="activeNews" >
        <div :class="width > t_lg ? 'block block__center' : ''">
          <div>
            <div v-if="width <= t_lg" class="block block__center">
              <img
                class="news-page__image component__select"
                :src="activeNews.big_image ? activeNews.big_image : activeNews.image"
                style="margin-bottom: 15px;" />
            </div>
            <div class="news-page__date component__select">
              {{activeNews.date[language]}}
            </div>
            <div class="news-page__title component__select">
              {{activeNews.title[language]}}
            </div>
            <div class="news-page__text component__select">
              {{activeNews.text[language]}}
            </div>
          </div>
          <div style="margin-left: 30px;" v-if="width > t_lg">
            <img
              class="component__select"
              :src="activeNews.big_image ? activeNews.big_image : activeNews.image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import news from '@/assets/text/news.json';

export default {
  name: 'NewsPage',
  data() {
    return {
      news,
      activeNews: null,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    m_lg() {
      return this.$store.state.index.m_lg;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    width() {
      return this.$store.state.index.width;
    },
  },
  created() {
    const index = news.findIndex((item) => item.path === this.$route.params.id);
    if (index >= 0) {
      this.activeNews = news[index];
    }
  },
};
</script>
