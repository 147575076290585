<template>
  <div class="block block__center">
    <Modal
      v-if="!error"
      :show="showDeleteModal"
      :ok-text="text.delete_part_ok"
      @cancel="() => {
        showDeleteModal = false;
        deletePart = null;
      }"
      @ok="setDeletePart(deletePart.uuid)"
      :title="text.delete_part_title">
      <template slot="body">
        <div style="padding: 20px" v-if="deletePart">
          <span>
            {{text.delete_part_text[language]}}:
            <b>{{deletePart.code}}-{{deletePart.name}}</b> ?
          </span>
        </div>
      </template>
    </Modal>
    <FullSize
      v-if="!error"
      :type="fsType"
      :link="fsLink"
      :show="fsShow"
      :title="fsFileName"
      @close="setFullSize({
        fsFileName: null,
        fsLink: null,
        fsType: null,
        fsShow: false,
      })" />
    <CheckModalV2
      v-if="!error"
      :title="text.errors_found"
      :show-footer="false"
      :show="showCheckModalV2"
      :order="order"
      @close="closeCheckModal"
      :check-data="checkData" />
    <div class="home__container">
      <div style="width: 100%;">
        <div style="width: 100%;">
          <OrderStatuses />
        </div>
        <div style="width: 100%;" v-if="!error">
          <Order
            back
            :order="order"
            :uuid="$route.params.id"
            @update="getOrder"
            @finish="() => {
            func.routerPush('/orders');
          }"
            @save="setShowCheckModal(true)"
            @view-cost="checkOrder"
            :edit="order && order.id_status === 11"/>
          <div
            v-for="(part, index) in parts"
            v-bind:key="`part_${index}`">
            <PartCard
              @setFullSize="setFullSize($event)"
              @setEdit="setEdit($event)"
              @setDelete="setShowDeleteModal($event)"
              @save="save($event.name, $event.value, $event.uuid)"
              @setHeight="setHeight($event)"
              @setTop="setTop($event)"
              :part="part"
              :index="index"
              :edit="part.edit"
              :fileFilter="fileFilters[part.uuid]"
              :isDel="parts.length > 1"
              :order="order" />
          </div>
          <div
            :style="`margin-bottom: 20px; padding: 0 ${width > t_lg ? '20px' : '15px'};`"
            v-if="order && order.id_status === 11">
            <UniversalUpload
              border-color="#B92A21"
              :uploadFunction="addNewPart"
              :upload-percent="percent"
              :formats="`${'.stp,.STP,.STEP,.step,.stl,.STL,.pdf,.txt,.dwg,.dxf,.emf,.dwt'
          + ',.jpeg,.jpg,.png,.cdw,.heif,.raw,.docx,.xlsx,.sldprt,.dxf,.ipt'}`"
              class="component__margin-bottom">
              <template slot="body">
                <div class="edit-order__upload_container">
                  <div class="edit-order__upload_small">
                    {{text.upload_first[language]}}
                  </div>
                  <div class="edit-order__upload_title">
                    {{text.create_title[language]}}
                  </div>
                  <div class="edit-order__upload_formats">
                    STP | STEP | STL | PDF | TXT | DWG | DXF | EMF | DWT
                  </div>
                  <div class="edit-order__upload_first">
                    {{text.upload_title[language]}}
                  </div>
                  <div class="edit-order__upload_small">
                    <span>{{text.upload_size[language]}} </span>
                    <b>
                      <span>50</span>
                      <span>{{text.upload_mb[language]}}</span>
                    </b>
                  </div>
                </div>
              </template>
            </UniversalUpload>
          </div>
          <div :style="width <= t_lg ? 'padding: 0 15px; width: 100%' : 'width: 100%'">
            <Button
              v-if="order.id_status !== 5"
              @click="checkOrder"
              type="primary"
              :text="text.view_cost[language]" block />
          </div>
        </div>
        <Page404 v-if="error" />
      </div>
    </div>
  </div>
</template>

<script>
import { query } from '@/utilites/axios';
import text from '@/assets/text/edit-order.json';
import UniversalUpload from '@/components/universalUpload/UniversalUploadV2.vue';
import Button from '@/components/button/Button.vue';
import FullSize from '@/components/fullSize/FullSize.vue';
import OrderStatuses from '@/components/orderStatuses/OrderStatuses.vue';
import func from '@/utilites/functions';
import CheckModalV2 from '@/pages/orderOffers/components/checkModal/CheckModal.vue';
import Modal from '@/components/modal/Modal.vue';
import Page404 from '@/pages/404/Page404.vue';
import Order from './components/order/Order.vue';
// import CheckModal from './components/checkModal/CheckModal.vue';
import PartCard from './components/partCard/PartCard.vue';

export default {
  name: 'EditOrder',
  data() {
    return {
      text,
      func,
      showDeleteModal: false,
      parts: [],
      deletePart: null,
      order: null,
      timer: null,
      savedData: {},
      activeFiles: {},
      fileFilters: {},
      newFiles: [],
      showCheckModal: false,
      fsType: 'image',
      fsLink: null,
      fsFileName: '',
      fsShow: false,
      lastLoadedIndex: 0,
      percent: 0,
      totalHeight: 0,
      cardsHeight: [],
      cardsTop: 0,
      scrollTopWhenNeedLoad: 0,
      showCheckModalV2: false,
      checkData: null,
      error: false,
    };
  },
  props: {
    scrollTop: {
      type: [Number, String],
    },
  },
  watch: {
    scrollTop: function (val) {
      if (val > this.scrollTopWhenNeedLoad) {
        if (
          this.parts[this.lastLoadedIndex + 1]
          && this.parts[this.lastLoadedIndex + 1].uuid
        ) {
          this.getPartByUuid(this.parts[this.lastLoadedIndex + 1].uuid, this.lastLoadedIndex + 1);
          this.lastLoadedIndex += 1;
          this.setScrollTopWhenNeedLoad();
        }
      }
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    width() {
      return this.$store.state.index.width;
    },
  },
  methods: {
    getOrder() {
      return new Promise((resolve, reject) => {
        query('post', 'getOrderInfoV2', {
          uuid: this.$route.params.id,
        }, {
          token: localStorage.getItem('token'),
        }).then((result) => {
          this.order = result.order_info;
          resolve();
        }).catch(() => {
          this.error = true;
          reject();
        });
      });
    },
    getParts(uuid, page) {
      return new Promise((resolve) => {
        query('post', 'getCurrentCompaniesOrderV2', {
          uuid: uuid,
          page: page,
          line_per_page: 100,
        }, {
          token: localStorage.getItem('token'),
        }).then((parts) => {
          if (page === 1) {
            this.parts = parts.table;
          } else {
            this.parts = [...this.parts, ...parts.table];
          }
          if (parts.total_pages > page) {
            this.getParts(uuid, page + 1).then(() => {
              resolve();
            });
          } else {
            resolve();
          }
        });
      });
    },
    save(name, value, uuid) {
      this.resetTimer(name, value, uuid);
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.saveData();
      }, 1000);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer(name, value, uuid) {
      if (!this.savedData[uuid]) {
        this.savedData[uuid] = {};
      }
      this.savedData[uuid][name] = value;
      this.stopTimer();
      this.startTimer();
    },
    saveData() {
      for (const item of Object.keys(this.savedData)) {
        let data = {
          uuid: item,
        };
        data = { ...data, ...this.savedData[item] };
        query('post', 'changePartOrderInfoV2', data, {
          token: localStorage.getItem('token'),
        }).then(() => {
          this.getPartByUuid(item);
          // this.getParts(this.$route.params.id, 1);
        });
      }
      this.savedData = {};
    },
    getPart(index) {
      if (this.parts[index] && this.parts[index].uuid && !this.parts[index].load) {
        query('post', 'getPartOrderInfoV2', {
          uuid: this.parts[index].uuid,
        }, {
          token: localStorage.getItem('token'),
        }).then((orderPart) => {
          const parts = this.parts.slice();
          parts[index] = {
            ...parts[index],
            ...orderPart.part_order_info,
          };
          parts[index].part_order_info = orderPart.part_order_info;
          if (!this.activeFiles[parts[index].uuid]) {
            this.activeFiles[parts[index].uuid] = parts[index].part_order_info.files[0].hash;
            this.fileFilters[parts[index].uuid] = 3;
          }
          parts[index].load = true;
          this.parts = parts;
        });
      }
    },
    async getPartByUuid(uuid) {
      await query('post', 'getPartOrderInfoV2', {
        uuid: uuid,
      }, {
        token: localStorage.getItem('token'),
      }).then((orderPart) => {
        const parts = this.parts.slice();
        const index = parts.findIndex((el) => el.uuid === uuid);
        parts[index] = {
          ...parts[index],
          ...orderPart.part_order_info,
        };
        parts[index].part_order_info = orderPart.part_order_info;
        if (!this.activeFiles[parts[index].uuid]) {
          this.activeFiles[parts[index].uuid] = parts[index].part_order_info.files[0].hash;
          this.fileFilters[parts[index].uuid] = 3;
        }
        parts[index].load = true;
        this.parts = parts;
      });
    },
    setEdit(index) {
      const parts = this.parts.slice();
      parts[index].edit = !parts[index].edit;
      this.parts = parts;
      if (!parts[index].load) {
        this.getPart(index);
      }
    },
    addFilesToOrder(index) {
      const data = [];
      this.newFiles.forEach((file) => {
        data.push({
          hash: file.hash,
          time_stamp: file.time_stamp || file.timeStamp,
          file_name: file.file_name || file.fileName,
        });
      });
      query('post', 'addFilesToOrderPartV2', {
        uuid: this.parts[index].uuid,
        files: data,
      }, {
        token: localStorage.getItem('token'),
      }).then(() => {
        this.newFiles = [];
        this.getParts(this.$route.params.id, 1).then(() => {
          this.getPart(0);
          this.setEdit(0);
        });
      });
    },
    setShowCheckModal(val) {
      this.showCheckModal = val;
    },
    setFullSize(e) {
      this.fsShow = e.fsShow;
      this.fsFileName = e.fsFileName;
      this.fsType = e.fsType;
      this.fsLink = e.fsLink;
    },
    addNewPart(formData, index, name) {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        if (token) {
          query('post', 'addNewPartV2', {
            code: String(this.parts.length + 1),
            name: name,
            files: [],
          }, {
            token: localStorage.getItem('token'),
          }, (e) => {
            const percent = String(Math.round((e.loaded * 100) / e.total));
            this.percent = percent;
          }).then(async (item) => {
            await query('post', 'addNewPartToOrderV2', {
              order_uuid: this.$route.params.id,
              parts: [item.uuid],
            }, {
              token: localStorage.getItem('token'),
            }).then((part) => {
              query('put', 'addFile', formData, {
                token,
              }, (e) => {
                const percent = String(Math.round((e.loaded * 100) / e.total));
                this.uploadPercent = percent;
              }).then((file) => {
                query('post', 'addFilesToOrderPartV2', {
                  uuid: part.order_part,
                  files: [
                    {
                      hash: file.hash,
                      time_stamp: file.timeStamp,
                      file_name: file.fileName,
                    },
                  ],
                }, {
                  token: localStorage.getItem('token'),
                }).then(() => {
                  resolve();
                  this.parts = [];
                  this.getOrder().then(() => {
                    this.updateGetParts();
                  });
                });
              });
            });
          });
        } else {
          reject();
        }
      });
    },
    updateGetParts() {
      this.getParts(this.$route.params.id, 1).then(() => {
        if (this.parts.length > 0) {
          for (const i of [...Array(3).keys()]) {
            if (this.parts[i] && this.parts[i].uuid) {
              this.lastLoadedIndex = i;
              this.setScrollTopWhenNeedLoad();
              this.getPartByUuid(this.parts[i].uuid);
            }
          }
          if (this.edit) {
            this.parts[this.parts.length - 1].edit = true;
          }
        }
      });
    },
    setHeight(e) {
      this.cardsHeight[e.index] = e.height;
      let totalHeight = 0;
      this.cardsHeight.forEach((val) => {
        totalHeight += val;
      });
      this.totalHeight = totalHeight;
      this.setScrollTopWhenNeedLoad();
    },
    setTop(val) {
      this.cardsTop = val;
    },
    closeCheckModal() {
      this.showCheckModalV2 = false;
      this.checkData = null;
    },
    setScrollTopWhenNeedLoad() {
      if (this.lastLoadedIndex + 1 <= this.parts.length - 1
        && this.cardsHeight[this.lastLoadedIndex + 1]) {
        const newList = this.cardsHeight.filter((el, i) => i <= this.lastLoadedIndex);
        let sum = 0;
        sum += this.cardsTop;
        newList.forEach((el) => {
          sum += el;
        });
        sum -= window.innerHeight;
        sum -= 300;
        this.scrollTopWhenNeedLoad = sum;
      }
    },
    checkOrder() {
      const uuid = this.$route.params.id;
      query('post', 'checkOrder', {
        uuid: uuid,
      }, {
        token: localStorage.getItem('token'),
      }).then((checkData) => {
        if (!checkData.check) {
          this.checkData = checkData;
          this.showCheckModalV2 = true;
        } else {
          func.routerPush(`/order-offers/${uuid}`);
        }
      });
    },
    setDeletePart(uuid) {
      query('delete', 'deletePartFromOrderV2', {
        uuid: uuid,
      }, {
        token: localStorage.getItem('token'),
      }).then(() => {
        this.showDeleteModal = false;
        this.deletePart = null;
        this.updateGetParts();
      });
    },
    setShowDeleteModal(index) {
      this.deletePart = this.parts[index];
      this.showDeleteModal = true;
    },
  },
  mounted() {
    this.getOrder().then(() => {
      this.updateGetParts();
    });
    this.$store.commit('user/setAfterAuthorize', () => {
      this.getOrder().then(() => {
        this.updateGetParts();
      });
    });
  },
  beforeDestroy() {
    this.$store.commit('user/setAfterAuthorize', () => {});
  },
  components: {
    Button,
    UniversalUpload,
    Order,
    // CheckModal,
    FullSize,
    OrderStatuses,
    PartCard,
    CheckModalV2,
    Modal,
    Page404,
  },
};
</script>
