import router from '../../router';
import store from '../../store';

const getNumberWithSpaces = (value) => {
  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.').toString();
};
const getMoney = (value, currency, locale) => {
  const options = { style: 'currency', currency };
  const temp = new Intl.NumberFormat(locale, options);
  return temp.format(value).toString();
};

const routerPush = (patch) => {
  if (!store.state.index.companyId) {
    router.push(patch);
  } else {
    router.push({
      path: patch,
      query: { company_id: store.state.index.companyId },
    });
  }
};

export default {
  getNumberWithSpaces,
  getMoney,
  routerPush,
};
