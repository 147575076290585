<template>
  <div style="margin-bottom: 20px">
    <div class="edit-order__part_header">
      <div class="block__flex block__align-center">
        <PartOrder style="margin-right: 10px;" v-if="width > t_lg" />
        <div class="edit-order__part_title">{{part.code}} {{part.name}}</div>
      </div>
      <div class="block__flex block__align-center">
        <Button
          v-if="order && order.id_status === 11"
          type="whiteboard"
          @click="setEdit(index)"
          :text="text.edit[language]" />
        <Button
          v-if="order && order.id_status === 11 && isDel"
          type="whiteboard"
          @click="setDelete(index)"
          class="component__margin-left"
          :text="text.delete[language]"/>
      </div>
    </div>
    <div class="edit-order__part_body" v-if="edit">
      <div :class="width > t_lg ? 'block block__between' : ''">
        <div style="width: 100%">
          <div
            class="edit-order__part_head-title"
            style="margin-bottom: 30px;">
            {{text.information[language]}}:
          </div>
          <Input
            :ti="1"
            :label="text.code[language]"
            :default-value="part.code_order"
            :required="true"
            @change="save('code_order', $event, part.uuid)"
            name="code" />
          <Input
            :label="text.name[language]"
            :ti="2"
            :required="true"
            :default-value="part.name"
            @change="save('name_order', $event, part.uuid)"
            name="name" />
          <Search
            :label="text.material[language]"
            :query="{
                    method: 'post',
                    url: 'getMaterialsAsArray',
                    label: 'name',
                    value: 'id',
                  }"
            :ti="3"
            :required="true"
            :default-value="part.material"
            clearable
            @clear="save('id_material', null, part.uuid)"
            @change="save('id_material', $event.value, part.uuid)"
            name="material" />
          <InputNumber
            :label="text.amount[language]"
            :required="true"
            :max="999999"
            :ti="4"
            :min="1"
            :step="1"
            @change="save('amount', +$event, part.uuid)"
            :default-value="+part.amount || null"
            name="amount"/>
          <Input
            :label="text.weight[language]"
            :ti="5"
            :step="1"
            type="number"
            :text-position="'center'"
            :default-value="part.weight"
            :sub-label="text.weight_unit[language]"
            @change="save('weight', $event, part.uuid)"
            name="weight" />
          <SizeInput
            :x-size="part.x_size"
            :y-size="part.y_size"
            :z-size="part.z_size"
            :tab-indexes="[6, 7, 8]"
            @change="saveSize($event, part.uuid, index)" />
        </div>
        <div :style="width > t_lg ? 'width: 100%; margin-left: 25px;' : 'width: 100%;'">
          <div
            class="block block__column"
            v-if="part.part_order_info && part.part_order_info.files"
            style="height: 100%">
            <div class="edit-order__part_preview-container">
              <div
                style="width: 100%; display: contents; height: 100%;"
                v-for="preview in part.part_order_info.files
                      .filter((el) => el.hash === activeFile)"
                v-bind:key="`preview_${preview.hash}`">
                <div></div>
                <img
                  v-if="(!file3d || preview.hash !== file3d)
                        && setShowImage(preview.hash, showImages)"
                  @error="setUpdateError($event, preview.hash)"
                  :src="preview.preview_img"
                  class="edit-order__part_preview" />
                <div
                  style="width: 100%;"
                  v-else-if="file3d && preview.hash === file3d">
                  <ModelViewer
                    style="height: 250px; width: 100%;"
                    :src="preview.preview_3d"  />
                </div>
                <div v-else style="height: 250px"></div>
                <div class="edit-order__part_preview-top">
                  <FullSizeLogo
                    @click="setFullSize(true, preview)"
                    class="edit-order__part_icon" />
                </div>
                <div class="edit-order__part_preview-bottom">
                  <Logo360
                    @click="set3dFile(preview.hash)"
                    v-if="preview.preview_3d"
                    class="edit-order__part_icon" />
                </div>
                <div
                  v-if="!setShowImage(preview.hash, showImages)"
                  style="height: 250px; width: 100%;"
                  class="block block__center block__align-center">
                  <a-spin />
                </div>
              </div>
            </div>
            <div
              class="block block__align-center"
              style="margin-top: 20px; margin-bottom: 20px;">
              <ArrowLeft
                @click="setFileFilter('minus')"
                :class="fileFilterNumber - 3 > 0
                      ? 'edit-order__part_arrow edit-order__part_arrow_active'
                      : 'edit-order__part_arrow'"
                style="margin-right: 10px;" />
              <div v-for="file in part.part_order_info.files
                    .filter((el, elIndex) => elIndex <= fileFilterNumber
                    && elIndex > fileFilterNumber - 4)"
                   :class="activeFile
                         === file.hash
                        ? 'edit-order__part_preview-min_container'
                        + ' edit-order__part_preview-min_container_active'
                        : 'edit-order__part_preview-min_container'"
                   @click="setActiveFile(file.hash)"
                   v-bind:key="`file_${index}_${file.hash}`">
                <img
                  v-if="setShowImage(file.hash, showImages)"
                  @error="setUpdateError($event, file.hash)"
                  class="edit-order__part_preview-min"
                  :src="file.preview_img" />
                <div
                  v-else
                  style="height: 70px; width: 140px;"
                  class="block block__center block__align-center">
                  <a-spin />
                </div>
              </div>
              <ArrowRight
                @click="setFileFilter('plus')"
                :class="fileFilterNumber < part.part_order_info.files.length - 1
                      ? 'edit-order__part_arrow edit-order__part_arrow_active'
                      : 'edit-order__part_arrow'" />
            </div>
            <UniversalUpload
              class="component__margin-bottom"
              :uploadFunction="uploadFile"
              :upload-percent="uploadPercent"
              :formats="`${'.stp,.STP,.STEP,.step,.stl,.STL,.pdf,.txt,.dwg,.dxf,.emf,.dwt'
          + ',.jpeg,.jpg,.png,.cdw,.heif,.raw,.docx,.xlsx,.sldprt,.dxf,.ipt'}`"
              >
              <template slot="body">
                <div class="edit-order__upload_container">
                  <div class="edit-order__upload_small">
                    {{text.upload_first[language]}}
                  </div>
                  <div class="edit-order__upload_title">
                    {{text.upload_title[language]}}
                  </div>
                  <div class="edit-order__upload_formats">
                    STP | STEP | STL | PDF | TXT | DWG | DXF | EMF | DWT
                  </div>
                  <div class="edit-order__upload_first">
                    {{text.upload_title[language]}}
                  </div>
                  <div class="edit-order__upload_small">
                    <span>{{text.upload_size[language]}} </span>
                    <b>
                      <span>50</span>
                      <span>{{text.upload_mb[language]}}</span>
                    </b>
                  </div>
                </div>
              </template>
            </UniversalUpload>
          </div>
        </div>
      </div>
      <Textarea
        :label="text.comment_part[language]"
        :ti="9"
        :default-value="part.comment"
        @change="save('comment', $event, part.uuid)"
        name="comment" />
      <div class="edit-order__add" v-if="width > t_lg">
        <div
          style="margin-bottom: 40px"
          class="block block__center edit-order__add_title">
          {{text.add_title[language]}}
        </div>
        <Button
          v-if="has3dModel"
          block
          type="attention"
          @click="() => {
                  func.routerPush(`/tech-requirements/${part.uuid}`);
                }"
          :text="text.edit_add_params[language]" />
      </div>
    </div>
    <div class="edit-order__part_body" v-else>
      <div
        :style="width <= t_lg ? 'flex-direction: column-reverse' : ''"
        :class="width > t_lg ? 'block block__between' : 'block'">
        <div style="width: 100%;">
          <div class="block block__column block__between" style="height: 100%;">
            <div>
              <div
                v-if="part.code"
                class="block block__between block__align-center component__margin-bottom">
                <div class="edit-order__params_label">
                  {{text.code[language]}}
                </div>
                <div class="edit-order__params_value">
                  {{part.code}}
                </div>
              </div>
              <div
                v-if="part.name"
                class="block block__between block__align-center component__margin-bottom">
                <div class="edit-order__params_label">
                  {{text.name[language]}}
                </div>
                <div class="edit-order__params_value">
                  {{part.name}}
                </div>
              </div>
              <div
                class="block block__between block__align-center component__margin-bottom">
                <div class="edit-order__params_label">
                  {{text.material[language]}}
                </div>
                <div class="edit-order__params_value">
                  {{part.material ? part.material : text.not_set2[language]}}
                </div>
              </div>
              <div
                class="block block__between block__align-center component__margin-bottom">
                <div class="edit-order__params_label">
                  {{text.amount[language]}}
                </div>
                <div class="edit-order__params_value">
                  {{part.amount ? part.amount : text.not_set2[language]}}
                </div>
              </div>
              <div
                class="block block__between block__align-center component__margin-bottom">
                <div class="edit-order__params_label">
                  {{text.weight[language]}}
                </div>
                <div class="edit-order__params_value" v-if="part.weight">
                  {{part.weight}} {{text.weight_unit[language]}}
                </div>
                <div class="edit-order__params_value" v-else>
                  {{text.not_set2[language]}}
                </div>
              </div>
              <div
                class="block block__between block__align-center component__margin-bottom">
                <div class="edit-order__params_label">
                  {{text.size[language]}}
                </div>
                <div
                  class="edit-order__params_value"
                  v-if="part.x_size && part.y_size && part.z_size">
                  {{part.x_size}} {{text.mm[language]}} x {{part.y_size}}
                  {{text.mm[language]}} x {{part.z_size}} {{text.mm[language]}}
                </div>
                <div class="edit-order__params_value" v-else>
                  {{text.not_set2[language]}}
                </div>
              </div>
            </div>
            <Button
              v-if="width > t_lg && has3dModel"
              block
              type="attention"
              @click="() => {
                      func.routerPush(`/tech-requirements/${part.uuid}`);
                    }"
              :text="text.tech_req[language]" />
          </div>
        </div>
        <div
          :style="width <= t_lg ? 'margin-bottom: 15px;' : ''"
          :class="`${width > t_lg ? 'component__margin-left' : ''} edit-order__part_right`">
          <div class="block__flex" style="height: 100%">
            <div
              style="width: 100%; height: 100%;"
              v-if="part && part.part_order_info
                    && part.part_order_info.files">
              <div
                style="position: relative; height: 100%;"
                v-for="preview in part.part_order_info.files
                      .filter((el) => el.hash === activeFile)"
                v-bind:key="`preview_${preview.hash}`"
                class="edit-order__part_preview-container">
                <img :src="preview.preview_img"
                     v-if="(!file3d || !preview.hash === file3d)
                        && setShowImage(preview.hash, showImages)"
                     @error="setError"
                     class="edit-order__part_preview" />
                <div v-else class="edit-order__part_preview">
                </div>
                <div
                  class="edit-order__part_3d-container"
                  style="height: 100%"
                  v-if="preview.hash === file3d && preview.hash === file3d">
                  <ModelViewer
                    style="width: 100%; height: 100%"
                    :src="preview.preview_3d"  />
                </div>
                <div class="edit-order__part_preview-top">
                  <FullSizeLogo
                    @click="setFullSize(true, preview)"
                    class="edit-order__part_icon" />
                </div>
                <div class="edit-order__part_preview-bottom">
                  <Logo360
                    @click="set3dFile(preview.hash)"
                    v-if="preview.preview_3d"
                    class="edit-order__part_icon" />
                </div>
              </div>
            </div>
            <div
              class="component__margin-left"
              :style="`width: ${width < t_lg && width >= t_md ? '20%' : '50%'}`">
              <div
                :class="part && part.part_order_info.files
                      && part.part_order_info.files.length > 2 ? 'edit-order__part_elements'
                      : 'edit-order__part_elements2'"
                v-if="part && part.part_order_info
                    && part.part_order_info.files">
                <div v-for="file in part.part_order_info.files
                    .filter((el, elIndex) => elIndex <= fileFilterNumber
                    && elIndex > fileFilterNumber - 4)"
                     :class="activeFile
                         === file.hash
                        ? 'edit-order__edit_preview-min_container'
                        + ' edit-order__edit_preview-min_container_active'
                        : 'edit-order__edit_preview-min_container'"
                     @click="setActiveFile(file.hash)"
                     :style="part && part.part_order_info.files
                      && part.part_order_info.files.length > 2 ? ''
                      : 'margin-bottom: 5px;'"
                     v-bind:key="`file_${index}_${file.hash}`">
                  <img
                    v-if="setShowImage(file.hash, showImages)"
                    @error="setUpdateError($event, file.hash)"
                    class="edit-order__edit_preview-min"
                    :src="file.preview_img" />
                  <div
                    v-else
                    style="width: 100%"
                    class="block block__center block__align-center">
                    <a-spin />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from '@/assets/text/edit-order.json';
import PartOrder from '@/assets/pictures/editOrder/part_logo.svg';
import Button from '@/components/button/Button.vue';
import Input from '@/components/inputV2/Input.vue';
import Search from '@/components/search/Search.vue';
import InputNumber from '@/components/inputNumber/InputNumber.vue';
import ModelViewer from '@/components/modelViewer/ModelViewerV2.vue';
import SizeInput from '@/components/sizeInput/SizeInput.vue';
import UniversalUpload from '@/components/universalUpload/UniversalUploadV2.vue';
import Textarea from '@/components/textarea/Textarea.vue';
import func from '@/utilites/functions';
import Default from '@/assets/pictures/editOrder/edit-deafult.png';
import FullSizeLogo from '@/assets/pictures/editOrder/fullSize.svg';
import Logo360 from '@/assets/pictures/editOrder/3d360.svg';
import ArrowLeft from '@/assets/pictures/editOrder/arrow-left.svg';
import ArrowRight from '@/assets/pictures/editOrder/arrow-right.svg';
import { query } from '@/utilites/axios';

export default {
  name: 'PartCard',
  data() {
    return {
      func,
      text,
      file3d: null,
      showImages: true,
      imageTimer: null,
      fsType: 'image',
      fsLink: null,
      fsFileName: '',
      fsShow: false,
      activeFile: null,
      fileFilterNumber: 0,
      uploadPercent: null,
      errorFiles: null,
      partData: null,
      has3dModel: false,
    };
  },
  props: {
    index: {
      type: Number,
    },
    part: {
      type: Object,
    },
    order: {
      type: Object,
    },
    edit: {
      type: Boolean,
    },
    fileFilter: {
      type: Number,
    },
    isDel: {
      type: Boolean,
    },
  },
  watch: {
    fileFilter: {
      immediate: true,
      handler: function (val) {
        this.fileFilterNumber = val;
      },
    },
    part: {
      immediate: true,
      handler: function (val) {
        this.partData = val;
        this.setHas3dModel(val);
        if (this.$el && this.$el.clientHeight) {
          this.setHeight();
        }
      },
    },
    'part.files': {
      immediate: true,
      handler: function () {
        this.setDefaultActiveFile();
        if (this.$el && this.$el.clientHeight) {
          this.$nextTick(() => {
            this.setHeight();
          });
        }
      },
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    t_md() {
      return this.$store.state.index.t_md;
    },
    width() {
      return this.$store.state.index.width;
    },
  },
  methods: {
    setHas3dModel(val) {
      let result = false;
      if (val && val.files && val.files.length > 0) {
        for (const file of val.files) {
          if (file.preview_3d) {
            result = true;
          }
        }
      }
      this.has3dModel = result;
    },
    setEdit() {
      this.$emit('setEdit', this.index);
    },
    setDelete() {
      this.$emit('setDelete', this.index);
    },
    save(name, value, uuid) {
      this.$emit('save', {
        name,
        value,
        uuid,
      });
    },
    saveSize(values, uuid) {
      if (values.x) {
        this.x = +values.x;
        this.save('x_size', +values.x, uuid);
      } else {
        this.save('x_size', this.part.x_size, uuid);
      }
      if (values.y) {
        this.y = +values.y;
        this.save('y_size', +values.y, uuid);
      } else {
        this.save('y_size', this.part.y_size, uuid);
      }
      if (values.z) {
        this.z = +values.z;
        this.save('z_size', +values.z, uuid);
      } else {
        this.save('z_size', this.part.z_size, uuid);
      }
    },
    setShowImage(hash, showImages) {
      let result = true;
      if (this.errorFiles && this.errorFiles[hash]
        && !showImages) {
        result = false;
      }
      return result;
    },
    setUpdateError(e, hash) {
      let errorFiles = { ...this.errorFiles };
      if (!errorFiles) {
        errorFiles = {};
      }
      if (!errorFiles[hash]) {
        errorFiles[hash] = 1;
      } else {
        errorFiles[hash] += 1;
      }
      if (errorFiles[hash] > 5) {
        e.target.src = Default;
      } else {
        this.errorFiles = errorFiles;
        this.showImages = false;
        this.resetImageTimer();
      }
    },
    setError(e) {
      e.target.src = Default;
    },
    startImageTimer() {
      this.imageTimer = setTimeout(() => {
        this.showImages = true;
      }, 1000);
    },
    stopImageTimer() {
      clearTimeout(this.imageTimer);
      this.imageTimer = null;
    },
    resetImageTimer() {
      this.stopImageTimer();
      this.startImageTimer();
    },
    setFullSize(val, preview) {
      if (val) {
        if (this.file3d && this.file3d === preview.hash) {
          this.fsType = '3d';
          this.fsLink = preview.preview_3d;
          this.fsFileName = preview.filename;
        } else if (preview.preview_3d) {
          this.fsType = 'image';
          this.fsLink = preview.preview_img;
          this.fsFileName = preview.filename;
        } else {
          this.fsType = 'image';
          this.fsLink = preview.download;
          this.fsFileName = preview.filename;
        }
      }
      this.fsShow = val;
      this.$emit('setFullSize', {
        fsType: this.fsType,
        fsLink: this.fsLink,
        fsFileName: this.fsFileName,
        fsShow: this.fsShow,
      });
    },
    set3dFile(hash) {
      this.file3d = hash;
    },
    setFileFilter(type) {
      if (type === 'minus' && this.fileFilterNumber - 3 > 0) {
        this.fileFilterNumber = --this.fileFilterNumber;
      } else if (type === 'plus'
        && this.fileFilterNumber
        < this.part.part_order_info.files.length - 1) {
        this.fileFilterNumber = ++this.fileFilterNumber;
      }
    },
    setActiveFile(hash) {
      this.activeFile = hash;
    },
    uploadFile(formData) {
      return new Promise((resolve, reject) => {
        query('put', 'addFile', formData, {
          token: localStorage.getItem('token'),
        }, (evt) => {
          this.uploadPercent = Math.round((evt.loaded * 100) / evt.total);
        }).then((result) => {
          const data = {
            hash: result.hash,
            time_stamp: result.time_stamp || result.timeStamp,
            file_name: result.file_name || result.fileName,
          };
          query('post', 'addFilesToOrderPartV2', {
            uuid: this.part.uuid,
            files: [data],
          }, {
            token: localStorage.getItem('token'),
          }).then(() => {
            this.getPart();
          });
          resolve(result);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    setDefaultActiveFile() {
      if (this.part && this.part.files && this.part.files.length > 0) {
        this.activeFile = this.part.files[0].hash;
      }
    },
    getPart() {
      query('post', 'getPartOrderInfoV2', {
        uuid: this.part.uuid,
      }, {
        token: localStorage.getItem('token'),
      }).then((orderPart) => {
        const part = { ...this.partData };
        part.part_order_info.files = orderPart.part_order_info.files;
        this.partData = part;
      });
    },
    setHeight() {
      this.$emit('setHeight', {
        index: this.index,
        height: this.$el.clientHeight,
      });
    },
  },
  mounted() {
    this.setDefaultActiveFile();
    this.setHeight();
    this.setHas3dModel(this.part);
    if (this.index === 0) {
      this.$emit('setTop', this.$el.getBoundingClientRect().y);
    }
  },
  beforeDestroy() {
    this.stopImageTimer();
  },
  components: {
    PartOrder,
    Button,
    Input,
    Search,
    InputNumber,
    ModelViewer,
    FullSizeLogo,
    Logo360,
    SizeInput,
    ArrowLeft,
    ArrowRight,
    UniversalUpload,
    Textarea,
  },
};
</script>
