var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block block__center",staticStyle:{"margin-bottom":"90px"}},[_c('div',{staticClass:"home__container block__relative home__media_container"},[_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"home__title"},[_vm._v(" "+_vm._s(_vm.text.media_title[_vm.language])+" ")]),_c('div',{staticClass:"block block__align-center block__between home__media_row"},[_c('a',{attrs:{"href":'https://ruposters.ru/news/23-06-2022/tehnopolise-predstavili-'
          + 'innovatsionnuyu-platformu-izgotovleniya-detalei',"target":"_blank"}},[_c('div',{on:{"mouseenter":function($event){return _vm.setActive(1, true)},"mouseleave":function($event){return _vm.setActive(1, false)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage !== 1),expression:"activeImage !== 1"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/ruposters-grey.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage === 1),expression:"activeImage === 1"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/ruposters-color.png")}})])]),_c('a',{attrs:{"target":"_blank","href":'https://technomoscow.ru/press/v-oez-tekhnopolis-moskva-'
          + 'predstavili-intellektualnuyu-platformu-dlya-izgotovleniya-detaley-na-zakaz/'}},[_c('div',{on:{"mouseenter":function($event){return _vm.setActive(2, true)},"mouseleave":function($event){return _vm.setActive(2, false)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage !== 2),expression:"activeImage !== 2"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/technoplic-grey.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage === 2),expression:"activeImage === 2"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/technologic-color.png")}})])]),_c('a',{attrs:{"href":'https://iot.ru/promyshlennost/v-moskve-vypustili-intellektualnuyu-'
        + 'platformu-dlya-izgotovleniya-detaley-na-zakaz',"target":"_blank"}},[_c('div',{on:{"mouseenter":function($event){return _vm.setActive(3, true)},"mouseleave":function($event){return _vm.setActive(3, false)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage !== 3),expression:"activeImage !== 3"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/iot-grey.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage === 3),expression:"activeImage === 3"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/iot-color.png")}})])])]),_c('div',{staticClass:"block block__align-center block__between home__media_row"},[_c('a',{attrs:{"href":"https://vz.ru/news/2022/6/27/1164952.html","target":"_blank"}},[_c('div',{on:{"mouseenter":function($event){return _vm.setActive(4, true)},"mouseleave":function($event){return _vm.setActive(4, false)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage !== 4),expression:"activeImage !== 4"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/vzglyad-grey.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage === 4),expression:"activeImage === 4"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/vzglyad-color.png")}})])]),_c('a',{attrs:{"href":'https://deloros.ru/v-startup-klube-delovoj-rossii-predstavili'
          + '-proekty-v-sfere-cifrovoj-promyshlennosti.html',"target":"_blank"}},[_c('div',{on:{"mouseenter":function($event){return _vm.setActive(5, true)},"mouseleave":function($event){return _vm.setActive(5, false)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage !== 5),expression:"activeImage !== 5"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/delovaya-grey.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage === 5),expression:"activeImage === 5"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/delovaya-color.png")}})])]),_c('a',{attrs:{"target":"_blank","href":'https://finance.rambler.ru/economics/48900595-moskovskie-promyshlenniki'
          + '-razrabotali-onlayn-platformu-dlya-izgotovleniya-metallicheskih-detaley/'}},[_c('div',{on:{"mouseenter":function($event){return _vm.setActive(6, true)},"mouseleave":function($event){return _vm.setActive(6, false)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage !== 6),expression:"activeImage !== 6"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/rampler-grey.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage === 6),expression:"activeImage === 6"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/rambler-color.png")}})])])]),_c('div',{staticClass:"block block__align-center block__between home__media_row"},[_c('a',{attrs:{"href":"https://www.cnews.ru/news/line/2022-06-23_v_oez_tehnopolis_moskva","target":"_blank"}},[_c('div',{on:{"mouseenter":function($event){return _vm.setActive(7, true)},"mouseleave":function($event){return _vm.setActive(7, false)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage !== 7),expression:"activeImage !== 7"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/cnews-grey.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage === 7),expression:"activeImage === 7"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/cnews-color.png")}})])]),_c('a',{attrs:{"target":"_blank","href":'https://www.vedomosti.ru/press_releases/2022/06/23/v-oez-tehnopolis-moskva-'
        + 'predstavili-intellektualnuyu-platformu-dlya-izgotovleniya-detalei-na-zakaz'}},[_c('div',{on:{"mouseenter":function($event){return _vm.setActive(8, true)},"mouseleave":function($event){return _vm.setActive(8, false)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage !== 8),expression:"activeImage !== 8"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/Vedomosti-grey.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage === 8),expression:"activeImage === 8"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/Vedomosti-color.png")}})])]),_c('a',{attrs:{"href":'https://3dtoday.ru/blogs/news3dtoday/cto-kak-gde-i-'
        + 'pocem-umnaya-proizvodstvennaya-platforma-i5solutions',"target":"_blank"}},[_c('div',{on:{"mouseenter":function($event){return _vm.setActive(9, true)},"mouseleave":function($event){return _vm.setActive(9, false)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage !== 9),expression:"activeImage !== 9"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/3dtoday-grey.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage === 9),expression:"activeImage === 9"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/3dtoday-color.png")}})])])]),_c('div',{staticClass:"block block__align-center block__between home__media_row"},[_c('a',{attrs:{"href":'https://www.zyfra.com/ru/news/media/v-moskve-opredeleny-'
        + 'luchshie-proekty-tsifrovizatsii-promyshlennosti/',"target":"_blank"}},[_c('div',{on:{"mouseenter":function($event){return _vm.setActive(10, true)},"mouseleave":function($event){return _vm.setActive(10, false)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage !== 10),expression:"activeImage !== 10"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/cifra-grey.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage === 10),expression:"activeImage === 10"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/cifra-color.png")}})])]),_c('a',{attrs:{"href":"https://www.mos.ru/news/item/108785073/","target":"_blank"}},[_c('div',{on:{"mouseenter":function($event){return _vm.setActive(11, true)},"mouseleave":function($event){return _vm.setActive(11, false)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage !== 11),expression:"activeImage !== 11"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/mosru-grey.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage === 11),expression:"activeImage === 11"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/mosru-color.png")}})])]),_c('a',{attrs:{"href":'https://vc.ru/tribuna/186908-intellektualnaya-proizvodstvennaya-platforma-'
        + 'i5-solutions-ocenit-stoimost-i-vremya-izgotovleniya-detaley',"target":"_blank"}},[_c('div',{on:{"mouseenter":function($event){return _vm.setActive(12, true)},"mouseleave":function($event){return _vm.setActive(12, false)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage !== 12),expression:"activeImage !== 12"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/vcru-grey.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage === 12),expression:"activeImage === 12"}],attrs:{"src":require("@/assets/pictures/media/vcru-color.png")}})])]),_c('a',{attrs:{"target":"_blank","href":"https://cipr.ru/news/na-cipr-2022-po-itogam-pitchej-vybrali-luchshie-startapy/"}},[_c('div',{on:{"mouseenter":function($event){return _vm.setActive(13, true)},"mouseleave":function($event){return _vm.setActive(13, false)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage !== 13),expression:"activeImage !== 13"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/cipr-grey.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeImage === 13),expression:"activeImage === 13"}],staticClass:"home__media_image",attrs:{"src":require("@/assets/pictures/media/cipr-color.png")}})])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }