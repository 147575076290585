<template>
  <div class="block block__center" style="margin-bottom: 90px;">
    <div class="home__container block__relative home__media_container">
      <div style="width: 100%;">
        <div class="home__title">
          {{text.media_title[language]}}
        </div>
        <div class="block block__align-center block__between home__media_row">
          <a
            :href="'https://ruposters.ru/news/23-06-2022/tehnopolise-predstavili-'
            + 'innovatsionnuyu-platformu-izgotovleniya-detalei'" target="_blank">
            <div
              @mouseenter="setActive(1, true)"
              @mouseleave="setActive(1, false)">
              <img
                class="home__media_image"
                src="@/assets/pictures/media/ruposters-grey.png"
                v-show="activeImage !== 1" />
              <img
                class="home__media_image"
                src="@/assets/pictures/media/ruposters-color.png"
                v-show="activeImage === 1"  />
            </div>
          </a>
          <a
            target="_blank"
            :href="'https://technomoscow.ru/press/v-oez-tekhnopolis-moskva-'
            + 'predstavili-intellektualnuyu-platformu-dlya-izgotovleniya-detaley-na-zakaz/'">
            <div
              @mouseenter="setActive(2, true)"
              @mouseleave="setActive(2, false)">
              <img
                class="home__media_image"
                src="@/assets/pictures/media/technoplic-grey.png"
                v-show="activeImage !== 2" />
              <img
                class="home__media_image"
                src="@/assets/pictures/media/technologic-color.png"
                v-show="activeImage === 2"  />
            </div>
          </a>
          <a :href="'https://iot.ru/promyshlennost/v-moskve-vypustili-intellektualnuyu-'
          + 'platformu-dlya-izgotovleniya-detaley-na-zakaz'" target="_blank">
            <div
              @mouseenter="setActive(3, true)"
              @mouseleave="setActive(3, false)">
              <img
                class="home__media_image"
                src="@/assets/pictures/media/iot-grey.png"
                v-show="activeImage !== 3" />
              <img
                class="home__media_image"
                src="@/assets/pictures/media/iot-color.png"
                v-show="activeImage === 3"  />
            </div>
          </a>
        </div>
        <div class="block block__align-center block__between home__media_row">
          <a href="https://vz.ru/news/2022/6/27/1164952.html" target="_blank">
            <div
              @mouseenter="setActive(4, true)"
              @mouseleave="setActive(4, false)">
              <img
                class="home__media_image"
                src="@/assets/pictures/media/vzglyad-grey.png"
                v-show="activeImage !== 4" />
              <img
                class="home__media_image"
                src="@/assets/pictures/media/vzglyad-color.png"
                v-show="activeImage === 4"  />
            </div>
          </a>
          <a
            :href="'https://deloros.ru/v-startup-klube-delovoj-rossii-predstavili'
            + '-proekty-v-sfere-cifrovoj-promyshlennosti.html'" target="_blank">
            <div
              @mouseenter="setActive(5, true)"
              @mouseleave="setActive(5, false)">
              <img
                class="home__media_image"
                src="@/assets/pictures/media/delovaya-grey.png"
                v-show="activeImage !== 5" />
              <img
                class="home__media_image"
                src="@/assets/pictures/media/delovaya-color.png"
                v-show="activeImage === 5" />
            </div>
          </a>
          <a
            target="_blank"
            :href="'https://finance.rambler.ru/economics/48900595-moskovskie-promyshlenniki'
            + '-razrabotali-onlayn-platformu-dlya-izgotovleniya-metallicheskih-detaley/'">
            <div
              @mouseenter="setActive(6, true)"
              @mouseleave="setActive(6, false)">
              <img
                class="home__media_image"
                src="@/assets/pictures/media/rampler-grey.png"
                v-show="activeImage !== 6" />
              <img
                class="home__media_image"
                src="@/assets/pictures/media/rambler-color.png"
                v-show="activeImage === 6"  />
            </div>
          </a>
        </div>
        <div class="block block__align-center block__between home__media_row">
          <a
            href="https://www.cnews.ru/news/line/2022-06-23_v_oez_tehnopolis_moskva"
            target="_blank">
            <div
              @mouseenter="setActive(7, true)"
              @mouseleave="setActive(7, false)">
              <img
                class="home__media_image"
                src="@/assets/pictures/media/cnews-grey.png"
                v-show="activeImage !== 7" />
              <img
                class="home__media_image"
                src="@/assets/pictures/media/cnews-color.png"
                v-show="activeImage === 7"  />
            </div>
          </a>
          <a
            target="_blank"
            :href="'https://www.vedomosti.ru/press_releases/2022/06/23/v-oez-tehnopolis-moskva-'
          + 'predstavili-intellektualnuyu-platformu-dlya-izgotovleniya-detalei-na-zakaz'" >
            <div
              @mouseenter="setActive(8, true)"
              @mouseleave="setActive(8, false)">
              <img
                class="home__media_image"
                src="@/assets/pictures/media/Vedomosti-grey.png"
                v-show="activeImage !== 8" />
              <img
                class="home__media_image"
                src="@/assets/pictures/media/Vedomosti-color.png"
                v-show="activeImage === 8" />
            </div>
          </a>
          <a :href="'https://3dtoday.ru/blogs/news3dtoday/cto-kak-gde-i-'
          + 'pocem-umnaya-proizvodstvennaya-platforma-i5solutions'" target="_blank">
            <div
              @mouseenter="setActive(9, true)"
              @mouseleave="setActive(9, false)">
              <img
                class="home__media_image"
                src="@/assets/pictures/media/3dtoday-grey.png"
                v-show="activeImage !== 9" />
              <img
                class="home__media_image"
                src="@/assets/pictures/media/3dtoday-color.png"
                v-show="activeImage === 9"  />
            </div>
          </a>
        </div>
        <div class="block block__align-center block__between home__media_row">
          <a :href="'https://www.zyfra.com/ru/news/media/v-moskve-opredeleny-'
          + 'luchshie-proekty-tsifrovizatsii-promyshlennosti/'" target="_blank">
            <div
              @mouseenter="setActive(10, true)"
              @mouseleave="setActive(10, false)">
              <img
                class="home__media_image"
                src="@/assets/pictures/media/cifra-grey.png"
                v-show="activeImage !== 10" />
              <img
                class="home__media_image"
                src="@/assets/pictures/media/cifra-color.png"
                v-show="activeImage === 10"  />
            </div>
          </a>
          <a href="https://www.mos.ru/news/item/108785073/" target="_blank">
            <div
              @mouseenter="setActive(11, true)"
              @mouseleave="setActive(11, false)">
              <img
                class="home__media_image"
                src="@/assets/pictures/media/mosru-grey.png"
                v-show="activeImage !== 11" />
              <img
                class="home__media_image"
                src="@/assets/pictures/media/mosru-color.png"
                v-show="activeImage === 11" />
            </div>
          </a>
          <a :href="'https://vc.ru/tribuna/186908-intellektualnaya-proizvodstvennaya-platforma-'
          + 'i5-solutions-ocenit-stoimost-i-vremya-izgotovleniya-detaley'" target="_blank">
            <div
              @mouseenter="setActive(12, true)"
              @mouseleave="setActive(12, false)">
              <img
                class="home__media_image"
                src="@/assets/pictures/media/vcru-grey.png"
                v-show="activeImage !== 12" />
              <img src="@/assets/pictures/media/vcru-color.png" v-show="activeImage === 12"  />
            </div>
          </a>
          <a
            target="_blank"
            href="https://cipr.ru/news/na-cipr-2022-po-itogam-pitchej-vybrali-luchshie-startapy/">
            <div
              @mouseenter="setActive(13, true)"
              @mouseleave="setActive(13, false)">
              <img
                class="home__media_image"
                src="@/assets/pictures/media/cipr-grey.png"
                v-show="activeImage !== 13" />
              <img
                class="home__media_image"
                src="@/assets/pictures/media/cipr-color.png"
                v-show="activeImage === 13"  />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from '@/assets/text/home.json';

export default {
  data() {
    return {
      text,
      activeImage: null,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    setActive(index, value) {
      if (value) {
        this.activeImage = index;
      } else {
        this.activeImage = null;
      }
    },
  },
};
</script>
