<template>
  <div class="input-i5">
    <label :for="name">
      {{label}}
      <span v-if="required" >*</span>
      <span v-if="subLabel" class="input-i5__sublabel">
        {{subLabel}}
      </span>
    </label>
    <div class="block__flex">
      <div class="input-i5__minus" @click="setStep('minus')">
        <Minus />
      </div>
      <input
        :tabindex="ti"
        :value="value"
        :step="step"
        :min="min"
        :max="max"
        :id="name"
        :name="name"
        type="number"
        autocomplete="off"
        :disabled="disabled === true"
        @input="handleChange"/>
      <div class="input-i5__plus" @click="setStep('plus')">
        <Plus />
      </div>
    </div>
  </div>
</template>

<script>
import Plus from '@/assets/pictures/input_number/math-plus.svg';
import Minus from '@/assets/pictures/input_number/math-minus.svg';

export default {
  name: 'InputNumber',
  data() {
    return {
      value: null,
    };
  },
  props: {
    defaultValue: {
      type: Number,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    subLabel: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    step: {
      type: Number,
      default: 1,
    },
    required: {
      type: Boolean,
      default: false,
    },
    ti: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    defaultValue: function (val) {
      if (typeof val === 'number') {
        this.value = val;
      }
    },
  },
  methods: {
    handleChange(evt) {
      if (this.max && +evt.target.value > this.max) {
        evt.target.value = this.max;
      } else if (this.min && +evt.target.value < this.min) {
        evt.target.value = this.min;
      }
      evt.target.value = +evt.target.value;
      this.value = +evt.target.value;
      this.$emit('change', evt.target.value);
    },
    setStep(type) {
      let val = 0;
      let step = 1;
      if (this.value) {
        val = this.value;
        if (this.step) {
          step = this.step;
        }
        if (type === 'plus' && (val + step <= this.max || !this.max)) {
          val += step;
        } else if (type === 'minus' && (val - step >= this.min || !this.min)) {
          val -= step;
        }
      } else {
        val = this.min || 1;
      }
      this.value = val;
      this.$emit('change', val);
    },
  },
  mounted() {
    this.value = this.defaultValue;
  },
  components: {
    Plus,
    Minus,
  },
};
</script>
