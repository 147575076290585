var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block__flex block__align-center block__column",staticStyle:{"position":"relative"}},[_c('div',{class:`input-i5 ${_vm.setFocus()}`,staticStyle:{"width":"100%"}},[_c('label',{attrs:{"for":_vm.name},on:{"click":_vm.handleFocusOut}},[_vm._v(_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e(),(_vm.subLabel && !_vm.error)?_c('span',{staticClass:"input-i5__sublabel"},[_vm._v(" "+_vm._s(_vm.subLabel)+" ")]):_vm._e(),(_vm.error)?_c('span',{staticClass:"input-i5__error"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()]),_c('div',{staticStyle:{"position":"relative"}},[_c('input',{class:`${_vm.error ? 'input-i5_error' : ''}`,style:(`${_vm.clearable ? 'padding-right: 20px;' : ''}`),attrs:{"tabindex":_vm.ti,"id":_vm.name,"name":_vm.name,"autocomplete":"off","disabled":_vm.disabled === true},domProps:{"value":_vm.value},on:{"focus":_vm.handleFocus,"blur":_vm.handleFocusOut,"input":_vm.handleChange}}),(_vm.clearable && _vm.value)?_c('ClearImage',{staticClass:"input-i5__clear",on:{"click":_vm.clear}}):_vm._e()],1)]),_c('div',{class:`search__container ${_vm.setOpen()} ${_vm.setDirection()}`,style:({
      width: `${_vm.elWidth}px`,
      maxHeight: `${_vm.dropDownMaxHeight}px`,
      marginTop: `${_vm.elHeight + 2}px`,
      position: _vm.setPosition()
    }),attrs:{"id":`${_vm.name}_dropdown`}},_vm._l((_vm.dropdownValues),function(val,index){return _c('div',{key:`dd_${index}`,on:{"mousedown":(e) => e.preventDefault()}},[_c('div',{class:`${_vm.dropdownProps
          && _vm.dropdownProps.secondLine
          && val[_vm.dropdownProps.secondLine]
          ? 'search__item_double'
          : 'search__item'
        }`,on:{"click":function($event){return _vm.setElement(val)}}},[_c('div',{staticClass:"block block__align-center"},[(_vm.dropdownProps && _vm.dropdownProps.img && val[_vm.dropdownProps.img])?_c('img',{staticClass:"component__margin-right search__image",attrs:{"src":val[_vm.dropdownProps.img]}}):_vm._e(),_c('div',[_c('div',{staticClass:"block block__align-center"},[_c('div',[_vm._v(_vm._s(val.label))]),(_vm.dropdownProps && _vm.dropdownProps.sublabel && val[_vm.dropdownProps.sublabel])?_c('div',{staticClass:"component__margin-left",staticStyle:{"overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"}},[_vm._v("("+_vm._s(val[_vm.dropdownProps.sublabel])+")")]):_vm._e()]),(_vm.dropdownProps
            && _vm.dropdownProps.secondLine
            && val[_vm.dropdownProps.secondLine])?_c('div',[_vm._v(" "+_vm._s(val[_vm.dropdownProps.secondLine])+" ")]):_vm._e()])])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }