<template>
  <div class="block block__center" style="margin-bottom: 90px;">
    <div class="home__container block__relative home__media_container">
      <div style="width: 100%; margin-bottom: 20px;">
        <div class="home__title">
          {{text.how_we_works_title[language]}}
        </div>
        <div :class="`${width > t_lg ? 'block block__between block__align-center' : ''}`">
          <div class="how-work__card">
            <div class="block block__align-center component__margin-bottom">
              <CreateOrderLogo style="min-width: 46px;" />
              <div class="how-work__card_title" style="margin-left: 15px;">
                {{text.works_create_order[language]}}
              </div>
            </div>
            <div class="block block__between" style="height: 100%">
              <div class="how-work__card_text">
                <div class="component__margin-bottom">{{text.works_1_1[language]}}</div>
                <div class="component__margin-bottom">{{text.works_1_2[language]}}</div>
                <div class="component__margin-bottom">{{text.works_1_3[language]}}</div>
                <div class="component__margin-bottom">{{text.works_1_4[language]}}</div>
              </div>
              <div class="block__flex block__align-bottom how-work__card_number">1</div>
            </div>
          </div>
          <div class="how-work__arrows" v-if="width > t_lg">
            <ArrowLogo />
            <ArrowLogo />
            <ArrowLogo />
            <ArrowLogo />
          </div>
          <div
            v-if="width <= t_lg"
            class="block block__between component__margin-top component__margin-bottom">
            <ArrowDownLogo />
            <ArrowDownLogo />
            <ArrowDownLogo />
            <ArrowDownLogo />
          </div>
          <div class="how-work__card">
            <div class="block block__align-center component__margin-bottom">
              <ManufactureLogo style="min-width: 46px;" />
              <div class="how-work__card_title" style="margin-left: 15px;">
                {{text.works_choose_manufacture[language]}}
              </div>
            </div>
            <div class="block block__between" style="height: 100%">
              <div class="how-work__card_text">
                <div class="component__margin-bottom">{{text.works_2_1[language]}}</div>
                <div class="component__margin-bottom">{{text.works_2_2[language]}}</div>
                <div class="component__margin-bottom">{{text.works_2_3[language]}}</div>
              </div>
              <div class="block__flex block__align-bottom how-work__card_number">2</div>
            </div>
          </div>
          <div class="how-work__arrows" v-if="width > t_lg">
            <ArrowLogo />
            <ArrowLogo />
            <ArrowLogo />
            <ArrowLogo />
          </div>
          <div
            v-if="width <= t_lg"
            class="block block__between component__margin-top component__margin-bottom">
            <ArrowDownLogo />
            <ArrowDownLogo />
            <ArrowDownLogo />
            <ArrowDownLogo />
          </div>
          <div class="how-work__card">
            <div class="block block__align-center component__margin-bottom">
              <DeliveryLogo style="min-width: 54px;" />
              <div style="margin-left: 15px;" class="how-work__card_title">
                {{text.works_delivery[language]}}
              </div>
            </div>
            <div class="block block__between" style="height: 100%">
              <div class="how-work__card_text">
                <div class="component__margin-bottom">{{text.works_3_1[language]}}</div>
                <div class="component__margin-bottom">{{text.works_3_2[language]}}</div>
                <div class="component__margin-bottom">{{text.works_3_3[language]}}</div>
                <div class="component__margin-bottom">{{text.works_3_4[language]}}</div>
              </div>
              <div class="block__flex block__align-bottom how-work__card_number">3</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from '@/assets/text/home.json';
import CreateOrderLogo from '@/assets/pictures/howWeWork/create_order.svg';
import ManufactureLogo from '@/assets/pictures/howWeWork/manufacture.svg';
import DeliveryLogo from '@/assets/pictures/howWeWork/delivery.svg';
import ArrowLogo from '@/assets/pictures/howWeWork/arrow.svg';
import ArrowDownLogo from '@/assets/pictures/howWeWork/arrow_down.svg';

export default {
  name: 'HowWeWork',
  data() {
    return {
      text,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    m_lg() {
      return this.$store.state.index.m_lg;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    width() {
      return this.$store.state.index.width;
    },
  },
  components: {
    CreateOrderLogo,
    ManufactureLogo,
    DeliveryLogo,
    ArrowLogo,
    ArrowDownLogo,
  },
};
</script>
