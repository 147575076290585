<template>
  <div style="width: 100%">
    <div class="block block__between" v-if="width > t_lg">
      <div
        v-for="(item, index) in resultStatuses" v-bind:key="`${index}_status`"
        @click="setActiveId(item)"
        class="orders__types_card">
        <div
          :class="`orders__types_count${activeId === item.id ? '_active' : ''}`">
          <div>{{item.count ? item.count : 0}}</div>
        </div>
        <div
          :class="`orders__types_name${activeId === item.id ? '_active' : ''}`">
          <div v-if="item.id === 11">{{text.draft_status[language]}}</div>
          <div v-if="item.id === 12">{{text.verification_status[language]}}</div>
          <div v-if="item.id === 9">{{text.application_status[language]}}</div>
          <div v-if="item.id === 4">{{text.in_work_status[language]}}</div>
          <div v-if="item.id === 5">{{text.cancelled_status[language]}}</div>
          <div v-if="item.id === 6">{{text.completed_status[language]}}</div>
          <div v-if="item.id === 3">{{text.awaiting_payment_status[language]}}</div>
          <div v-if="item.id === 8">{{text.request_clarification_status[language]}}</div>
          <div v-if="item.id === 7">{{text.delivery_status[language]}}</div>
        </div>
        <div
          :class="`orders__types_line${activeId === item.id ? '_active' : ''}`">
        </div>
      </div>
    </div>
    <div
      class="block block__between"
      v-if="width <= t_lg && resultStatuses">
      <div
        v-for="item in resultStatuses.filter((el, index) => index
            < +Math.ceil(resultStatuses.length / 2))"
        v-bind:key="item"
        @click="setActiveId(item)"
        class="orders__types_card">
        <div
          :class="`orders__types_count${activeId === item.id ? '_active' : ''}`">
          <div>{{item.count ? item.count : 0}}</div>
        </div>
        <div
          :class="`orders__types_name${activeId === item.id ? '_active' : ''}`">
          <div v-if="item.id === 11">{{text.draft_status[language]}}</div>
          <div v-if="item.id === 12">{{text.verification_status[language]}}</div>
          <div v-if="item.id === 9">{{text.application_status[language]}}</div>
          <div v-if="item.id === 4">{{text.in_work_status[language]}}</div>
          <div v-if="item.id === 5">{{text.cancelled_status[language]}}</div>
          <div v-if="item.id === 6">{{text.completed_status[language]}}</div>
          <div v-if="item.id === 3">{{text.awaiting_payment_status[language]}}</div>
          <div v-if="item.id === 8">{{text.request_clarification_status[language]}}</div>
          <div v-if="item.id === 7">{{text.delivery_status[language]}}</div>
        </div>
        <div
          :class="`orders__types_line${activeId === item.id ? '_active' : ''}`">
        </div>
      </div>
    </div>
    <div class="block block__between"
         v-if="width <= t_lg && resultStatuses" >
      <div
        v-for="item in resultStatuses.filter((el, index) => index
            >= +Math.ceil(resultStatuses.length / 2))"
        v-bind:key="item"
        @click="setActiveId(item)"
        class="orders__types_card">
        <div
          :class="`orders__types_count${activeId === item.id ? '_active' : ''}`">
          <div>{{item.count ? item.count : 0}}</div>
        </div>
        <div
          :class="`orders__types_name${activeId === item.id ? '_active' : ''}`">
          <div v-if="item.id === 11">{{text.draft_status[language]}}</div>
          <div v-if="item.id === 12">{{text.verification_status[language]}}</div>
          <div v-if="item.id === 9">{{text.application_status[language]}}</div>
          <div v-if="item.id === 4">{{text.in_work_status[language]}}</div>
          <div v-if="item.id === 5">{{text.cancelled_status[language]}}</div>
          <div v-if="item.id === 6">{{text.completed_status[language]}}</div>
          <div v-if="item.id === 3">{{text.awaiting_payment_status[language]}}</div>
          <div v-if="item.id === 8">{{text.request_clarification_status[language]}}</div>
          <div v-if="item.id === 7">{{text.delivery_status[language]}}</div>
        </div>
        <div
          :class="`orders__types_line${activeId === item.id ? '_active' : ''}`">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from '@/assets/text/orders.json';

export default {
  name: 'OrderStatuses',
  data() {
    return {
      text,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    width() {
      return this.$store.state.index.width;
    },
    resultStatuses() {
      return this.$store.state.orders.statusAmountList;
    },
    activeId() {
      return this.$store.state.orders.activeId;
    },
  },
  methods: {
    setActiveId(item) {
      this.$emit('setActiveId', item.id);
      this.$store.dispatch('orders/changeActiveStatus', +item.id);
      this.$router.push(`/orders/${item.path}`);
    },
  },
  created() {
    this.$store.dispatch('orders/getOrderStatuses');
  },
};
</script>
