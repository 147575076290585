<template>
  <div class="home__container home__media_container">
    <div class="block block__center" v-if="success && loading">
      <div style="width: 100%">
        <div class="registration-success__container">
          <div>
            <div class="registration-success__title" style="margin-bottom: 25px">
              <span>{{text.title_1[language]}} </span>
              <b>{{this.$route.query.user}} </b>
              <span>{{text.title_2[language]}}</span>
            </div>
            <div class="registration-success__title" style="margin-bottom: 25px">
              {{text.sub_title[language]}}
            </div>
            <div class="block block__center" style="padding: 0 45px">
              <a href="mailto:info@i5.solutions" style="width: 100%">
                <Button
                  block
                  :text="text.button[language]"
                  type="primary"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block block__center" v-if="!success && loading">
      <div style="width: 100%">
        <div class="registration-success__container">
          <div>
            <div class="registration-success__title" style="margin-bottom: 25px">
              {{text.error_message[language]}}
            </div>
            <div class="block block__center" style="padding: 0 45px">
              <Button
                block
                @click="goHome"
                :text="text.error_button[language]"
                type="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { query } from '@/utilites/axios';
import text from '@/assets/text/registrarion-success.json';
import Button from '@/components/button/Button.vue';

export default {
  name: 'RegistrationSuccess',
  data() {
    return {
      loading: false,
      success: false,
      text,
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    proofUser() {
      this.loading = false;
      if (this.$route.query.user && this.$route.query.hash) {
        query('get', `proofUser/?user=${
          this.$route.query.user}&hash=${this.$route.query.hash}`, {}, {}).then(() => {
          this.success = true;
        }).catch(() => {
          this.loading = true;
          this.success = false;
        });
      } else {
        this.loading = true;
        this.success = false;
      }
    },
    signIn() {
      this.$store.commit('user/setShowAuthorizeModal', true);
      this.$store.commit('user/setAfterAuthorize', () => {
        this.$router.push('/orders/draft');
      });
    },
    goHome() {
      this.$router.push('/');
    },
  },
  mounted() {
    this.proofUser();
  },
  components: {
    Button,
  },
};
</script>
