import '@babel/polyfill';
import Vue from 'vue';
import Antd from 'ant-design-vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/styles/index.scss';
import 'ant-design-vue/dist/antd.less';

Vue.use(Antd);

Vue.use(VueMeta);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  // store.commit('index/setShow404', false);
  let access = true;
  if (to.query && to.query.company_id) {
    if (to.query.company_id === 'b59d73af0dc8fed545046314dc144f81') {
      store.commit('index/setCompanyId', to.query.company_id);
    }
  }
  const pushPage = () => {
    if (access) {
      next();
    } else if (store.state.user.user && !access && to.meta.auth) {
      next('/');
    } else if (store.state.user.user && !access && !to.meta.auth) {
      next('/');
    } else if (!store.state.user.user && !access && to.meta.auth) {
      next('/');
    } else if (!store.state.user.user && !access && !to.meta.auth) {
      next('/');
    } else {
      next('/');
    }
  };
  const check = () => {
    // const user = store.state.user.user;
    const acc = store.state.user.access;
    const meta = to.meta;
    if (!(acc && acc.admin) && meta.prod === false) {
      access = false;
    } else if (!((meta.admin && acc.admin)
      || (meta.expert && acc.expert)
      || (meta.manufacturer && acc.manufacturing)
      || (!acc.admin && !acc.expert && !acc.manufacturing && meta.user))) {
      access = false;
    }
  };
  if (to.meta.auth) {
    store.commit('user/setClosableModal', false);
    store.commit('user/setAfterAuthorize', () => {});
    if (!store.state.user.user || !store.state.user.access) {
      store.dispatch('user/getUser').then(() => {
        if (store.state.user.user && store.state.user.user.lang) {
          store.commit('index/setLanguage', store.state.user.user.lang);
        }
        check();
        pushPage();
      }).catch(() => {
        const afterAuthorize = () => {
          next();
        };
        store.commit('user/setAfterAuthorize', afterAuthorize);
        store.commit('user/setShowAuthorizeModal', true);
      });
    } else {
      store.dispatch('user/getUser');
      check();
      pushPage();
    }
  } else {
    store.dispatch('user/getUserWithout401').then(() => {
      if (store.state.user.user && store.state.user.user.lang) {
        store.commit('index/setLanguage', store.state.user.user.lang);
      }
    });
    pushPage();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    const savedLanguage = localStorage.getItem('language');
    let language = window.navigator.userLanguage || window.navigator.language;
    if (!savedLanguage) {
      language = language.split('-')[0];
    } else {
      language = savedLanguage;
    }
    if (language === 'ru') {
      this.$store.commit('index/setLanguage', 'ru');
    } else if (language === 'en') {
      this.$store.commit('index/setLanguage', 'en');
    } else {
      this.$store.commit('index/setLanguage', 'en');
    }
  },
}).$mount('#app');
